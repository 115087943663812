<template>
  <div class="true-false">
    <Draggable v-model="questions" handle=".handle" v-bind="dragOptions">
      <div class="" v-for="(question, i) in questions" :key="i">
        <QuestionSimple
          :question="question"
          :number="i"
          :canAddQuestion="true"
          class="my-5"
          @delete-question="deleteQuestion"
          @update-question="updateQuestion"
          :options="options"
        >
        </QuestionSimple>
      </div>
    </Draggable>
    <div
      @click="addQuestion"
      class="plus m-auto rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl"
    >
      +
    </div>
  </div>
</template>
<script>
import Draggable from 'vuedraggable'
import QuestionSimple from '@/components/Games/Question_simple'

export default {
  name: 'QuizSimple',
  components: {
    QuestionSimple,
    Draggable,
  },
  props: {
    game: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      options: {
        fixedAnswers: true,
        multipleCorrection: true,
        editableAnswers: true,
        multipleAnswers: false,
      },
    }
  },
  computed: {
    questions: {
      get() {
        if (!this.game) return []
        return JSON.parse(JSON.stringify(this.game.questions))
      },
      set(value) {
        this.$emit('update-all-questions', value)
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
  },
  watch: {
    game: {
      handler(game) {
        // Automaticly add one question if none provided
        if (game && game.questions.length <= 0) {
          this.addQuestion()
        }
      },
      immediate: true,
    },
  },
  methods: {
    addQuestion() {
      let question = {
        answers: [
          {
            text: 'Vrai',
            is_correct: true,
            correction: 'Bonne réponse',
          },
          {
            text: 'Faux',
            is_correct: false,
            correction: 'Mauvaise réponse',
          },
        ],
        text: '',
        correction: '',
      }
      this.$emit('add-questions', [question])
    },
    updateQuestion(i, data) {
      this.$emit('update-question', i, data)
    },
    deleteQuestion(i) {
      this.$emit('delete-questions', [i])
    },
  },
}
</script>

<style lang="scss"></style>
