import { ApolloClient } from 'apollo-client'

import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

import { ApolloLink, fromPromise } from 'apollo-link'
import { onError } from 'apollo-link-error'

import store from '@/store/index'

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_BACKEND_URL,
})

/* Handle authorization header */
const authLink = new ApolloLink((operation, forward) => {
  const token = store.getters['Auth/userToken']

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  })

  return forward(operation)
})

/* Handle authorization error */
const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  // eslint-disable-next-line
  console.error(graphQLErrors) // todo: ??

  if (graphQLErrors) {
    for (let i = 0; i < graphQLErrors.length; i++) {
      const error = graphQLErrors[i]

      if (
        error.extensions.code == 'access-denied' ||
        error.extensions.code == 'invalid-jwt' ||
        error.extensions.code == 'expired'
      ) {
        const token = store.getters['Auth/userToken']

        if (token) {
          // Try refreshing the token
          return fromPromise(
            store.dispatch('Auth/refreshToken').then((success) => {
              return success
            })
          ).flatMap((success) => {
            if (!success) {
              // todo: not here ?
              location.reload()
              throw new Error('Token expired')
            }

            // Retry after a successful refresh
            return forward(operation)
          })
        }
      }
    }
  }
})

/* Automagicly remove __typename from all operation variables */
function omitTypename(key, value) {
  return key === '__typename' ? undefined : value
}

const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
  }

  return forward(operation)
})

const link = new ApolloLink.from([omitTypenameLink, errorLink, authLink, httpLink])

const cache = new InMemoryCache()

const client = new ApolloClient({
  link: link,
  cache,
})

client.defaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
  },
}

export default client
