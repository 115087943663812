<template>
  <b-modal :ref="modalId" :id="modalId" class="bootstrap" centered hide-footer hide-header>
    <div class="d-block text-center mt-4 mb-6 text-3xl">
      <h3 class="text-pink">Avertissement</h3>
    </div>

    <p class="text-center text-gray-600">
      <slot></slot>
    </p>

    <div class="flex flex-row space-x-4 justify-center items-center">
      <button type="button" class="mt-4 twn-button secondary" @click="$bvModal.hide(modalId)">
        Annuler
      </button>
      <button type="button" class="mt-4 twn-button danger" @click="onConfirmClick">
        {{ actionBtnText }}
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    modalId: {
      type: String,
    },
    title: {
      type: String,
      default: 'Avertissement',
    },
    actionBtnText: {
      type: String,
      default: 'Confirmer',
    },
  },

  methods: {
    onConfirmClick() {
      this.$bvModal.hide(this.modalId)

      this.$emit('onConfirm')
    },
  },
}
</script>

<style>
.modal-content {
  @apply border-0 rounded-lg;
}
</style>
