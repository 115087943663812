<script>
import Header from '@/components/Header'
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  state: {
    navOpen: false,
  },
  components: {
    Header,
    Navbar,
    Footer,
  },
  computed: {
    ...mapGetters('Header', ['showNav']),
    ...mapState('Header', {
      navOpen: (state) => state.showNav,
    }),
  },
  methods: {
    ...mapActions('Header', ['closeNav', 'openNav']),
    getBreadCrumb() {
      let items = [
        {
          text: process.env.VUE_APP_NAME,
          to: { name: 'users-list' },
        },
      ]
      if (
        this.$router.currentRoute.name &&
        this.$router.currentRoute.meta &&
        this.$router.currentRoute.meta.breadcrumb
      ) {
        let text = this.$router.currentRoute.meta.breadcrumb
        if (typeof this.$router.currentRoute.meta.breadcrumb === 'function') {
          text = this.$router.currentRoute.meta.breadcrumb(this.$route)
        }

        items.push({
          text: text,
          to: this.$router.currentRoute.name,
        })
      }

      return items
    },
  },
}
</script>

<template>
  <div class="flex flex-row">
    <Navbar class="sidebar fixed h-screen z-50" v-bind:class="{ 'nav-open': showNav }"></Navbar>
    <div
      @click="closeNav($event)"
      class="main-panel bg-white w-full relative min-h-screen h-full flex flex-col"
    >
      <Header :crumbs="getBreadCrumb()" />
      <div
        class="flex-auto flex flex-col"
        :class="{ 'pb-10 px-3 md:px-10 md:my-10': !$route.meta.noMargin }"
      >
        <slot />
      </div>
      <Footer class="absolute bottom-0 h-10 w-auto"></Footer>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.breadcrumb {
  @apply pl-10;
  background-color: #fcfcfc;
  .breadcrumb-item {
    @apply uppercase text-sm text-principal-list-item;
    &.active {
      font-weight: 700;
    }
  }

  .breadcrumb-item + .breadcrumb-item::before {
    content: '>';
  }
}
.main-panel {
  @apply min-w-0;
  transition: margin 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}
@screen md {
  .footer {
    width: 100% !important;
  }
  .main-panel {
    margin-left: 5rem;
  }
}
// .main-panel.nav-open{
//     margin-left: $navbarLength;
// }
</style>
