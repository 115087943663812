<template>
  <div v-if="game">
    <!-- Table -->
    <div class="uppercase font-principal-bold text-sm">{{ game.type.name }}</div>
    <GameTable
      :game="game"
      @add-column="addColumn"
      @add-line="addLine"
      @update-header="updateHeader"
      @delete-column="deleteColumn"
      @delete-line="deleteLine"
      :assets="assets"
      @media-selected="SelectMedia"
      ref="GameTable"
    >
      <template slot-scope="{ question }">
        <div @click="OpenPanel(question)" class="w-full h-full flex-center">
          <div
            v-if="question.question_type_id"
            class="w-4 h-4 absolute right-0 top-0 cursor-pointer pt-1"
            @click="ResetQuestionModal()"
          >
            <img src="@/assets/images/NODE_CROSS.svg" />
          </div>
          <div
            v-if="!questionTypes || !question.question_type_id"
            class="h-full flex-center w-full"
          >
            Choisissez du type de contenu
          </div>
          <div
            v-else-if="questionTypes[question.question_type_id] == 'cell_qcm'"
            class="cell flex flex-col text-center relative"
          >
            <div class="w-full text-center m-auto">QCM</div>
            <label class="switch" v-if="question.answers && question.answers[0]">
              <input type="checkbox" :checked="question.answers[0].is_correct" disabled />
              <span class="slider round"></span>
            </label>
          </div>
          <div
            v-else-if="questionTypes[question.question_type_id] == 'cell_fill'"
            class="cell w-full flex flex-col justify-center items center"
          >
            <div class="w-full text-center m-auto">FILL</div>
            <div
              v-if="question.answers && question.answers.length > 0"
              class="w-full flex flex-col justify-center items-center"
            >
              Nombre de réponses
              <div class="number">{{ question.answers.length }}</div>
            </div>
          </div>
          <div v-else-if="questionTypes[question.question_type_id] == 'cell_dropdown'">
            <div class="w-full text-center m-auto">DROPDOWN</div>
            <div
              v-if="question.answers && question.answers.length > 0"
              class="w-full flex flex-col justify-center items-center"
            >
              Nombre de réponses
              <div class="number">{{ question.answers.length }}</div>
            </div>
          </div>
          <div v-else-if="questionTypes[question.question_type_id] == 'cell_text'">TEXT</div>
          <div
            v-else-if="
              questionTypes[question.question_type_id] == 'cell_image' && assets[question.media_id]
            "
          >
            <img :src="assets[question.media_id].thumbnail_url" alt="" />
          </div>
        </div>
      </template>
    </GameTable>
    <MiddlePanel
      title="Parametrage de cellule"
      :onTabChange="selectTab"
      :tabs="tabOptions"
      ref="panel"
    >
      <template slot="qcm">
        <div v-if="question" class="my-12 h-full flex flex-row items-center justify-around w-full">
          <div class="w-2/3 flex justify-center items-center">
            <div class="mr-3">Correction:</div>
            <b-form-textarea class="twn-textarea" v-model="questionCorrection"></b-form-textarea>
          </div>
          <div class="w-1/3" @click="QCM_CheckAnswer(question)">
            <div class="flex flex-row flex-center" v-if="answers && answers[0]">
              <label class="switch">
                <input
                  type="checkbox"
                  :checked="answers[0].is_correct"
                  @click="QCM_CheckAnswer(question)"
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </template>
      <template slot="text_img">
        <div v-if="question" class="flex flex-col flex-center">
          <button type="button" @click="showRightPanel()" class="twn-button my-4">
            Ajouter une image
          </button>
          <div v-if="!imageState && !question.media_id" class="w-3/4">
            <b-form-textarea
              class="w-full twn-textarea"
              v-model="TxtOrImg_UpdateText"
            ></b-form-textarea>
          </div>
          <div
            v-else
            class="w-full m-5 image-content"
            @click="TxtOrImg_OpenMediaChoice"
            @dragover="allowDrop"
            @drop.prevent.stop="TxtOrImg_dropImageInQuestion($event)"
          >
            <div
              class="drop-zone p-10 w-full h-full border-dashed border-2"
              v-if="!question.media_id || !assets[question.media_id]"
            >
              <div class="m-auto w-1/3 break-words text-center text-xs">
                Ajouter une image depuis la bibliothèque de média
              </div>
            </div>
            <div v-else class="image m-auto w-1/5 h-2/3 relative">
              <div
                class="w-4 h-4 absolute right-0 top-0 cursor-pointer pt-1"
                @pointerdown.stop="TxtOrImg_DeleteImage()"
              >
                <img src="@/assets/images/NODE_CROSS.svg" />
              </div>
              <img :src="assets[question.media_id].thumbnail_url" alt="" class="" />
            </div>
          </div>
        </div>
      </template>
      <template slot="dropdown">
        <div v-if="answers" class="dropdown-answers">
          <draggable v-model="answers" handle=".answer-handle">
            <div
              v-for="(answer, i) in answers"
              :key="i"
              class="flex justify-around items-center my-4"
            >
              <div class="number answer-handle w-1/6 mr-4">
                {{ i + 1 }}
              </div>
              <b-form-textarea
                class="twn-textarea w-5/6"
                @input="Dropdown_AnswerText(i, $event)"
                :value="answer.text"
              >
              </b-form-textarea>
              <div class="mx-4" @click="Dropdown_CheckAnswer(i)">
                <div class="flex flex-row flex-center">
                  <label class="switch">
                    <input
                      type="checkbox"
                      :checked="answer.is_correct"
                      @click="Dropdown_CheckAnswer(i)"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <div class="w-4 h-4 cursor-pointer pt-1" @click="DeleteAnswer(i)">
                <img src="@/assets/images/NODE_CROSS.svg" />
              </div>
            </div>
          </draggable>
          <div v-if="answers">
            <div v-if="answers.length == 0" class="w-full text-center my-4">
              Ajouter une réponse
            </div>
            <div
              @click="Dropdown_addDefaultAnswer()"
              class="plus m-auto rounded-full w-10 h-10 flex justify-center items-center cursor-pointer"
            >
              +
            </div>
          </div>
        </div>
      </template>
      <template slot="fill">
        <div v-if="answers">
          <draggable v-model="answers" handle=".answer-handle">
            <div v-for="(answer, i) in answers" :key="i">
              <div class="flex justify-around items-center my-4">
                <div class="number answer-handle w-1/6">
                  {{ i + 1 }}
                </div>
                <v-select
                  taggable
                  multiple
                  @input="Fill_setAValue($event, i)"
                  :value="Fill_getValues(i)"
                  class="style-chooser text-xs w-5/6"
                ></v-select>
                <div class="w-4 h-4 cursor-pointer pt-1" @click="DeleteAnswer(i)">
                  <img src="@/assets/images/NODE_CROSS.svg" />
                </div>
              </div>
            </div>
          </draggable>
          <div v-if="answers">
            <div v-if="answers.length == 0" class="w-full text-center my-4">
              Ajouter une réponse
            </div>
            <div
              @click="Dropdown_addDefaultAnswer()"
              class="plus m-auto rounded-full w-10 h-10 flex justify-center items-center cursor-pointer"
            >
              +
            </div>
          </div>
        </div>
      </template>
    </MiddlePanel>
    <b-modal ref="alert-modal" centered hide-footer>
      <template v-slot:modal-title> Suppression de la cellule </template>
      <div class="text-center">Confirmer la suppression ?</div>
      <div class="flex justify-between items-center my-4">
        <button @click="HideResetQuestionModal" type="button" class="twn-button">Retour</button>
        <button @click="ResetQuestion" type="button" class="twn-button danger">Supprimer</button>
      </div>
    </b-modal>
    <div class="my-4">
      Correction:
      <b-form-textarea v-model="correction"></b-form-textarea>
    </div>
  </div>
</template>

<script>
import GameTable from '@/components/common/game/Table'
import MiddlePanel from '@/components/MiddlePanel'
import dispatchStoreRequest from '@/mixins/dispatchStoreRequest'
import Draggable from 'vuedraggable'
import { mapState } from 'vuex'

export default {
  name: 'DragDrop',
  components: {
    GameTable,
    MiddlePanel,
    Draggable,
  },
  mixins: [dispatchStoreRequest],
  props: {
    game: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      tabOptions: [
        {
          title: 'QCM',
          slot: 'qcm',
          selected: true,
          onInit: this.InitQuestion,
        },
        {
          title: 'Texte/Image',
          slot: 'text_img',
          onInit: this.InitQuestion,
        },
        {
          title: 'Texte à trous',
          slot: 'fill',
          onInit: this.InitQuestion,
        },
        {
          title: 'Dropdown',
          slot: 'dropdown',
          onInit: this.InitQuestion,
        },
      ],
      selectedQuestion: null,
      selectedTab: [],
      rightPanel: null,
      imageType: false,
      image: null,
      resetModal: false,
    }
  },
  async created() {
    await this.dispatchStoreRequest('Games/getQuestionTypeList')

    if (this.questions.length > 0) {
      //populate ?
    } else {
      this.addLine({ lineCount: 0, columnCount: 0 })
      this.addColumn({ lineCount: 1, columnCount: 0 })
    }
    this.middlePanel = this.$refs['panel']
  },
  computed: {
    ...mapState('Assets', {
      assets(state) {
        if (!state.assets || state.assets.length <= 0) return {}

        return state.assets.reduce((dict, asset) => {
          dict[asset.id] = asset

          return dict
        }, {})
      },
    }),
    ...mapState('Games', {
      questionTypes(state) {
        if (!state.question_type_list || Object.keys(state.question_type_list).length <= 0)
          return {}

        return state.question_type_list.reduce((dict, questionType) => {
          dict[questionType.id] = questionType.slug
          return dict
        }, {})
      },
      questionTypeBySlug(state) {
        if (!state.question_type_list || Object.keys(state.question_type_list).length <= 0)
          return {}

        return state.question_type_list.reduce((dict, questionType) => {
          dict[questionType.slug] = questionType.id
          return dict
        }, {})
      },
    }),
    questions() {
      if (!this.game) return []
      return JSON.parse(JSON.stringify(this.game.questions))
    },
    question() {
      if (this.selectedQuestion >= 0) {
        return this.questions[this.selectedQuestion]
      }
      return null
    },
    correction: {
      get() {
        return this.game.correction
      },
      set(value) {
        this.$emit('update-correction', value)
      },
    },
    answers: {
      get() {
        if (!this.question) return []
        return this.question.answers
      },
      set(value) {
        if (this.question) {
          this.$emit('update-question', this.selectedQuestion, {
            answers: value,
          })
        }
      },
    },
    questionCorrection: {
      get() {
        if (!this.question) {
          return ''
        }
        return this.question.correction
      },
      set(value) {
        if (!this.question) {
          console.error('No question found for this cell')
          return
        }

        this.$emit('update-question', this.selectedQuestion, {
          correction: value,
        })
      },
    },
    TxtOrImg_UpdateText: {
      get() {
        if (!this.question) {
          return ''
        }
        return this.question.text
      },
      set(value) {
        if (!this.question) {
          console.error('No question found for this cell')
          return
        }

        this.$emit('update-question', this.selectedQuestion, {
          text: value,
        })
      },
    },
    imageState() {
      return this.$refs['GameTable'].imageState
    },
  },
  methods: {
    SelectMedia(asset) {
      this.$emit('update-question', this.selectedQuestion, {
        media_id: asset.id,
      })
    },
    OpenPanel(question) {
      this.selectedQuestion = this.game.questions.indexOf(question)

      if (!this.question.question_type_id) {
        this.InitQuestion('qcm')
      }

      let questionSlug = this.questionTypes[this.question.question_type_id]
      let tab = 0
      //Associate game types to selected game tabs
      switch (questionSlug) {
        case 'cell_image':
          tab = this.tabOptions.findIndex((tab) => tab.slot == 'text_img')
          break
        case 'cell_text':
          tab = this.tabOptions.findIndex((tab) => tab.slot == 'text_img')
          break
        case 'cell_qcm':
          tab = this.tabOptions.findIndex((tab) => tab.slot == 'qcm')
          break
        case 'cell_fill':
          tab = this.tabOptions.findIndex((tab) => tab.slot == 'fill')
          break
        case 'cell_dropdown':
          tab = this.tabOptions.findIndex((tab) => tab.slot == 'dropdown')
          break
      }

      tab = tab == -1 ? 0 : tab

      if (!this.resetModal) {
        this.$refs['panel'].ChangeTab(tab)
        this.$refs['panel'].show()
      }
    },
    HidePanel() {
      this.selectedLabels = []
      this.selectedQuestion = null
      this.$refs['panel'].hide()
    },
    addLine({ lineCount, columnCount }) {
      this.$emit('add-headers', [
        {
          content: '',
          line: lineCount,
          column: null,
        },
      ])

      let questions = []

      for (var i = 0; i < columnCount; i++) {
        questions.push({
          answers: [],
          text: '',
          column: i,
          question_type_id: null,
          media_id: null,
          line: lineCount,
          correction: '',
        })
      }

      this.$emit('add-questions', questions)
    },
    addColumn({ lineCount, columnCount }) {
      this.$emit('add-headers', [
        {
          content: '',
          line: null,
          column: columnCount,
        },
      ])

      let questions = []

      for (var i = 0; i < lineCount; i++) {
        questions.push({
          answers: [],
          text: '',
          column: columnCount,
          question_type_id: null,
          media_id: null,
          line: i,
          correction: '',
        })
      }

      this.$emit('add-questions', questions)
    },
    deleteLine(line) {
      // Find header for this line and remove it
      let headerIndex = null

      for (var i = 0; i < this.game.headers.length; i++) {
        if (this.game.headers[i].line == line) {
          headerIndex = i
        } else if (this.game.headers[i].line > line) {
          const header = this.game.headers[i]

          this.$emit('update-header', i, {
            line: header.line - 1,
          })
        }
      }

      if (headerIndex != null) {
        this.$emit('delete-headers', [headerIndex])
      }

      // Find questions on this line and remove them
      let questionIndexes = []

      for (var j = 0; j < this.game.questions.length; j++) {
        if (this.game.questions[j].line == line) {
          questionIndexes.push(j)
        } else if (this.game.questions[j].line > line) {
          const question = this.game.questions[j]

          this.$emit('update-question', j, {
            line: question.line - 1,
          })
        }
      }

      this.$emit('delete-questions', questionIndexes)
    },
    deleteColumn(column) {
      // Find header for this column and remove it
      let headerIndex = null

      for (var i = 0; i < this.game.headers.length; i++) {
        if (this.game.headers[i].column == column) {
          headerIndex = i
        } else if (this.game.headers[i].column > column) {
          const header = this.game.headers[i]

          this.$emit('update-header', i, {
            column: header.column - 1,
          })
        }
      }

      if (headerIndex != null) {
        this.$emit('delete-headers', [headerIndex])
      }

      // Find questions on this column and remove them
      let questionIndexes = []

      for (var j = 0; j < this.game.questions.length; j++) {
        if (this.game.questions[j].column == column) {
          questionIndexes.push(j)
        } else if (this.game.questions[j].column > column) {
          const question = this.game.questions[j]

          this.$emit('update-question', j, {
            column: question.column - 1,
          })
        }
      }

      this.$emit('delete-questions', questionIndexes)
    },
    updateHeader(i, data) {
      this.$emit('update-header', i, data)
    },
    InitQuestion(slug) {
      let answers = []

      switch (slug) {
        case 'qcm':
          if (this.question.answers.length == 0) {
            answers.push({
              text: '',
              is_correct: false,
            })
            this.$emit('update-question', this.selectedQuestion, {
              answers,
            })
          }
          break
        default:
          break
      }
    },
    ResetQuestionModal() {
      this.resetModal = true
      this.$refs['alert-modal'].show()
    },
    HideResetQuestionModal() {
      this.$refs['alert-modal'].hide()
      this.resetModal = false
    },
    ResetQuestion() {
      if (this.question) {
        this.$emit('update-question', this.selectedQuestion, {
          answers: [],
          text: '',
          column: this.question.column,
          question_type_id: null,
          media_id: null,
          line: this.question.line,
          correction: '',
        })
      }
      this.HideResetQuestionModal()
    },
    DeleteAnswer(answerIndex) {
      if (this.answers[answerIndex]) {
        this.answers.splice(answerIndex, 1)
        this.$emit('update-question', this.selectedQuestion, {
          answers: this.answers,
        })
      }
    },
    QCM_CheckAnswer() {
      let answers = [...this.question.answers]

      answers[0].is_correct = answers[0].is_correct ? false : true
      this.$emit('update-question', this.selectedQuestion, {
        answers,
      })
    },
    allowDrop: function (event) {
      // this.draggedAssetX = event.clientX
      // this.rightPanelX = this.$refs["rightPanel"].$el.offsetLeft
      event.preventDefault()
    },
    async TxtOrImg_dropImageInQuestion(e) {
      if (e.dataTransfer) {
        const assetJSON = e.dataTransfer.getData('application/json')
        const asset = assetJSON ? JSON.parse(assetJSON) : null
        // let image = await this.dispatchStoreRequest("Assets/getAssetFromID", asset.id)
        this.$emit('update-question', this.selectedQuestion, {
          media_id: asset.id,
        })
        this.middlePanel.$forceUpdate()
      }
    },
    TxtOrImg_DeleteImage() {
      this.$emit('update-question', this.selectedQuestion, {
        media_id: null,
      })
    },
    TxtOrImg_OpenMediaChoice() {
      this.showRightPanel()
    },
    showRightPanel() {
      this.$refs['GameTable'].ShowRightPanel()
    },
    Dropdown_addDefaultAnswer() {
      let newAnswers = [...this.answers]
      newAnswers.push({
        text: '',
        is_correct: false,
      })
      this.$emit('update-question', this.selectedQuestion, {
        answers: newAnswers,
      })
    },
    Dropdown_AnswerText(answerIndex, value) {
      this.answers[answerIndex].text = value
      this.$emit('update-question', this.selectedQuestion, {
        answers: this.answers,
      })
    },
    Dropdown_CheckAnswer(answerIndex) {
      let answers = [...this.question.answers]

      answers[answerIndex].is_correct = answers[answerIndex].is_correct ? false : true
      this.$emit('update-question', this.selectedQuestion, {
        answers,
      })
    },
    Fill_SetMainAnswerText({ index }) {
      let i = index
      let answers = [...this.answers]
      if (answers[i]) {
        let splittedAnswer = answers[i].text.split(';')
        // splittedAnswer[0] = value
        let joinedVal = splittedAnswer.join(';')
        answers[i].text = joinedVal
        this.answers = answers
      }
    },
    Fill_setAValue(e, answerIndex) {
      if (this.answers[answerIndex]) {
        let joinedVal = e.join(';')
        this.answers[answerIndex].text = joinedVal
        this.$emit('update-question', this.selectedQuestion, {
          answers: this.answers,
        })
      }
    },
    Fill_getValues(index) {
      if (this.answers && this.answers[index] && this.answers[index].text) {
        return this.answers[index].text.split(';')
      }
      return []
    },
    selectTab(val) {
      let typeID = null

      //Associate game types to selected game tabs
      switch (this.tabOptions[val].slot) {
        case 'text_img':
          if (this.question.media_id) {
            typeID = this.questionTypeBySlug['cell_image']
          } else {
            typeID = this.questionTypeBySlug['cell_text']
          }
          break
        case 'qcm':
          typeID = this.questionTypeBySlug['cell_qcm']
          break
        case 'fill':
          typeID = this.questionTypeBySlug['cell_fill']
          break
        case 'dropdown':
          typeID = this.questionTypeBySlug['cell_dropdown']
          break
      }

      this.$emit('update-question', this.selectedQuestion, {
        question_type_id: typeID,
      })
    },
  },
}
</script>

<style lang="scss">
.label {
  @apply flex-row rounded-full px-3 py-1 m-2;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  .content {
    @apply ml-1 text-sm;
    min-width: 1rem;
    min-height: 1rem;
  }
}
</style>
<style lang="scss" scoped>
.twn-textarea {
  height: 2.5rem;
}

.number {
  @apply text-xs text-principal-list-item w-4 h-4 rounded-full flex justify-center items-center p-3;
  background-color: rgba(220, 151, 153, 0.2);
}

.style-chooser {
  border: 1px solid #e1e1e1;
  border-radius: 3px;
}
</style>
