export function debounce(func, wait, immediate) {
  var timeout
  return function () {
    var context = this,
      args = arguments
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }, wait)
    if (immediate && !timeout) func.apply(context, args)
  }
}

export function parseJWT(token) {
  let base64Url = token.split('.')[1]
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')

  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

// TODO: Don't store user with a refresh token because we can use the refresh token cookie to auth between refresh/tabs ?
export const userStorage = {
  storageKey: 'user',

  get() {
    const userJSON = localStorage.getItem(this.storageKey)

    if (!userJSON) return null

    try {
      const user = JSON.parse(userJSON)

      return user
    } catch (error) {
      return null
    }
  },

  set(user) {
    localStorage.setItem(this.storageKey, JSON.stringify(user))
  },

  clear() {
    localStorage.clear()
  },
}

// Unsafe simple encrypt/decrypt algorythm without dependencies
export function unsafeEncrypt(salt, text) {
  if (!text) {
    return ''
  } else if (!salt) {
    return text
  }

  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0))
  const byteHex = (n) => ('0' + Number(n).toString(16)).substr(-2)
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code)

  return text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('')
}

export function unsafeDecrypt(salt, encoded) {
  if (!encoded) {
    return ''
  } else if (!salt) {
    return encoded
  }

  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0))
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code)

  return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join('')
}

export const uniqBy = (arr, predicate) => {
  const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate]

  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item)

        map.has(key) || map.set(key, item)

        return map
      }, new Map())
      .values(),
  ]
}
