import { GC_GET_LOCATION_LIST } from '@/graphql/location'
import apollo from '@/apolloClient'

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_LOCATION_LIST(state, locations) {
      state.list = locations
    },
  },
  actions: {
    async getList({ commit }) {
      const response = await apollo.query({ query: GC_GET_LOCATION_LIST })

      commit('SET_LOCATION_LIST', response.data.location)
    },
  },
}
