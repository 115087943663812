const _eventQueueKey = 'browserEventBusStorage'
const _eventExpirationDelay = 1000 * 60 * 5
const _handlerByEventCategory = {}

let _lastStorageEventTime = Date.now()

function _getEventQueue() {
  return JSON.parse(window.localStorage.getItem(_eventQueueKey) || '[]')
}

function _setEventQueue(eventQueue) {
  window.localStorage.setItem(_eventQueueKey, JSON.stringify(eventQueue))
}

// Handle cross tab/window event emiting
function _onStorageEvent(event) {
  if (event.key && event.key == _eventQueueKey) {
    // Check event queue for unhandled events
    const eventQueue = JSON.parse(event.newValue)

    for (var i = eventQueue.length - 1; i >= 0; i--) {
      const storageEvent = eventQueue[i]

      if (storageEvent.timestamp > _lastStorageEventTime) {
        const handlerByType = _handlerByEventCategory[storageEvent.category]

        if (handlerByType && handlerByType[storageEvent.type]) {
          const handlers = handlerByType[storageEvent.type]

          for (var j = 0; j < handlers.length; j++) {
            handlers[j](storageEvent.payload)
          }
        }
      } else {
        // We use a reverse for loop so we can stop at the first already handled event
        break
      }
    }

    _lastStorageEventTime = Date.now()
  }
}

window.addEventListener('storage', _onStorageEvent, false)

export const eventType = {
  add: 'add',
  update: 'update',
  delete: 'delete',
}

export const eventCategory = {
  scenario: 'scenario',
  activity: 'activity',
}

// Add an handler function to be called when an event of the specified category and type is emited
export function on(eventCategory, eventType, handler) {
  if (!_handlerByEventCategory[eventCategory]) {
    _handlerByEventCategory[eventCategory] = {}
  }

  if (!_handlerByEventCategory[eventCategory][eventType]) {
    _handlerByEventCategory[eventCategory][eventType] = []
  }

  // Check if this handler is not already registered
  for (var i = 0; i < _handlerByEventCategory[eventCategory][eventType].length; i++) {
    if (_handlerByEventCategory[eventCategory][eventType][i] === handler) {
      return
    }
  }

  _handlerByEventCategory[eventCategory][eventType].push(handler)
}

// Remove an handler from all event type in a category
export function off(eventCategory, handler) {
  if (!_handlerByEventCategory[eventCategory]) {
    return
  }

  const eventTypes = Object.keys(eventType)

  for (var i = 0; i < eventTypes.length; i++) {
    const eventType = eventTypes[i]
    const handlers = _handlerByEventCategory[eventCategory][eventType]

    if (!handlers) {
      continue
    }

    _handlerByEventCategory[eventCategory][eventType] = handlers.filter((h) => {
      return h !== handler
    })
  }
}

export function emit(eventCategory, eventType, payload) {
  const now = Date.now()

  let eventQueue = _getEventQueue()

  // Remove expired event
  let firstValidIndex = 0

  for (var i = 0; i < eventQueue.length; i++) {
    if (eventQueue[i].timestamp + _eventExpirationDelay > now) {
      firstValidIndex = i
      break
    }
  }

  eventQueue = eventQueue.splice(0, firstValidIndex)

  // Add the new event to queue
  // todo: check for concurency ? sync ? add a lock ?
  eventQueue.push({
    timestamp: now,
    category: eventCategory,
    type: eventType,
    payload,
  })
  _setEventQueue(eventQueue)
}

export default {
  eventType,
  eventCategory,
  on,
  emit,
  off,
  // Syntax sugar/helper for event typed 'on' function
  onAdd: (eventCategory, handler) => on(eventCategory, eventType.add, handler),
  onUpdate: (eventCategory, handler) => on(eventCategory, eventType.update, handler),
  onDelete: (eventCategory, handler) => on(eventCategory, eventType.delete, handler),
  // Syntax sugar/helper for event typed 'emit' function
  emitAdd: (eventCategory, payload) => emit(eventCategory, eventType.add, payload),
  emitUpdate: (eventCategory, payload) => emit(eventCategory, eventType.update, payload),
  emitDelete: (eventCategory, payload) => emit(eventCategory, eventType.delete, payload),
}
