<template>
  <!-- Empty/Loading states -->
  <p v-if="!course" class="mt-4 text-center">Aucun parcours sélectionné</p>
  <p v-else-if="!course.id" class="mt-4 text-center">Chargement du parcours...</p>

  <!-- Content -->
  <div v-else class="mt-4 border rounded p-4">
    <!-- Header -->
    <div class="flex mb-4">
      <div class="w-1/4"></div>
      <div class="w-1/4"></div>

      <template v-if="promotionType && promotionType.slug == 'collective_promotion'">
        <p class="w-1/4 px-2 text-center">Date</p>
        <p class="w-1/4 px-2 text-center">Disponible jusqu'au</p>
      </template>
      <template v-else>
        <p class="w-1/4 px-2 text-center">Du</p>
        <p class="w-1/4 px-2 text-center">Au</p>
      </template>
    </div>

    <!-- List -->
    <template v-for="(module, moduleIndex) in course.modules">
      <div class="flex mb-4" v-for="sequence in module.sequences" :key="sequence.id">
        <p class="w-1/4">
          {{
            'Niveau ' +
            (moduleIndex + 1) +
            ' - ' +
            module.name +
            (module.title ? ' - ' + module.title : '')
          }}
        </p>
        <p class="w-1/4">{{ sequence.name + ' - ' + sequence.title }}</p>

        <div class="w-1/4 px-2">
          <b-form-datepicker
            :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'long',
            }"
            :min="promotion.startDate"
            :max="promotion.endDate"
            :value="sequencesData[sequence.id] ? sequencesData[sequence.id].startDate : null"
            @input="onSequenceStartDateUpdate(sequence.id, $event)"
          ></b-form-datepicker>
        </div>

        <div class="w-1/4 px-2">
          <b-form-datepicker
            :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'long',
            }"
            :min="
              sequencesData[sequence.id] && sequencesData[sequence.id].startDate
                ? sequencesData[sequence.id].startDate
                : promotion.startDate
            "
            :max="promotion.endDate"
            :value="sequencesData[sequence.id] ? sequencesData[sequence.id].endDate : null"
            @input="onSequenceEndDateUpdate(sequence.id, $event)"
          ></b-form-datepicker>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    course: {
      type: Object,
    },
    promotion: {
      type: Object,
      required: true,
    },
    promotionType: {
      type: Object,
    },
  },
  computed: {
    sequencesData() {
      if (!this.promotion || !this.promotion.sequences) return {}

      return this.promotion.sequences.reduce((dictionary, sequence) => {
        if (sequence.userID == null) {
          dictionary[sequence.id] = sequence
        }

        return dictionary
      }, {})
    },
  },
  watch: {
    'promotion.startDate': {
      handler(newDate) {
        const startDate = new Date(newDate)

        if (!this.promotion.sequences || !startDate || isNaN(startDate)) return

        // Update sequence with out of bounds dates
        this.promotion.sequences.forEach((sequence) => {
          if (sequence.startDate && sequence.startDate < startDate) {
            this.onSequenceStartDateUpdate(sequence.id, newDate)
          }
          if (sequence.endDate && sequence.endDate < startDate) {
            this.onSequenceStartDateUpdate(sequence.id, newDate)
          }
        })
      },
      immediate: true,
    },
    'promotion.endDate': {
      handler(newDate) {
        const endDate = new Date(newDate)

        if (!this.promotion.sequences || !endDate || isNaN(endDate)) return

        // Update sequence with out of bounds dates
        this.promotion.sequences.forEach((sequence) => {
          if (sequence.startDate && sequence.startDate > endDate) {
            this.onSequenceStartDateUpdate(sequence.id, newDate)
          }
          if (sequence.endDate && sequence.endDate > endDate) {
            this.onSequenceEndDateUpdate(sequence.id, newDate)
          }
        })
      },
      immediate: true,
    },
  },
  methods: {
    onSequenceStartDateUpdate(sequenceID, newDate) {
      const startDate = new Date(newDate)

      if (!startDate || isNaN(startDate)) return

      this.$emit('set-sequence-start', { sequenceID, startDate })

      // Check if end date is out of bounds
      const data = this.sequencesData[sequenceID]

      if (data && data.endDate && startDate > data.endDate) {
        this.onSequenceEndDateUpdate(sequenceID, newDate)
      }
    },
    onSequenceEndDateUpdate(sequenceID, newDate) {
      const endDate = new Date(newDate)

      if (!endDate || isNaN(endDate)) return

      this.$emit('set-sequence-end', { sequenceID, endDate })
    },
  },
}
</script>

<style lang="scss"></style>
