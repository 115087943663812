import axios from 'axios'
import aws from 'aws4'

const JWT_SIGN = (config) => {
  const signedRequest = aws.sign(config, {
    secretAccessKey: process.env.VUE_APP_AWS_SECRET_KEY,
    accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY,
  })

  // Remove unsafe headers for browser
  const unsafeHeaders = ['content-length', 'host']

  const headerKeys = Object.keys(signedRequest.headers)

  for (var i = 0; i < headerKeys.length; i++) {
    if (unsafeHeaders.indexOf(headerKeys[i].toLowerCase()) > -1) {
      delete signedRequest.headers[headerKeys[i]]
    }
  }

  return signedRequest
}

const getAWSPutRequestForFile = (file, filePath) => {
  const fileData = {
    filePath,
    contentType: file.type,
  }

  const config = {
    host: process.env.VUE_APP_AWS_HOST,
    url: 'https://' + process.env.VUE_APP_AWS_HOST + process.env.VUE_APP_AWS_LAMBDA_PATH,
    method: 'PUT',
    data: fileData,
    body: JSON.stringify(fileData),
    region: process.env.VUE_APP_AWS_REGION,
    path: process.env.VUE_APP_AWS_LAMBDA_PATH,
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const signedRequest = JWT_SIGN(config)

  return axios(signedRequest)
}

const getAWSDeleteRequestForFilePath = (filePath) => {
  const path = process.env.VUE_APP_AWS_LAMBDA_PATH + `?filePath=${filePath}`

  const config = {
    host: process.env.VUE_APP_AWS_HOST,
    url: 'https://' + process.env.VUE_APP_AWS_HOST + path,
    method: 'DELETE',
    region: process.env.VUE_APP_AWS_REGION,
    path,
  }

  const signedRequest = JWT_SIGN(config)

  return axios(signedRequest)
}

export default {
  getAWSPutRequestForFile,
  getAWSDeleteRequestForFilePath,
}
