<template>
  <div>
    <List
      v-if="!isLoading"
      listing="group"
      :fields="fields"
      order-by="end_date: desc, start_date: asc"
      search-text="Rechercher une promotion"
      :pageSize="pageSize"
      edit-route-name="groups-edit"
    >
      <template v-slot:buttons>
        <div class="md:flex flex-row justify-around hidden md:w-1/3">
          <button
            v-if="!isReadOnly"
            type="button"
            class="twn-button secondary whitespace-nowrap overflow-hidden text-xs mr-2"
            @click="onAddPromotionClick"
          >
            Créer un programme
          </button>
        </div>
      </template>
    </List>
  </div>
</template>

<script>
import List from '@/components/List'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    List,
  },
  props: {
    pageSize: {
      type: Number,
      default: 30,
      required: false,
    },
  },
  data: () => ({
    isLoading: true,
    fields: [
      {
        key: 'identifier',
        label: 'ID',
        sortable: true,
      },
      {
        key: 'name',
        label: 'Nom',
        sortable: true,
      },
      {
        key: 'start_date',
        db: 'start_date',
        label: 'Début',
        sortable: true,
      },
      {
        key: 'end_date',
        db: 'end_date',
        label: 'Fin',
        sortable: true,
      },
      {
        key: 'users_aggregate',
        label: 'Étudiants',
        sortable: true,
        db: `users_aggregate {
              aggregate {
                count
              }
            }`,
        nested: 'count',
      },
    ],
  }),
  computed: {
    ...mapState('Auth', ['userInfo']),
    isSuperAdmin() {
      return this.userInfo && this.userInfo.role == 'superadmin'
    },
    isReadOnly() {
      return !this.isSuperAdmin
    },
  },
  async mounted() {
    this.isLoading = false
  },
  methods: {
    ...mapActions('EditItem', ['clearCurrentID']),
    onAddPromotionClick() {
      this.clearCurrentID()
      this.$router.push({ name: 'groups-create' })
    },
  },
}
</script>
