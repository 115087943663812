import { GC_GET_TRIGGER_TYPE_LIST } from '@/graphql/trigger'
import apollo from '@/apolloClient'

export default {
  namespaced: true,
  state: {
    list: [],
    type_list: [],
  },
  mutations: {
    SET_TRIGGER_TYPE_LIST(state, types) {
      state.type_list = types
    },
  },
  actions: {
    async getTypeList({ commit }) {
      const response = await apollo.query({ query: GC_GET_TRIGGER_TYPE_LIST })

      commit('SET_TRIGGER_TYPE_LIST', response.data.trigger_type)
    },
  },
}
