<template>
  <div v-if="editor" class="text-editor" :class="{ 'all-features': allFeatures }">
    <div class="tool-bar flex flex-wrap bg-gray-comment rounded-t">
      <div class="flex px-2">
        <button
          @click="editor.chain().focus().toggleBold().run()"
          :disabled="!editor.can().chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }"
        >
          <svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
            <path
              class="filled"
              d="M6.15751 9.75001C7.73701 9.75001 8.71651 8.93476 8.71651 7.63276C8.71651 6.65326 7.97851 5.92051 6.97351 5.84326V5.80201C7.35839 5.74406 7.70988 5.55047 7.96458 5.25616C8.21928 4.96186 8.36041 4.58621 8.36251 4.19701C8.36251 3.06451 7.49101 2.35201 6.10201 2.35201H2.88226V9.75001H6.15751ZM4.43101 3.50551H5.70301C6.42526 3.50551 6.84076 3.84376 6.84076 4.43851C6.84076 5.06401 6.36901 5.42851 5.54326 5.42851H4.43101V3.50476V3.50551ZM4.43101 8.59651V6.44851H5.72851C6.64126 6.44851 7.13851 6.81751 7.13851 7.50976C7.13851 8.21701 6.65626 8.59651 5.76451 8.59651H4.43026H4.43101Z"
            />
          </svg>
        </button>

        <button
          @click="editor.chain().focus().toggleItalic().run()"
          :disabled="!editor.can().chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }"
        >
          <svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
            <path
              class="filled"
              d="M9.5 3.5V2H4.5V3.5H5.934L4.506 8.5H2.5V10H7.5V8.5H6.066L7.494 3.5H9.5Z"
            />
          </svg>
        </button>

        <button
          @click="editor.chain().focus().toggleUnderline().run()"
          :disabled="!editor.can().chain().focus().toggleUnderline().run()"
          :class="{ 'is-active': editor.isActive('underline') }"
        >
          <svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
            <path
              class="filled"
              d="M9.65625 9.42188H2.34375C2.29219 9.42188 2.25 9.46172 2.25 9.51094V10.2234C2.25 10.2727 2.29219 10.3125 2.34375 10.3125H9.65625C9.70781 10.3125 9.75 10.2727 9.75 10.2234V9.51094C9.75 9.46172 9.70781 9.42188 9.65625 9.42188ZM6 8.53125C6.81328 8.53125 7.57734 8.21367 8.15391 7.63828C8.73047 7.06289 9.04688 6.29766 9.04688 5.48438V1.82812C9.04688 1.75078 8.98359 1.6875 8.90625 1.6875H8.20312C8.12578 1.6875 8.0625 1.75078 8.0625 1.82812V5.48438C8.0625 6.62109 7.13672 7.54688 6 7.54688C4.86328 7.54688 3.9375 6.62109 3.9375 5.48438V1.82812C3.9375 1.75078 3.87422 1.6875 3.79688 1.6875H3.09375C3.01641 1.6875 2.95312 1.75078 2.95312 1.82812V5.48438C2.95312 6.29766 3.2707 7.06172 3.84609 7.63828C4.42148 8.21484 5.18672 8.53125 6 8.53125Z"
            />
          </svg>
        </button>

        <button
          v-if="allFeatures"
          @click="setLink"
          :class="{ 'is-active': editor.isActive('link') }"
        >
          <svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
            <path
              class="stroked"
              d="M6.91421 5.08579C6.13316 4.30474 4.86684 4.30474 4.08579 5.08579L2.08579 7.08579C1.30474 7.86683 1.30474 9.13316 2.08579 9.91421C2.86683 10.6953 4.13316 10.6953 4.91421 9.91421L5.46499 9.36343M5.08579 6.91421C5.86683 7.69526 7.13316 7.69526 7.91421 6.91421L9.91421 4.91421C10.6953 4.13316 10.6953 2.86683 9.91421 2.08579C9.13316 1.30474 7.86683 1.30474 7.08579 2.08579L6.53598 2.63559"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>

      <div v-if="allFeatures" class="flex px-2">
        <button
          @click="editor.chain().focus().setTextAlign('left').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
        >
          <svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
            <path
              class="filled"
              d="M2 2.99999C2 2.66862 2.26863 2.39999 2.6 2.39999H9.4C9.73137 2.39999 10 2.66862 10 2.99999C10 3.33136 9.73137 3.59999 9.4 3.59999H2.6C2.26863 3.59999 2 3.33136 2 2.99999Z"
              fill="#174489"
            />
            <path
              class="filled"
              d="M2 5.99999C2 5.66862 2.26863 5.39999 2.6 5.39999H5.4C5.73137 5.39999 6 5.66862 6 5.99999C6 6.33136 5.73137 6.59999 5.4 6.59999H2.6C2.26863 6.59999 2 6.33136 2 5.99999Z"
            />
            <path
              class="filled"
              d="M2 8.99999C2 8.66862 2.26863 8.39999 2.6 8.39999H7.4C7.73138 8.39999 8 8.66862 8 8.99999C8 9.33137 7.73138 9.59999 7.4 9.59999H2.6C2.26863 9.59999 2 9.33137 2 8.99999Z"
            />
          </svg>
        </button>
        <button
          @click="editor.chain().focus().setTextAlign('center').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
        >
          <svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
            <path
              class="filled"
              d="M2 2.99999C2 2.66862 2.26863 2.39999 2.6 2.39999H9.4C9.73137 2.39999 10 2.66862 10 2.99999C10 3.33136 9.73137 3.59999 9.4 3.59999H2.6C2.26863 3.59999 2 3.33136 2 2.99999Z"
            />
            <path
              class="filled"
              d="M4 5.99999C4 5.66862 4.26863 5.39999 4.6 5.39999H7.4C7.73137 5.39999 8 5.66862 8 5.99999C8 6.33136 7.73137 6.59999 7.4 6.59999H4.6C4.26863 6.59999 4 6.33136 4 5.99999Z"
            />
            <path
              class="filled"
              d="M3 8.99999C3 8.66862 3.26863 8.39999 3.6 8.39999H8.4C8.73138 8.39999 9 8.66862 9 8.99999C9 9.33137 8.73138 9.59999 8.4 9.59999H3.6C3.26863 9.59999 3 9.33137 3 8.99999Z"
            />
          </svg>
        </button>
        <button
          @click="editor.chain().focus().setTextAlign('right').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
        >
          <svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
            <path
              class="filled"
              d="M2 2.99999C2 2.66862 2.26863 2.39999 2.6 2.39999H9.4C9.73137 2.39999 10 2.66862 10 2.99999C10 3.33136 9.73137 3.59999 9.4 3.59999H2.6C2.26863 3.59999 2 3.33136 2 2.99999Z"
            />
            <path
              class="filled"
              d="M6 5.99999C6 5.66862 6.26863 5.39999 6.6 5.39999H9.4C9.73137 5.39999 10 5.66862 10 5.99999C10 6.33136 9.73137 6.59999 9.4 6.59999H6.6C6.26863 6.59999 6 6.33136 6 5.99999Z"
            />
            <path
              class="filled"
              d="M4 8.99999C4 8.66862 4.26863 8.39999 4.6 8.39999H9.4C9.73138 8.39999 10 8.66862 10 8.99999C10 9.33137 9.73138 9.59999 9.4 9.59999H4.6C4.26863 9.59999 4 9.33137 4 8.99999Z"
            />
          </svg>
        </button>
      </div>

      <div v-if="allFeatures" class="flex px-2">
        <button
          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
        >
          <svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 7">
            <path
              class="filled"
              d="M0.885449 7V-4.76837e-07H2.18545V2.66H4.98545V-4.76837e-07H6.28545V7H4.98545V3.86H2.18545V7H0.885449ZM9.16072 2.1H9.37772V7H8.80372V2.982L7.90072 3.507V2.863L9.16072 2.1Z"
            />
          </svg>
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
        >
          <svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 7">
            <path
              class="filled"
              d="M0.205274 7V-4.76837e-07H1.50527V2.66H4.30527V-4.76837e-07H5.60527V7H4.30527V3.86H1.50527V7H0.205274ZM7.20654 7V6.727L9.07554 4.333C9.38354 3.934 9.47454 3.71 9.47454 3.402C9.47454 3.164 9.41154 2.982 9.29254 2.842C9.16654 2.695 8.97754 2.604 8.70454 2.604C8.23554 2.604 7.94854 2.954 7.89954 3.444L7.31154 3.332C7.36054 2.611 7.94154 2.044 8.70454 2.044C9.18754 2.044 9.53754 2.212 9.76854 2.471C9.97854 2.709 10.0765 3.017 10.0765 3.402C10.0765 3.815 9.93654 4.137 9.68454 4.466L8.15854 6.44H10.1675V7H7.20654Z"
            />
          </svg>
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
        >
          <svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 7">
            <path
              class="filled"
              d="M0.17793 7V-4.76837e-07H1.47793V2.66H4.27793V-4.76837e-07H5.57793V7H4.27793V3.86H1.47793V7H0.17793ZM7.1022 5.656L7.6902 5.579C7.7742 6.16 8.0962 6.496 8.6632 6.496C9.2302 6.496 9.6432 6.132 9.6432 5.46C9.6432 4.788 9.2302 4.424 8.6632 4.424C8.5162 4.424 8.3762 4.445 8.2292 4.515L7.9842 4.186L9.1042 2.66H7.3332V2.1H9.9932V2.289L8.7472 3.976C8.7892 3.962 8.8942 3.955 8.9432 3.955C9.6922 3.955 10.2452 4.557 10.2452 5.46C10.2452 6.398 9.5942 7.056 8.6632 7.056C7.8512 7.056 7.1862 6.51 7.1022 5.656Z"
            />
          </svg>
        </button>
      </div>

      <div v-if="allFeatures" class="flex px-2">
        <button
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': editor.isActive('bulletList') }"
        >
          <svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
            <path
              class="filled"
              d="M4 2.99999C4 2.66862 4.26863 2.39999 4.6 2.39999H9.39999C9.73137 2.39999 9.99999 2.66862 9.99999 2.99999C9.99999 3.33136 9.73137 3.59999 9.39999 3.59999H4.6C4.26863 3.59999 4 3.33136 4 2.99999Z"
            />
            <path
              class="filled"
              d="M4 5.99999C4 5.66862 4.26863 5.39999 4.6 5.39999H9.4C9.73137 5.39999 10 5.66862 10 5.99999C10 6.33136 9.73137 6.59999 9.4 6.59999H4.6C4.26863 6.59999 4 6.33136 4 5.99999Z"
            />
            <path
              class="filled"
              d="M4 8.99999C4 8.66862 4.26863 8.39999 4.6 8.39999H9.4C9.73137 8.39999 10 8.66862 10 8.99999C10 9.33137 9.73137 9.59999 9.4 9.59999H4.6C4.26863 9.59999 4 9.33137 4 8.99999Z"
            />
            <path
              class="filled"
              d="M1.99999 3C1.99999 2.66863 2.26862 2.4 2.59999 2.4C2.93136 2.4 3.19999 2.66863 3.19999 3C3.19999 3.33137 2.93136 3.6 2.59999 3.6C2.26862 3.6 1.99999 3.33137 1.99999 3Z"
            />
            <path
              class="filled"
              d="M1.99999 6C1.99999 5.66863 2.26862 5.4 2.59999 5.4C2.93136 5.4 3.19999 5.66863 3.19999 6C3.19999 6.33137 2.93136 6.6 2.59999 6.6C2.26862 6.6 1.99999 6.33137 1.99999 6Z"
            />
            <path
              class="filled"
              d="M1.99999 9C1.99999 8.66863 2.26862 8.4 2.59999 8.4C2.93136 8.4 3.19999 8.66863 3.19999 9C3.19999 9.33137 2.93136 9.6 2.59999 9.6C2.26862 9.6 1.99999 9.33137 1.99999 9Z"
            />
          </svg>
        </button>
        <button
          @click="editor.chain().focus().toggleOrderedList().run()"
          :class="{ 'is-active': editor.isActive('orderedList') }"
        >
          <svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
            <path
              class="filled"
              d="M4 2.99999C4 2.66862 4.26863 2.39999 4.6 2.39999H9.39999C9.73137 2.39999 9.99999 2.66862 9.99999 2.99999C9.99999 3.33136 9.73137 3.59999 9.39999 3.59999H4.6C4.26863 3.59999 4 3.33136 4 2.99999Z"
            />
            <path
              class="filled"
              d="M4 5.99999C4 5.66862 4.26863 5.39999 4.6 5.39999H9.4C9.73137 5.39999 10 5.66862 10 5.99999C10 6.33137 9.73137 6.59999 9.4 6.59999H4.6C4.26863 6.59999 4 6.33137 4 5.99999Z"
            />
            <path
              class="filled"
              d="M4 8.99999C4 8.66862 4.26863 8.39999 4.6 8.39999H9.4C9.73137 8.39999 10 8.66862 10 8.99999C10 9.33137 9.73137 9.59999 9.4 9.59999H4.6C4.26863 9.59999 4 9.33137 4 8.99999Z"
            />
            <path
              class="filled"
              d="M2.89339 1.89999H3.02239V3.99999H2.70439V2.34999L2.31139 2.58399V2.24799L2.89339 1.89999Z"
            />
            <path
              class="filled"
              d="M2.03432 6.99999V6.86799L2.80832 5.87499C2.94332 5.70399 2.97632 5.61099 2.97632 5.48499C2.97632 5.29299 2.88032 5.16399 2.68832 5.16399C2.50532 5.16399 2.40032 5.29599 2.37632 5.48499L2.07632 5.42799C2.09732 5.11599 2.34932 4.87599 2.68832 4.87599C3.04232 4.87599 3.29732 5.09799 3.29732 5.47299C3.29732 5.63499 3.24332 5.79999 3.12332 5.95299L2.52632 6.71199H3.33632V6.99999H2.03432Z"
            />
            <path
              class="filled"
              d="M2 9.42099L2.309 9.38199C2.345 9.60399 2.468 9.73599 2.687 9.73599C2.903 9.73599 3.062 9.59499 3.062 9.33099C3.062 9.05799 2.9 8.91999 2.687 8.91999C2.624 8.91999 2.57 8.93199 2.504 8.96199L2.378 8.79399L2.813 8.18799H2.102V7.89999H3.272V8.00799L2.762 8.70399C2.786 8.69799 2.822 8.69499 2.849 8.69499C3.173 8.69499 3.38 8.96499 3.38 9.33099C3.38 9.73299 3.074 10.024 2.687 10.024C2.327 10.024 2.024 9.77199 2 9.42099Z"
            />
          </svg>
        </button>
      </div>

      <div v-if="allFeatures && !noMedias" class="flex px-2">
        <button v-b-modal.modal-add-image>
          <svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
            <path
              class="filled"
              d="M2.39999 1.8C1.73725 1.8 1.2 2.33726 1.2 3V9C1.2 9.66274 1.73725 10.2 2.39999 10.2H9.59998C10.2627 10.2 10.8 9.66274 10.8 9V3C10.8 2.33726 10.2627 1.8 9.59998 1.8H2.39999ZM9.59998 9H2.39999L4.79999 4.2L6.59998 7.8L7.79998 5.4L9.59998 9Z"
            />
          </svg>
        </button>

        <button v-b-modal.modal-add-pdf>
          <svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
            <path
              class="stroked"
              d="M7.58579 3.5L4.29289 6.79289C3.90237 7.18342 3.90237 7.81658 4.29289 8.20711C4.68342 8.59763 5.31658 8.59763 5.70711 8.20711L8.91421 4.91421C9.69526 4.13316 9.69526 2.86683 8.91421 2.08579C8.13316 1.30474 6.86683 1.30474 6.08579 2.08579L2.87868 5.37868C1.70711 6.55025 1.70711 8.44975 2.87868 9.62132C4.05025 10.7929 5.94975 10.7929 7.12132 9.62132L10.25 6.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>

    <editor-content :editor="editor" class="h-full" />

    <b-modal
      v-if="allFeatures && !noMedias"
      ref="modal-add-image"
      class="bootstrap"
      centered
      hide-footer
      id="modal-add-image"
      hide-header
    >
      <div class="d-block text-center my-6 uppercase font-semibold">
        <label for="mage" class="uppercase font-principal-medium text-sm w-full">
          Selectionnez une image
        </label>

        <v-select
          id="image"
          class="twn-select inline-block pl-2 w-3/4"
          placeholder="Choisissez une image"
          :getOptionLabel="(option) => option.title || option.name"
          :options="images"
          v-model="file"
        />
      </div>

      <div class="flex flex-row justify-evenly items-center">
        <button type="button" class="mt-4 twn-button" @click="cancelModal('image')">Retour</button>
        <button type="button" class="mt-4 twn-button success" @click="addImage" :disabled="!file">
          Ajouter
        </button>
      </div>
    </b-modal>

    <b-modal
      v-if="allFeatures && !noMedias"
      ref="modal-add-pdf"
      class="bootstrap"
      centered
      hide-footer
      id="modal-add-pdf"
      hide-header
    >
      <div class="d-block text-center my-6 uppercase font-semibold">
        <label for="pdf" class="uppercase font-principal-medium text-sm w-full">
          Selectionnez un pdf
        </label>

        <v-select
          id="pdf"
          class="twn-select inline-block pl-2 w-3/4"
          placeholder="Choisissez un pdf"
          :options="pdfs"
          v-model="file"
          :getOptionLabel="(option) => option.title || option.name"
        />
      </div>

      <div class="flex flex-row justify-evenly items-center">
        <button type="button" class="mt-4 twn-button" @click="cancelModal('pdf')">Retour</button>
        <button type="button" class="mt-4 twn-button success" @click="addPdf" :disabled="!file">
          Ajouter
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import TextAlign from '@tiptap/extension-text-align'
import Image from '@tiptap/extension-image'
import PDFButton from '@/tiptap/PDFButton'

export default {
  name: 'TextEditor',

  components: {
    EditorContent,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    allFeatures: {
      type: Boolean,
      default: false,
    },
    noMedias: {
      type: Boolean,
      default: false,
    },
    medias: {
      type: Array,
      default: () => [],
    },
    pageId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      editor: null,
      file: null,
    }
  },

  computed: {
    pdfs() {
      return this.medias.filter((media) => media.type.slug === 'file_pdf')
    },
    images() {
      return this.medias.filter((media) => media.type.slug === 'file_image')
    },
  },

  watch: {
    value(value) {
      if (this.editor.getHTML() === value) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },

  mounted() {
    function insertClasses(classes) {
      return { HTMLAttributes: { class: classes } }
    }
    let extensions = [
      StarterKit.configure({
        heading: insertClasses('text-blue text-blue-default font-semibold mb-4'),
        paragraph: insertClasses('mb-4'),
        bold: insertClasses('font-bold'),
        bulletList: insertClasses('list-disc pl-4 mb-4'),
        orderedList: insertClasses('list-decimal pl-4 mb-4'),
      }),
      Underline,
    ]

    if (this.allFeatures) {
      extensions = extensions.concat(
        Link.configure(insertClasses('text-blue text-blue-default font-bold underline')),
        Image.configure({
          inline: true,
          ...insertClasses('max-h-full max-w-full inline-block rounded-md'),
        }),
        PDFButton,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        })
      )
    }

    this.editor = new Editor({
      content: this.value,
      extensions,
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML())
      },
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },

  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const href = prompt('URL', previousUrl)

      if (href === null) {
        return
      } else if (href === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run()
      } else {
        this.editor.chain().focus().extendMarkRange('link').setLink({ href }).run()
      }
    },

    cancelModal(type) {
      this.file = null
      this.$bvModal.hide('modal-add-' + type)
    },

    addImage() {
      this.editor.commands.setImage({ src: process.env.VUE_APP_AWS_S3_URL + '/' + this.file.path })
      this.cancelModal('image')
    },

    addPdf() {
      const file = {
        ...this.file,
        path: process.env.VUE_APP_AWS_S3_URL + '/' + this.file.path,
      }
      this.editor.commands.setPdf(file)
      this.cancelModal('pdf')
    },
  },
}
</script>

<style lang="scss" scoped>
.tool-bar {
  position: sticky;
  top: 0;
  z-index: 1;

  & > div:not(:last-child) {
    &::after {
      @apply bg-blue relative;
      content: '';
      width: 1px;
      height: 60%;
      top: 20%;
      left: 0.5rem;
    }
  }

  button {
    @apply text-blue flex items-center justify-items-center p-1;
    height: 30px;
    width: 30px;

    &.is-active,
    &:hover {
      @apply bg-blue text-white;
    }

    &:disabled {
      opacity: 0.3;
    }

    path.filled {
      fill: currentColor;
    }

    path.stroked {
      stroke: currentColor;
      fill: none;
    }
  }
}

.text-editor {
  display: flex;
  flex-direction: column;

  ::v-deep .tiptap {
    @apply bg-gray-light p-4 rounded-b;
    box-shadow: 0px 0px 2px 0px #9393934d inset;
    min-height: 100px;
    height: 100%;
    overflow-y: auto;
    outline: none;
    color: #222221;

    h1 {
      @apply text-3xl;
    }

    h2 {
      @apply text-2xl;
    }

    h3 {
      @apply text-xl;
    }

    ul,
    ol {
      p {
        margin: 0 !important;
      }
    }

    button.pdf-button {
      @apply px-2 py-1 bg-blue flex text-white;
      line-height: 1.2;
      border-radius: 4px;
      font-weight: 400;
      margin-bottom: 1rem;

      &::before {
        content: '';
        display: inline-block;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath stroke='white' d='M7.58579 3.5L4.29289 6.79289C3.90237 7.18342 3.90237 7.81658 4.29289 8.20711C4.68342 8.59763 5.31658 8.59763 5.70711 8.20711L8.91421 4.91421C9.69526 4.13316 9.69526 2.86683 8.91421 2.08579C8.13316 1.30474 6.86683 1.30474 6.08579 2.08579L2.87868 5.37868C1.70711 6.55025 1.70711 8.44975 2.87868 9.62132C4.05025 10.7929 5.94975 10.7929 7.12132 9.62132L10.25 6.5' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
        max-height: 1rem;
        min-width: 1rem;
        margin-right: 0.25rem;
      }
    }
  }
  .all-features ::v-deep .tiptap {
    min-height: 300px;
  }
}
</style>
