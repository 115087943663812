import apollo from '@/apolloClient'

import {
  GC_GET_CUSTOMER_LIST,
  GC_GET_CUSTOMER_BY_ID,
  GC_ADD_CUSTOMER_ONE,
  GC_UPDATE_CUSTOMER_BY_ID,
  GC_DELETE_CUSTOMER_BY_ID,
  GC_ADD_CUSTOMER_TO_GROUP,
  GC_REMOVE_CUSTOMER_FROM_GROUP,
} from '@/graphql/customer'

export default {
  namespaced: true,
  state: {
    list: [],
    items: {},
  },
  mutations: {
    SET_CUSTOMER_LIST(state, customers) {
      state.list = customers || []
    },
    SET_CUSTOMER(state, customer) {
      if (!customer || !customer.id) return

      state.items[customer.id] = customer
    },
  },
  actions: {
    async getList({ commit }) {
      const response = await apollo.query({ query: GC_GET_CUSTOMER_LIST })

      commit('SET_CUSTOMER_LIST', response.data.customer)
    },
    async getByID({ commit }, id) {
      let response = await apollo.query({
        query: GC_GET_CUSTOMER_BY_ID,
        variables: { id },
      })

      if (!response.data.customer_by_pk) {
        return
      }

      commit('SET_CUSTOMER', response.data.customer_by_pk)
    },
    async save(context, data) {
      let response = null
      let result = {}

      let customer = {
        id: data.id,
        identifier: data.identifier,
        name: data.name,
      }

      // Update or add the customer
      if (data.id) {
        // Set id
        let variables = {
          id: data.id,
          customer,
        }

        // Get ids for deleting managers
        variables.manager_ids = data.managers.map((manager) => manager.user_id)

        // Format managers list for upsert
        variables.managers = data.managers.map((manager) => {
          // Add customer id
          manager.customer_id = data.id

          return manager
        })

        // Update remote data
        response = await apollo.mutate({
          mutation: GC_UPDATE_CUSTOMER_BY_ID,
          variables,
        })

        result.success = true
      } else {
        // Format managers for insert
        customer.managers = {
          data: data.managers,
        }

        // Add remote data
        response = await apollo.mutate({
          mutation: GC_ADD_CUSTOMER_ONE,
          variables: {
            customer,
          },
        })

        result.id = response.data.insert_customer_one.id
      }

      return result
    },
    async addToGroup(context, { id, groupID }) {
      const variables = {
        group_id: groupID,
        customer_id: id,
      }

      // Send query
      const response = await apollo.mutate({
        mutation: GC_ADD_CUSTOMER_TO_GROUP,
        variables,
      })

      return !!response
    },
    async removeFromGroup(context, { groupID }) {
      const variables = {
        group_id: groupID,
      }

      // Send query
      const response = await apollo.mutate({
        mutation: GC_REMOVE_CUSTOMER_FROM_GROUP,
        variables,
      })

      return !!response
    },
    async delete(context, id) {
      const response = await apollo.mutate({
        mutation: GC_DELETE_CUSTOMER_BY_ID,
        variables: { id },
      })

      return response.data.delete_customer_by_pk
    },
  },
}
