<template>
  <div class="c-promotion-detail px-8">
    <div class="buttons flex flex-row justify-around md:justify-between">
      <button type="button" class="twn-button text-xs" @click="goBack">Retour</button>
      <div v-if="!isReadOnly">
        <button
          type="button"
          class="twn-button text-xs danger mr-4"
          @click="showDeleteModal()"
          v-if="itemID"
          :disabled="hasPendingStoreRequest"
        >
          Supprimer la promotion
        </button>
        <button
          type="button"
          class="twn-button secondary text-xs"
          @click="save"
          :disabled="hasPendingStoreRequest"
        >
          Enregistrer
        </button>
      </div>
    </div>

    <div class="my-10">
      <!-- Group common fields -->
      <div class="mt-8 mb-4">
        <!-- Identifier -->
        <div class="form-group inline-block w-1/3 pr-2">
          <label for="game-name" class="uppercase font-principal-medium text-sm">ID *</label>
          <b-form-input
            :disabled="isReadOnly"
            class="w-full"
            v-model="promotion.identifier"
            type="text"
            autofocus
            :state="isDefined(promotion.identifier)"
          />
        </div>

        <!-- Name -->
        <div class="form-group inline-block w-2/3 pl-2">
          <label for="game-name" class="uppercase font-principal text-sm">Nom du programme *</label>
          <b-form-input
            :disabled="isReadOnly"
            class="w-full"
            v-model="promotion.name"
            type="text"
            autofocus
            :state="isDefined(promotion.name)"
          />
        </div>

        <!-- Start date -->
        <div class="form-group inline-block w-1/3 pr-2">
          <label>Date d'ouverture</label>
          <b-form-datepicker
            :disabled="isReadOnly"
            class="w-full"
            v-model="promotion.startDate"
            :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'long',
            }"
          ></b-form-datepicker>
        </div>

        <!-- End date -->
        <div class="form-group inline-block w-1/3 px-2">
          <label>Date de fermeture</label>
          <b-form-datepicker
            :disabled="isReadOnly"
            class="w-full"
            v-model="promotion.endDate"
            :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'long',
            }"
            :min="promotion.startDate"
          ></b-form-datepicker>
        </div>

        <hr class="my-8" />

        <div class="mt-8 grid grid-cols-3 gap-x-4">
          <div>
            <h3 class="title text-2xl text-blue font-light mb-8">UE associés</h3>

            <div class="mt-4">
              <div class="inline-block w-full mb-4">
                <label>Ajouter une UE</label>
                <v-select
                  :options="uniqueScenarioList"
                  :filterable="true"
                  label="name"
                  placeholder="Rechercher un UE..."
                  @input="promotion.scenarios.push($event)"
                />
              </div>

              <div
                v-for="(scenario, idx) in promotion.scenarios"
                :key="scenario.id"
                class="flex justify-between w-full"
              >
                <div>{{ scenario.name }} - {{ scenario.title }}</div>
                <b-icon-x
                  class="text-red-600 cursor-pointer"
                  @click="promotion.scenarios.splice(idx, 1)"
                ></b-icon-x>
              </div>
            </div>
          </div>
          <div>
            <h3 class="title text-2xl text-blue font-light mb-8">Colles associées</h3>

            <div class="mt-4">
              <div class="inline-block w-full mb-4">
                <label>Ajouter une colle</label>
                <v-select
                  :options="uniqueColleList"
                  :filterable="true"
                  label="name"
                  placeholder="Rechercher une colle..."
                  @input="promotion.colles.push($event)"
                />
              </div>

              <div
                v-for="(colle, idx) in promotion.colles"
                :key="colle.id"
                class="flex justify-between w-full"
              >
                <div>{{ colle.name }} - {{ colle.title }}</div>
                <b-icon-x
                  class="text-red-600 cursor-pointer"
                  @click="promotion.colles.splice(idx, 1)"
                ></b-icon-x>
              </div>
            </div>
          </div>
          <div>
            <h3 class="title text-2xl text-blue font-light mb-8">CCBs associés</h3>

            <div class="mt-4">
              <div class="inline-block w-full mb-4">
                <label>Ajouter un CCB</label>
                <v-select
                  :options="uniqueMockExamList"
                  :filterable="true"
                  label="name"
                  placeholder="Rechercher un CCB..."
                  @input="promotion.mock_exams.push($event)"
                />
              </div>

              <div
                v-for="(exam, idx) in promotion.mock_exams"
                :key="exam.id"
                class="flex justify-between w-full"
              >
                <div>{{ exam.name }} - {{ exam.title }}</div>
                <b-icon-x
                  class="text-red-600 cursor-pointer"
                  @click="promotion.mock_exams.splice(idx, 1)"
                ></b-icon-x>
              </div>
            </div>
          </div>
        </div>

        <hr class="my-8" />

        <div class="mt-8">
          <h3 class="title text-2xl text-blue font-light mb-8">
            Étudiants inscrits dans ce programme
          </h3>

          <PromotionInternsForm
            v-show="currentTab == 'users'"
            :promotion="promotion"
            @add-intern="onAddIntern"
            @remove-intern="onRemoveIntern"
            :is-read-only="isReadOnly"
          />
        </div>

        <div class="mt-8" v-if="promotionType">
          <PromotionSequenceForm
            v-show="currentTab == 'sequences'"
            :course="course"
            :promotion="promotion"
            :promotion-type="promotionType"
            @set-sequence-start="onSetSequenceStartDate"
            @set-sequence-end="onSetSequenceEndDate"
          />
          <PromotionInternSequenceForm
            v-show="currentTab == 'calendar'"
            :course="course"
            :promotion="promotion"
            :promotion-type="promotionType"
            @add-user-sequence="onAddInternSequence"
            @remove-user-sequence="onRemoveInternSequence"
          />
        </div>
      </div>

      <!-- <GroupDashboard v-if="promotion && promotion.id" :id="promotion.id" /> -->
    </div>

    <!-- Modals -->
    <b-modal
      ref="delete-modal-detail-promotion"
      class="bootstrap"
      centered
      hide-footer
      id="delete-modal-detail-promotion"
      hide-header
    >
      <div class="d-block text-center my-6 uppercase font-semibold">
        <h3>Confirmer la suppression de "{{ promotion.name }}"</h3>
      </div>
      <div class="flex flex-row justify-evenly items-center">
        <button
          type="button"
          class="mt-4 twn-button"
          @click="$bvModal.hide('delete-modal-detail-promotion')"
        >
          Retour
        </button>
        <button type="button" class="mt-4 twn-button danger" @click="onConfirmDelete">
          Supprimer
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import dispatchStoreRequest from '@/mixins/dispatchStoreRequest'
import { GC_GET_SCENARIO_NAME_BY_ID } from '@/graphql/scenario'

import PromotionSequenceForm from './PromotionSequenceForm'
import PromotionInternsForm from './PromotionInternsForm'
import PromotionInternSequenceForm from './PromotionInternSequenceForm'

import XLSX from 'xlsx'

export default {
  props: ['itemID'],
  mixins: [dispatchStoreRequest],
  components: {
    PromotionSequenceForm,
    PromotionInternsForm,
    PromotionInternSequenceForm,
  },
  data: () => ({
    // UI State
    currentTab: 'users',
    // Local promotion data
    promotion: {
      id: null,
      identifier: '',
      name: '',
      startDate: new Date(),
      endDate: null,
      course: null,
      customer_id: null,
      disabled: false,
      type: null,
      sequences: [],
      users: [],
      scenarios: [],
      colles: [],
      mock_exams: [],
    },
    course: null,
  }),
  computed: {
    ...mapState({
      courseList: (state) => state.Course.list,
      typeList: (state) => state.Promotion.typeList,
      customerList: (state) => state.Customers.list,
      userInfo: (state) => state.Auth.userInfo,
      collesList: (state) => state.Games.list.filter((game) => game.type.slug === 'colle'),
      mockExamsList: (state) => state.Games.list.filter((game) => game.type.slug === 'mock_exam'),
      scenarioList: (state) => state.Scenarios.list,
    }),
    isSuperAdmin() {
      return this.userInfo && this.userInfo.role == 'superadmin'
    },
    isReadOnly() {
      return !this.isSuperAdmin
    },
    uniqueScenarioList() {
      return this.scenarioList.filter((scenario) => {
        // Filter out other type of scenario (not "UE")
        if (scenario.type.slug !== 'basic') {
          return false
        }

        return !this.promotion.scenarios.find(
          (promotionScenario) => promotionScenario.id == scenario.id
        )
      })
    },

    uniqueColleList() {
      return this.collesList.filter((game) => {
        return !this.promotion.colles.find((promotionColle) => promotionColle.id == game.id)
      })
    },

    uniqueMockExamList() {
      return this.mockExamsList.filter((game) => {
        return !this.promotion.mock_exams.find((promotionExam) => promotionExam.id == game.id)
      })
    },

    promotionType() {
      if (!this.typeList || !this.promotion || !this.promotion.type) return null

      return this.typeList.find((type) => type.id == this.promotion.type)
    },
    hasSequencesSchedule() {
      if (!this.promotionType) return false

      const validTypes = ['collective_promotion', 'individual_promotion']

      return validTypes.indexOf(this.promotionType) > -1
    },
    hasInternSchedule() {
      if (!this.promotionType) return false

      const validTypes = ['collective_promotion', 'individual_promotion']

      return validTypes.indexOf(this.promotionType) > -1
    },
  },
  watch: {
    'itemID': {
      async handler(itemID) {
        if (itemID) {
          // todo: handle invalid uuid response
          await this.dispatchStoreRequest('Promotion/getByID', itemID, true)

          // Convert remote data to local one
          const remoteData = this.$store.state.Promotion.items[itemID]

          this.promotion.id = itemID

          // todo: handle error
          if (!remoteData) return

          this.promotion.identifier = remoteData.identifier
          this.promotion.name = remoteData.name
          this.promotion.startDate = remoteData.start_date
          this.promotion.endDate = remoteData.end_date
          this.promotion.course = remoteData.course_id
          this.promotion.module_id = remoteData.course.modules[0].id
          this.promotion.customer_id = remoteData.customer_id
          this.promotion.disabled = remoteData.disabled
          this.promotion.scenarios = remoteData.course.modules[0].scenarios.map(
            ({ scenario }) => scenario
          )

          this.promotion.colles = remoteData.activities.filter(
            (activity) => activity.type.slug === 'colle'
          )

          this.promotion.mock_exams = remoteData.activities.filter(
            (activity) => activity.type.slug === 'mock_exam'
          )

          this.promotion.type = remoteData.promotion_type_id
          this.promotion.sequences = remoteData.sequences.map((sequence) => {
            return {
              id: sequence.sequence_id,
              startDate: new Date(sequence.start_date),
              endDate: new Date(sequence.end_date),
              userID: sequence.user_id,
            }
          })
          this.promotion.users = remoteData.users.map((user) => {
            return {
              id: user.user_id,
            }
          })
        } else {
          // Clear local data
          this.promotion.id = null
          this.promotion.identifier = ''
          this.promotion.name = ''
          this.promotion.startDate = new Date()
          this.promotion.endDate = null
          this.promotion.course = null
          this.promotion.customer_id = null
          this.promotion.disabled = false
          this.promotion.sequences = []
          this.promotion.users = []
          this.promotion.scenarios = []

          // Set default values if possible
          if (
            !this.promotion.type &&
            this.typeList &&
            this.typeList.length > 0 &&
            this.typeList[0].id
          ) {
            this.promotion.type = this.typeList[0].id
          } else {
            this.promotion.type = null
          }
        }
      },
      immediate: true,
    },
    'promotion.startDate': {
      handler(startDate) {
        // Automatically set end date to start date + 1 year
        if (startDate) {
          let nextYear = new Date(startDate)
          nextYear.setFullYear(nextYear.getFullYear() + 1)

          this.promotion.endDate = nextYear
        }
      },
      immediate: true,
    },
    'typeList'(list) {
      // Set default values
      if (!this.promotion.type && list && list.length > 0 && list[0].id) {
        this.promotion.type = list[0].id
      }
    },
    'promotion.type': {
      handler(type) {
        // Set default values
        if (!type && this.typeList && this.typeList.length > 0 && this.typeList[0].id) {
          this.promotion.type = this.typeList[0].id
        }
      },
    },
  },
  async mounted() {
    // Load users, course and promotion type list
    await Promise.all([
      this.dispatchStoreRequest('Course/getList'),
      this.dispatchStoreRequest('Promotion/getTypeList'),
      this.dispatchStoreRequest('Customers/getList'),
      this.dispatchStoreRequest('Scenarios/getList'),
      this.dispatchStoreRequest('Course/GetCourseTypes'),
      this.dispatchStoreRequest('Games/getTypeList'),
      this.dispatchStoreRequest('Games/getList'),
    ])
  },
  methods: {
    getCustomerLabel(customer) {
      if (!customer) return 'Étudiant supprimé'

      return [customer.identifier, customer.name].filter((str) => str || false).join(' ')
    },
    goBack() {
      this.$router.push({ name: 'groups-list' })
    },
    showDeleteModal() {
      this.$refs['delete-modal-detail-promotion'].show()
    },

    onConfirmDelete() {
      this.$store.dispatch('Promotion/delete', this.itemID)
      this.$bvModal.hide('delete-modal-detail-promotion')
      this.goBack()
    },
    onSetSequenceStartDate({ sequenceID, startDate }) {
      // Try to find and update the sequence data
      for (var i = 0; i < this.promotion.sequences.length; i++) {
        if (
          this.promotion.sequences[i].userID == null &&
          this.promotion.sequences[i].id == sequenceID
        ) {
          this.promotion.sequences[i].startDate = startDate
          return
        }
      }

      // If no data exist, create a default one
      this.promotion.sequences.push({
        id: sequenceID,
        startDate,
        endDate: null,
        userID: null,
      })
    },
    onSetSequenceEndDate({ sequenceID, endDate }) {
      // Try to find and update the sequence data
      for (var i = 0; i < this.promotion.sequences.length; i++) {
        if (
          this.promotion.sequences[i].userID == null &&
          this.promotion.sequences[i].id == sequenceID
        ) {
          this.promotion.sequences[i].endDate = endDate
          return
        }
      }

      // If no data exist, create a default one
      this.promotion.sequences.push({
        id: sequenceID,
        startDate: null,
        endDate,
        userID: null,
      })
    },
    onAddIntern(intern) {
      this.promotion.users.unshift({ id: intern.id })
    },
    onRemoveIntern(intern) {
      for (var i = 0; i < this.promotion.users.length; i++) {
        if (this.promotion.users[i].id == intern.id) {
          this.promotion.users.splice(i, 1)
          break
        }
      }
    },
    onAddInternSequence(internSequence) {
      this.promotion.sequences.push({
        id: internSequence.id,
        startDate: internSequence.startDate,
        endDate: internSequence.endDate,
        userID: internSequence.userID,
      })
    },
    onRemoveInternSequence(internSequence) {
      for (var i = 0; i < this.promotion.sequences.length; i++) {
        if (
          this.promotion.sequences[i].id == internSequence.id &&
          this.promotion.sequences[i].userID == internSequence.userID
        ) {
          this.promotion.sequences.splice(i, 1)
          break
        }
      }
    },
    async save() {
      const response = await this.dispatchStoreRequest('Promotion/save', this.promotion)

      if (response.id) {
        this.$router.push({
          name: 'groups-edit',
          params: {
            itemID: response.id,
          },
        })
      }

      this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })
    },
    isDefined(v) {
      // eslint-disable-next-line
      return !!v ? null : false
    },
  },
}
</script>

<style lang="scss" scoped>
.tabs-triggers {
  button {
    @apply flex-1 p-2 font-principal-medium;

    &.selected {
      @apply text-white bg-red;
    }
  }
}
</style>
