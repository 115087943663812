<template>
  <div class="games">
    <!-- Game list -->
    <List
      ref="list"
      listing="activity"
      :fields="fields"
      :where-filter="whereFilter"
      searchText="Rechercher"
      editRouteName="activity-edit"
      :deleteItemOverride="deleteGameByID"
      :customRouteParams="() => ({ gameTypeSlug: $route.params.type })"
    >
      <template v-slot:buttons>
        <div class="md:flex flex-row justify-end hidden md:w-1/3">
          <button
            type="button"
            class="twn-button secondary whitespace-nowrap overflow-hidden text-xs mr-2"
            @click="onCreateActivityClick"
          >
            Créer {{ typeText }}
          </button>
        </div>
      </template>

      <template v-slot:cell(is_public)="{ item }">
        <Toggle :checked="item.is_public" :onInput="onPublicClick.bind(null, item)" />
      </template>
    </List>
  </div>
</template>

<script>
import List from '@/components/List'
import { mapState, mapActions } from 'vuex'

import dispatchStoreRequest from '@/mixins/dispatchStoreRequest'
import Toggle from '../components/Toggle.vue'
import { GC_MAKE_GAME_PUBLIC } from '../graphql/game'
import apollo from '@/apolloClient'

export default {
  name: 'MiniGamesList',
  mixins: [dispatchStoreRequest],
  components: { List, Toggle },
  async created() {
    this.init()
  },
  data() {
    return {
      fields: [
        {
          key: 'identifier',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'title',
          label: 'Nom',
          sortable: true,
        },
        {
          key: 'nb_questions',
          label: 'Nombre de questions',
          sortable: true,
          nested: 'count',
          db: `
            nb_questions: questions_aggregate {
              aggregate {
                count
              }
            }`,
        },
        {
          key: 'is_public',
          label: 'Gratuit',
          sortable: false,
        },
      ],
    }
  },

  /**
   * Since some routes share this same view, when a param change we need to refresh it
   */
  watch: {
    '$route.params.type': function () {
      this.$refs.list.getList()
      this.init()
    },
  },

  computed: {
    ...mapState({ gameTypeList: (state) => state.Games.type_list }),

    gameType() {
      return this.gameTypeList.find((gameType) => gameType.slug === this.$route.params.type)
    },

    whereFilter() {
      return `type: { slug: {_eq: ${this.$route.params.type} } }`
    },

    typeText() {
      return {
        quiz_simple: 'un chapitre',
        mock_exam: 'un concours blanc',
        colle: 'une colle',
      }[this.gameType.slug]
    },
  },
  methods: {
    ...mapActions('Games', ['getTypeList', 'deleteGameByID']),
    ...mapActions('List', ['clearListItems']),

    async init() {
      await this.getTypeList()
    },

    onCreateActivityClick() {
      this.$router.push({
        name: 'activity-edit',
        params: { gameTypeSlug: this.$route.params.type },
      })
    },

    /**
     * When the toggle is clicked, we change the public status of the game
     */
    onPublicClick(item) {
      item.is_public = !item.is_public
      apollo.mutate({
        mutation: GC_MAKE_GAME_PUBLIC,
        variables: { id: item.id, public: item.is_public },
      })
    },
  },
}
</script>

<style lang="scss">
.choice {
  // box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.1);
  @apply flex-wrap justify-center w-full flex flex-row mb-5;
  @screen lg {
    @apply justify-start;
  }
  .game-card {
    @apply flex flex-col w-48 h-48 cursor-pointer m-4;
    @screen lg {
      @apply m-0 mr-4 mb-4;
    }

    .picto-image {
      height: 90%;
      @apply text-principal-selected fill-current;

      svg {
        width: 100%;
        height: 100%;
      }

      img {
        @apply h-full m-auto p-4;
      }
    }

    &:hover {
      box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.15);
    }
  }
}
</style>
