<template>
  <div class="mt-4">
    <div class="inline-block w-1/3 mb-4">
      <label v-if="!isReadOnly">Ajouter un étudiant</label>
      <v-select
        v-if="!isReadOnly"
        :options="internsList"
        :filterable="true"
        label="name"
        placeholder="Rechercher un étudiant..."
        v-model="selectedUser"
        @input="onUserSelect($event)"
      />
    </div>

    <div class="inline-block w-2/3 text-right px-4">
      <span>{{ this.promotion.users.length }}</span>
      <span> étudiants</span>
    </div>

    <div class="">
      <table class="twn-table w-full">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Email</th>
            <th v-if="!isReadOnly"></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="intern in promotion.users" :key="intern.id">
            <template v-if="interns[intern.id]">
              <td>{{ interns[intern.id].first_name }}</td>
              <td>{{ interns[intern.id].name }}</td>
              <td>{{ interns[intern.id].email }}</td>
              <td
                v-if="!isReadOnly"
                class="flex justify-center text-xl font-bold items-center cursor-pointer col-span-1"
                @click="onRemoveInternClick(intern)"
              >
                <div class="border border-gray-light rounded-full text-center w-8 h-8">-</div>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import userFieldsValues from '@/constants/userFieldsValues'

export default {
  props: {
    promotion: {
      type: Object,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    isPlanning: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedUser: null,
    }
  },
  computed: {
    ...mapState('Utils', ['userList', 'metaTypeList']),
    internsList() {
      if (!this.userList) return []

      return this.userList
        .filter((user) => {
          if (this.isPlanning) {
            return !user.planning_id && !this.promotion.users.some((intern) => user.id == intern.id)
          }
          return (
            (!user.groups || user.groups.length <= 0) &&
            !this.promotion.users.some((intern) => user.id == intern.id)
          )
        })
        .map((user) => {
          return {
            id: user.id,
            name:
              [user.first_name, user.name].filter((str) => str || false).join(' ') +
              ' - ' +
              user.email,
          }
        })
    },
    interns() {
      if (!this.userList || !this.metaTypeList) return {}

      const jobsLabel = userFieldsValues.job.reduce((dict, job) => {
        dict[job.value] = job.label

        return dict
      }, {})

      const jobMetaTypes = this.metaTypeList.find((type) => type.slug == 'job') || {}

      return this.userList.reduce((dict, user) => {
        const jobMeta = user.metas.find((meta) => meta.meta_type_id == jobMetaTypes.id)
        user.job = jobMeta ? jobsLabel[jobMeta.value] : jobsLabel.other

        dict[user.id] = user

        return dict
      }, {})
    },
  },
  methods: {
    onUserSelect(intern) {
      this.$emit('add-intern', intern)

      this.$nextTick(() => (this.selectedUser = null))
    },
    onRemoveInternClick(intern) {
      this.$emit('remove-intern', intern)
    },
  },
}
</script>

<style lang="scss">
.group-interns {
  @apply border-separate;

  tr {
    &:hover td {
      @apply border-gray-lighter;
    }
  }

  td {
    @apply py-1 border-t-1 border-b-1 border-white;
  }
}
</style>
