const storeRequestCache = {
  // Current pending promise by cache key
  pendingRequests: {},
  requests: {},
  getPath(actionName, args) {
    let mod = 'common'
    let action = actionName

    const parts = actionName.split('/')

    if (parts.length > 1) {
      mod = parts[0]
      action = parts[1]
    }

    return {
      module: mod,
      action,
      key: actionName + (args ? '|' + JSON.stringify(args) : ''),
    }
  },
  hasExpired(actionName, args) {
    const path = this.getPath(actionName, args)

    if (!this.requests[path.module]) return true

    return this.requests[path.module][path.key] ? false : true
  },
  getRequestResponse(actionName, args) {
    const path = this.getPath(actionName, args)

    if (!this.requests[path.module] || !this.requests[path.module][path.key]) return null

    return this.requests[path.module][path.key].response
  },
  getPendingRequest(actionName, args) {
    const path = this.getPath(actionName, args)

    if (!this.pendingRequests[path.module]) return null

    return this.pendingRequests[path.module][path.key]
  },
  registerPendingRequest(actionName, args, promise) {
    const path = this.getPath(actionName, args)

    if (!this.pendingRequests[path.module]) this.pendingRequests[path.module] = {}

    this.pendingRequests[path.module][path.key] = promise
  },
  removePendingRequest(actionName, args) {
    const path = this.getPath(actionName, args)

    if (!this.pendingRequests[path.module]) return

    delete this.pendingRequests[path.module][path.key]
  },
  registerRequest(actionName, args, response) {
    const path = this.getPath(actionName, args)

    // Store expiration data only for get request
    if (path.action.substring(0, 3).toLowerCase() == 'get') {
      if (!this.requests[path.module]) this.requests[path.module] = {}

      this.requests[path.module][path.key] = {
        response,
        date: new Date(),
      }
    } else {
      // Expire this module if it's not a get request (so probably a mutation)
      this.requests[path.module] = {}
    }
  },
}

export default {
  data() {
    return {
      pendingStoreRequest: 0,
    }
  },
  computed: {
    hasPendingStoreRequest() {
      return this.pendingStoreRequest > 0
    },
  },
  methods: {
    async dispatchStoreRequest(actionName, args = null, force = false) {
      // Handle duplicate request
      const pendingRequest = storeRequestCache.getPendingRequest(actionName, args)

      if (pendingRequest) {
        return await pendingRequest
      }

      // Check if cache for this request exist and has expired
      const expired = force || storeRequestCache.hasExpired(actionName, args)

      let response = expired ? null : storeRequestCache.getRequestResponse(actionName, args)

      if (expired) {
        this.pendingStoreRequest += 1

        try {
          let promise = null

          if (args) {
            promise = this.$store.dispatch(actionName, args)
          } else {
            promise = this.$store.dispatch(actionName)
          }

          // Handle request response and pending state
          storeRequestCache.registerPendingRequest(actionName, args, promise)

          response = await promise

          storeRequestCache.removePendingRequest(actionName, args)

          // Add request response to cache
          storeRequestCache.registerRequest(actionName, args, response)
        } finally {
          this.pendingStoreRequest -= 1
        }
      }

      return response
    },
  },
}
