<template>
  <div class="w-full question flex flex-row relative justify-center items-center">
    <!-- Header/Handle -->
    <div class="handle self-stretch w-10 flex justify-center items-center cursor-move">
      <div class="rotate-icon">
        <b-icon-justify></b-icon-justify>
      </div>
    </div>

    <!-- Content -->
    <div class="flex items-center flex-1">
      <!-- Index -->
      <div
        v-if="identifier"
        class="handle cursor-pointer index bg-principal-selected w-10 self-stretch flex justify-center items-center text-xs"
      >
        <div>{{ identifier }}</div>
      </div>

      <!-- Form -->
      <div class="content p-10 w-full">
        <!-- Title and Media -->
        <div class="input-field w-full flex justify-around items-center">
          <!-- Title -->
          <b-form-textarea
            class="w-1/2"
            placeholder="Titre"
            rows="1"
            max-rows="6"
            type="text"
            v-model="title"
          />

          <!-- Media -->
          <v-select
            class="twn-select w-1/3 ml-auto mr-4"
            placeholder="Media (image ou vidéo)"
            :getOptionLabel="getMediaLabel"
            :options="mediaList"
            :reduce="(medium) => medium.id"
            v-model="media"
          />
        </div>

        <!-- Text -->
        <div class="input-field w-full mt-4">
          <b-form-textarea
            placeholder="Texte"
            rows="1"
            max-rows="6"
            type="text"
            v-model="text"
          ></b-form-textarea>
        </div>

        <!-- Label -->
        <div class="w-full input-group mt-10">
          <b-form-textarea
            placeholder="Label"
            rows="1"
            max-rows="6"
            type="text"
            v-model="label"
          ></b-form-textarea>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div
      @click="deleteQuestion"
      class="cursor-pointer deleteQuestion self-stretch w-10 flex justify-center items-center text-white"
    >
      <b-icon-x></b-icon-x>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Hotspot',
  props: {
    question: {
      type: Object,
      required: true,
    },
    identifier: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapState('Assets', {
      mediaList: (state) => state.assets,
    }),
    title: {
      get() {
        return this.question.text
      },
      set(value) {
        this.$emit('update-question', {
          text: value,
        })
      },
    },
    media: {
      get() {
        return this.question.media_id
      },
      set(value) {
        this.$emit('update-question', {
          media_id: value,
        })
      },
    },
    text: {
      get() {
        return this.question.correction
      },
      set(value) {
        this.$emit('update-question', {
          correction: value,
        })
      },
    },
    label: {
      get() {
        return this.question.help
      },
      set(value) {
        this.$emit('update-question', {
          help: value,
        })
      },
    },
  },
  methods: {
    getMediaLabel(medium) {
      return [medium.title, medium.name].filter((str) => str || false).join(' - ')
    },
    deleteQuestion() {
      this.$emit('delete-question')
    },
  },
}
</script>

<style lang="scss">
.question {
  box-shadow: $cardShadow;
  border-radius: 10px;

  &.removeState {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  color: $textLight;
  .index {
    background-color: rgba(220, 151, 153, 0.1);
  }

  .rotate-icon {
    transform: rotate(90deg);
  }

  .image-content {
    .image {
      img {
        max-height: 150px;
      }
    }
  }

  .deleteQuestion {
    @apply bg-principal-selected;
  }
}
</style>
