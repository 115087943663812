import apollo from '@/apolloClient'

import {
  GC_GET_PLANNING_LIST,
  GC_GET_PLANNING_BY_ID,
  GC_ADD_PLANNING_ONE,
  GC_UPDATE_PLANNING_BY_ID,
  GC_DELETE_PLANNING_BY_ID,
} from '@/graphql/planning'

export default {
  namespaced: true,
  state: {
    list: [],
    items: {},
  },
  mutations: {
    SET_PLANNING_LIST(state, plannings) {
      state.list = plannings || []
    },
    SET_PLANNING(state, planning) {
      if (!planning || !planning.id) return

      state.items[planning.id] = planning
    },
  },
  actions: {
    async getList({ commit }) {
      const response = await apollo.query({ query: GC_GET_PLANNING_LIST })

      commit('SET_PLANNING_LIST', response.data.planning)
    },
    async getByID({ commit }, id) {
      let response = await apollo.query({
        query: GC_GET_PLANNING_BY_ID,
        variables: { id },
      })

      if (!response.data.planning_by_pk) {
        return
      }

      commit('SET_PLANNING', response.data.planning_by_pk)
    },
    async save(context, data) {
      let response = null
      let result = {}

      let planning = {
        name: data.name,
        content: data.content,
      }
      if (data.id) {
        // Set id
        planning.id = data.id
        planning.users = data.users.map((user) => user.id)
        response = await apollo.mutate({
          mutation: GC_UPDATE_PLANNING_BY_ID,
          variables: planning,
        })

        result.success = true
      } else {
        response = await apollo.mutate({
          mutation: GC_ADD_PLANNING_ONE,
          variables: planning,
        })

        result.id = response.data.insert_planning_one.id
      }

      await context.dispatch('Utils/getUsers', null, { root: true })

      return result
    },
    async delete(context, data) {
      const response = await apollo.mutate({
        mutation: GC_DELETE_PLANNING_BY_ID,
        variables: { id: data.id, users: data.users.map((user) => user.id) },
      })

      return response.data.delete_planning_by_pk
    },
  },
}
