<template>
  <div
    class="w-full question flex flex-row relative justify-center items-center"
    :class="noStyle ? 'no-style' : ''"
  >
    <div
      v-if="canAddQuestion"
      class="handle self-stretch w-10 flex justify-center items-center cursor-move"
    >
      <div class="rotate-icon">
        <b-icon-justify></b-icon-justify>
      </div>
    </div>
    <div class="flex items-center flex-1">
      <div
        v-if="canAddQuestion"
        class="handle cursor-pointer number bg-principal-selected w-10 self-stretch flex justify-center items-center text-xs"
      >
        <div>{{ number + 1 }}</div>
      </div>
      <div class="content p-10 w-full" v-if="!imageType">
        <div v-if="hasLabel" class="input-field w-full">
          <span>Enoncé de la question</span>
          <TextEditor v-model="questionLabel" class="mt-2" />
        </div>

        <div class="my-4">
          <label>Image</label>
          <Dropzone
            :current-file="questionMedia"
            @file-uploaded="questionMedia = $event"
            :accepted-files="['image/*']"
            type="file_image"
          />
        </div>

        <div
          class="underline text-center flex justify-center items-center text-blue mt-4 cursor-pointer"
          @click="hiddenAnswers = !hiddenAnswers"
        >
          {{ !hiddenAnswers ? 'Voir les réponses' : 'Masquer les réponses' }}
          <b-icon-chevron-down
            class="mx-2 transform"
            :class="hiddenAnswers ? 'rotate-180' : 'rotate-0'"
          />
        </div>

        <div
          class="answers w-full flex flex-row justify-center items-center mt-5"
          v-show="hiddenAnswers"
        >
          <!-- Answer text -->
          <div class="box-answers w-full" v-bind:class="[answers.length == 0 ? 'w-0' : '']">
            <draggable v-model="answers" handle=".answer-handle">
              <div
                v-for="(answer, i) in answers"
                :key="i"
                class="w-full flex flex-row justify-center items-center my-2 shadow-md rounded-md overflow-hidden"
              >
                <!-- Number -->
                <template v-if="!options || !options.fixedAnswers">
                  <div class="answer-handle cursor-pointer w-10 flex items-center justify-center">
                    <div class="rotate-icon">
                      <b-icon-justify></b-icon-justify>
                    </div>
                  </div>
                  <div
                    class="flex justify-center items-center font-bold number answer-handle w-10 text-center self-stretch p-2 cursor-pointer"
                  >
                    <div>{{ String.fromCharCode(65 + i) }}</div>
                  </div>
                </template>

                <!-- Input/Text -->
                <div
                  class="input-field w-full p-2"
                  :class="{ 'no-handle': options && options.fixedAnswers }"
                >
                  <span class="mb-2">Réponse</span>
                  <span v-if="options && options.fixedAnswers && !options.editableAnswers">{{
                    answer.text
                  }}</span>
                  <b-form-textarea
                    v-else
                    max-rows="6"
                    class="h-full border-none"
                    :class="{ 'w-full': !hasPicture, 'w-1/2': hasPicture }"
                    @input="updateAnswer($event, 'text', i)"
                    type="text"
                    :placeholder="
                      options && options.answerPlaceholder ? options.answerPlaceholder : 'Réponse'
                    "
                    :value="answer.text"
                  />

                  <span class="mb-2">Message de correction</span>
                  <TextEditor
                    :value="answer.correction"
                    @input="updateAnswer($event, 'correction', i)"
                    class="mt-2"
                  />
                </div>
                <div
                  class="bg-white cursor-pointer self-stretch flex items-center text-white justify-center w-10"
                  @click="removeAnswer(i)"
                  v-if="!options || (options && !options.fixedAnswers)"
                >
                  <img class="w-3" src="@/assets/images/THRASH.svg" />
                </div>
              </div>
            </draggable>
          </div>

          <!-- Right/Wrong answer switch -->
          <div
            v-if="!options || !options.noRightAnswers"
            class="check-answers self-stretch flex flex-col justify-around items-center"
          >
            <div
              v-for="(answer, i) in answers"
              :key="i"
              class="w-full mx-3 flex flex-row justify-center items-center"
            >
              <label class="switch">
                <input
                  v-if="hasMultipleAnswers"
                  type="checkbox"
                  :checked="answer.is_correct"
                  @input="checkAnswer(i)"
                />
                <input
                  v-else
                  type="radio"
                  :name="'answer_' + number"
                  :checked="answer.is_correct"
                  @input="checkAnswer(i)"
                />
                <span v-if="hasMultipleAnswers" class="slider round"></span>
              </label>
            </div>
          </div>

          <!-- New answer button -->
          <div
            v-if="!options || (options && !options.fixedAnswers)"
            v-bind:class="[answers.length == 0 ? 'w-full' : '']"
          >
            <div
              @click="addDefaultAnswer(number)"
              class="plus m-auto rounded-full w-10 h-10 flex justify-center items-center cursor-pointer"
            >
              +
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="w-full m-5 image-content"
        @click="openMediaChoice"
        @dragover="allowDrop"
        @drop.prevent.stop="dropImageInQuestion($event)"
      >
        <div class="drop-zone p-10 w-full h-full border-dashed border-2" v-if="!image">
          <div class="m-auto w-1/3 break-words text-center text-xs">
            Ajouter une image depuis la bibliothèque de média
          </div>
        </div>
        <div v-else class="image m-auto w-1/5 h-2/3">
          <img :src="image.thumbnail_url" alt="" class="" />
        </div>
      </div>
      <div></div>
    </div>
    <div
      v-if="canAddQuestion"
      @click="removeQuestion"
      class="cursor-pointer self-stretch w-10 flex justify-center items-center text-white"
    >
      <img class="w-3" src="@/assets/images/THRASH.svg" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dispatchStoreRequest from '@/mixins/dispatchStoreRequest'
import Draggable from 'vuedraggable'
import TextEditor from '@/components/base/TextEditor.vue'
import Dropzone from '@/components/Dropzone.vue'

export default {
  name: 'Question',
  components: {
    Draggable,
    TextEditor,
    Dropzone,
  },
  mixins: [dispatchStoreRequest],
  props: {
    number: {
      type: Number,
      required: true,
      default: null,
    },
    options: {
      type: Object,
      required: false,
    },
    question: {
      type: Object,
      required: true,
      default: null,
    },
    imageType: {
      type: Boolean,
      required: false,
      default: false,
    },
    mediaLoaded: {
      type: Boolean,
      required: false,
    },
    noStyle: {
      Type: Boolean,
      required: false,
    },
    canAddQuestion: {
      Type: Boolean,
      required: false,
      default: true,
    },
    hasPicture: {
      Type: Boolean,
      required: false,
      default: false,
    },
    hasLabel: {
      Type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      image: null,
      multipleAnswers: true,
      hiddenAnswers: false,
    }
  },
  computed: {
    ...mapState('Assets', {
      mediaList: (state) => state.assets,
    }),
    questionLabel: {
      get() {
        return this.question.text
      },
      set(value) {
        this.$emit('update-question', this.number, {
          text: value,
        })
      },
    },
    questionMedia: {
      get() {
        return this.question.media ? this.question.media.path : null
      },
      set(value) {
        this.$emit('update-question', this.number, {
          media: value,
        })
      },
    },
    hasMultipleAnswers: {
      get() {
        if (this.options && this.options.hasMultipleAnswers !== undefined) {
          return this.options.hasMultipleAnswers
        }

        return this.multipleAnswers
      },
      set(value) {
        this.multipleAnswers = value

        // Check for answers consitency
        if (!this.multipleAnswers) {
          let found = false
          let newAnswers = [...this.answers]

          // Keep only the first correct answer
          for (var i = 0; i < this.answers.length; i++) {
            if (found) {
              this.answers[i].is_correct = false
            } else if (this.answers[i].is_correct) {
              found = true
            }
          }

          this.answers = newAnswers
        }

        this.$emit('update-multiple-answers', this.number, this.multipleAnswers)
      },
    },
    correctionText: {
      get() {
        return this.question.correction
      },
      set(value) {
        this.$emit('update-question', this.number, {
          correction: value,
        })
      },
    },
    rightCorrectionText: {
      get() {
        const index = this.answers.findIndex((answer) => {
          return answer.is_correct
        })

        return index > -1 ? this.answers[index].correction : ''
      },
      set(value) {
        const index = this.answers.findIndex((answer) => {
          return answer.is_correct
        })

        if (index > -1) {
          this.setAnswerCorrection(index, value)
        }
      },
    },
    wrongCorrectionText: {
      get() {
        const index = this.answers.findIndex((answer) => {
          return !answer.is_correct
        })

        return index > -1 ? this.answers[index].correction : ''
      },
      set(value) {
        const index = this.answers.findIndex((answer) => {
          return !answer.is_correct
        })

        if (index > -1) {
          this.setAnswerCorrection(index, value)
        }
      },
    },
    answers: {
      get() {
        return this.question.answers
      },
      set(value) {
        this.$emit('update-question', this.number, {
          answers: value,
        })
      },
    },
  },
  methods: {
    updateAnswer(input, key, i) {
      let newAnswers = [...this.answers]
      newAnswers[i][key] = input
      this.answers = newAnswers
    },
    updateAnswerMedium(newValue, i) {
      let newAnswers = [...this.answers]
      newAnswers[i].media_id = newValue
      this.answers = newAnswers
    },
    removeQuestion() {
      this.$emit('delete-question', this.number)
    },
    addDefaultAnswer() {
      let newAnswers = [...this.answers]
      newAnswers.push({
        text: '',
        is_correct: false,
        correction: '',
      })
      this.$emit('update-question', this.number, {
        answers: newAnswers,
      })
    },
    checkAnswer(index) {
      let correct = true
      let newAnswers = [...this.answers]

      if (this.hasMultipleAnswers) {
        if (newAnswers[index].is_correct) {
          correct = false
        }
        newAnswers[index].is_correct = correct
      } else {
        for (var i = 0; i < newAnswers.length; i++) {
          newAnswers[i].is_correct = false

          if (this.options && this.options.multipleCorrection) {
            newAnswers[i].correction = this.wrongCorrectionText
          }
        }

        newAnswers[index].is_correct = true
        if (this.options && this.options.multipleCorrection) {
          newAnswers[index].correction = this.rightCorrectionText
        }
      }

      this.answers = newAnswers
    },
    removeAnswer(i) {
      let newAnswers = [...this.answers]
      newAnswers.splice(i, 1)
      this.answers = newAnswers
    },
    setAnswerCorrection(index, correction) {
      let newAnswers = [...this.answers]

      newAnswers[index].correction = correction

      this.answers = newAnswers
    },
    openMediaChoice() {
      this.$emit('open-panel')
    },
    async dropImageInQuestion(e) {
      if (e.dataTransfer) {
        const assetJSON = e.dataTransfer.getData('application/json')
        const asset = assetJSON ? JSON.parse(assetJSON) : null

        this.image = await this.dispatchStoreRequest('Assets/getAssetFromID', asset.id, true)

        this.$emit('update-question', this.number, {
          media_id: asset.id,
        })
      }
    },
    allowDrop: function (event) {
      event.preventDefault()
    },
  },
  watch: {
    async question(newVal, oldVal) {
      if (newVal.media_id && (!oldVal.media_id || oldVal.media_id !== newVal.media_id)) {
        this.image = await this.dispatchStoreRequest('Assets/getAssetFromID', newVal.media_id, true)
      }
      if (!newVal.media_id) {
        this.image = null
      }
    },
    async mediaLoaded() {
      if (this.question.media_id) {
        this.image = await this.dispatchStoreRequest(
          'Assets/getAssetFromID',
          this.question.media_id,
          true
        )
      }
    },
  },
}
</script>
<style lang="scss">
.plus {
  background-color: rgba(225, 225, 225, 0.15);
}
.question:not(.no-style) {
  box-shadow: $cardShadow;
  border-radius: 10px;

  &.removeState {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  color: $textLight;
  .number {
    background-color: #174489;
    color: white;
  }

  .rotate-icon {
    transform: rotate(90deg);
  }

  .image-content {
    .image {
      img {
        max-height: 150px;
      }
    }
  }

  .answers {
    .box-answers {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    }
    .remove-state {
      > :first-child {
        @apply bg-principal-selected text-white;
      }
      > :last-child {
        @apply border-principal-selected;
      }
    }
    .input-field {
      border-left: none;

      textarea {
        overflow-y: hidden !important;
        height: 1.4rem;
      }

      &.no-handle {
        border: solid 0.3px #e1e1e1;
      }
    }

    /* The switch - the box around the slider */
    .switch {
      @apply text-center cursor-pointer;
      position: relative;
      display: inline-block;
      width: 50px;
      height: 24px;
      margin-bottom: 0;
    }

    .switch input[type='radio'] {
      @apply appearance-none border-2 rounded-full w-4 h-4 cursor-pointer;
      border-color: #ccc;

      &:checked {
        @apply bg-red border-red;
      }
    }

    /* Hide default HTML checkbox */
    .switch input[type='checkbox'] {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: '';
      height: 16px;
      width: 16px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      @apply bg-principal-selected;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 24px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
}

/**
 * Vue2 Editor
*/
.ql-snow .ql-toolbar button svg,
.quillWrapper .ql-snow.ql-toolbar button svg {
  width: 14px !important;
  height: 14px !important;
}

.quillWrapper .ql-snow.ql-toolbar {
  padding-top: 2px !important;
  padding-bottom: 0px !important;
}

.ql-editor {
  min-height: 70px !important;
  font-size: 12px !important;
  padding: 10px 8px;
}

.ql-container {
  height: 60% !important;
}

.quillWrapper .ql-snow.ql-toolbar .ql-formats {
  margin-bottom: 3px;
}
</style>
