<template>
  <div class="settings" v-show="show">
    <div class="settings-control">
      <label>Identifiant</label>
      <input class="twn-input" type="text" name="name" v-model="settings.name" />
    </div>
    <div class="settings-control">
      <label>Titre</label>
      <input class="twn-input" name="title" type="text" ref="title" v-model="settings.title" />
    </div>
    <div class="d-none settings-control">
      <label>Description</label>
      <textarea
        class="twn-input"
        rows="6"
        name="description"
        v-model="settings.description"
      ></textarea>
    </div>
    <div v-if="scenarioTypeList && scenarioTypeList.length > 1" class="settings-control">
      <label>Type</label>
      <v-select
        class="twn-select"
        name="type"
        label="name"
        :clearable="false"
        :options="scenarioTypeList"
        v-model="settings.type"
      ></v-select>
    </div>

    <!-- Final test specific settings -->
    <template v-if="settings.type && settings.type.slug == 'final_test'">
      <!-- Correction media/pdf linked to this test -->
      <div class="settings-control">
        <label>Document de correction</label>
        <v-select
          class="twn-select"
          name="type"
          label="label"
          :clearable="true"
          :options="pdfList"
          :placeholder="pdfList ? 'Aucun document associé' : 'Chargement...'"
          v-model="settings.correction"
        ></v-select>
      </div>
    </template>

    <!-- Revision specific settings -->
    <template v-if="settings.type && settings.type.slug == 'revision'">
      <!-- Scenario linked to this revision -->
      <div class="settings-control">
        <label>Scénario à réviser</label>
        <v-select
          class="twn-select"
          name="type"
          label="label"
          :clearable="true"
          :options="scenarioList"
          :placeholder="scenarioList ? 'Aucun scénario associé' : 'Chargement...'"
          v-model="settings.linkedScenario"
        ></v-select>
      </div>

      <!-- Number of sequence before triggering this revision -->
      <div class="settings-control">
        <label>Délai de déclenchement de la révision</label>
        <v-select
          class="twn-select"
          name="type"
          label="label"
          :clearable="false"
          :options="delayList"
          :reduce="(delay) => delay.value"
          v-model="settings.revisionDelay"
        ></v-select>
      </div>
    </template>

    <div class="pt-4 text-xs text-right">
      <button type="button" class="twn-button" :disabled="hasPendingRequest" @click="onSaveClick">
        Enregistrer
      </button>
    </div>

    <div class="mt-10" v-if="scenario && scenario.contentLogs.length > 0">
      <div class="cursor-pointer select-none" @click="showContentHistory = !showContentHistory">
        <svg
          class="inline-block mr-2"
          :class="{ 'transform rotate-180': showContentHistory }"
          height="15px"
          id="Layer_1"
          version="1.1"
          viewBox="0 0 26 15"
          width="26px"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <polygon
            fill="#231F20"
            points="23.303,-0.002 12.467,10.834 1.63,-0.002 -0.454,2.082 12.467,15.002 14.551,12.918 25.387,2.082   "
          />
        </svg>
        <span v-if="showContentHistory">Cacher</span>
        <span v-else>Afficher</span> l'historique des modifications
      </div>

      <div v-if="showContentHistory" class="mt-4">
        <div
          class="my-4 flex flex-row text-sm justify-between w-full pr-4"
          v-for="record in scenario.contentLogs"
          :key="record.created_at"
        >
          <div>{{ formatDate(record.created_at) }}</div>
          <div v-if="record.user">{{ record.user.first_name }} {{ record.user.name }}</div>
          <div v-else>-</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import moment from 'moment'

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    scenario: {
      type: Object,
      required: true,
      default: () => {},
    },
    locationList: {
      type: Array,
      required: true,
      default: () => [],
    },
    entityList: {
      type: Array,
      required: true,
      default: () => [],
    },
    scenarioTypeList: {
      type: Array,
      required: true,
      default: () => [],
    },
    hasPendingRequest: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      showContentHistory: false,
      settings: {},
      delayList: [
        {
          value: 1,
          label: 'Séquence + 1',
        },
        {
          value: 2,
          label: 'Séquence + 2',
        },
        {
          value: 4,
          label: 'Séquence + 4',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      mediaTypeList: (state) => state.Media.type_list,
      scenarioList(state) {
        const scenarioType = this.scenarioTypeList
          ? this.scenarioTypeList.find((type) => type.slug == 'scenario')
          : null

        if (!scenarioType) return []

        return state.Scenarios.list.reduce((list, scenario) => {
          if (scenario.scenario_type_id == scenarioType.id && scenario.id != this.scenario.id) {
            list.push({
              ...scenario,
              label: scenario.name + ' - ' + scenario.title,
            })
          }

          return list
        }, [])
      },
      pdfList(state) {
        const mediaType = this.mediaTypeList
          ? this.mediaTypeList.find((type) => type.slug == 'file_pdf')
          : null

        if (!mediaType) return []

        return state.Media.list.reduce((list, media) => {
          if (media.media_type_id == mediaType.id) {
            list.push({
              ...media,
              label: media.title || media.name,
            })
          }

          return list
        }, [])
      },
    }),
    spawnPointList() {
      if (!this.entityList) return []

      return this.entityList.filter((entity) => {
        return entity.type.slug == 'spawn_point'
      })
    },
    mapPointList() {
      if (!this.entityList) return []

      return this.entityList.filter((entity) => {
        return entity.type.slug == 'map_point'
      })
    },
    sceneList() {
      if (!this.entityList) return []

      return this.entityList.filter((entity) => {
        return entity.type.slug == 'scene'
      })
    },
  },
  watch: {
    show: {
      handler(show) {
        if (show) {
          this.cloneSettings()

          // Focus scenario title
          this.$nextTick(() => {
            if (this.$refs && this.$refs.title) {
              this.$refs.title.focus()

              if (!this.scenario.id)
                this.$refs.title.setSelectionRange(0, this.scenario.title.length)
            }
          })
        }
      },
      immediate: true,
    },
    scenario: {
      handler() {
        this.cloneSettings()
      },
      immediate: true,
    },
  },
  methods: {
    formatDate(dt) {
      return moment(new Date(dt)).locale('fr').format('LLLL')
    },
    cloneSettings() {
      if (!this.scenario) return

      // Get settings from scenario data
      this.settings = {
        name: this.scenario.name,
        title: this.scenario.title,
        description: this.scenario.description,
        type: this.scenario.type,
        spawnPoint: this.scenario.spawnPoint,
        mapPoint: this.scenario.mapPoint,
        scenes: this.scenario.scenes,
        presetScenario: this.scenario.presetScenario,
        linkedScenario: this.scenario.linkedScenario,
        correction: this.scenario.correction,
        revisionDelay: this.scenario.revisionDelay,
      }
    },
    async onSaveClick() {
      this.$emit('update-settings', this.settings)
    },
  },
}
</script>

<style lang="scss" scoped>
.settings {
  .settings-control {
    @apply mb-6;

    & > label {
      @apply mb-1 text-xs;
      display: block;
    }

    & > input,
    & > textarea {
      @apply w-full;
    }

    & > textarea {
      resize: none;
    }
  }
}
</style>
