<template>
  <div>
    <div class="flex">
      <table class="lms-table w-4/5">
        <!-- Column index label -->
        <tr class="lms-headers">
          <th class="b-n"></th>
          <th class="b-n"></th>
          <th
            class="lms-header text-center py-1 cursor-pointer"
            :class="{
              selected: hoveredColumn == index,
              highlighted: hoveredColumn == index + 1,
            }"
            :title="canAddColumn ? 'Supprimer la colonne' : 'Colonne ' + getColumnIndexLabel(index)"
            v-for="(header, index) in columnHeaders"
            :key="header.column"
            @mouseenter="hoveredColumn = index"
            @mouseleave="columnHeaderMouseLeave(index)"
            @click="deleteColumn(index)"
          >
            <span class="header-index" v-if="hoveredColumn != index || !canAddColumn">
              {{ getColumnIndexLabel(index) }}
            </span>
            <span class="header-delete" v-else> x </span>
          </th>
        </tr>

        <!-- Column titles -->
        <tr class="lms-headers">
          <th class="b-n"></th>
          <th class="b-n"></th>
          <th
            class="lms-header bb-n text-center p-5"
            :class="{
              selected: hoveredColumn == index,
              highlighted: hoveredColumn == index + 1,
            }"
            title="Editer l'entête de la colonne"
            v-for="(header, index) in columnHeaders"
            :key="header.column"
            @mouseenter="hoveredColumn = index"
            @mouseleave="columnHeaderMouseLeave(index)"
          >
            <div v-if="!hasPictureOnColumnHeaders">
              <textarea
                class="w-full h-16 border-2"
                contenteditable
                :value="header.content"
                @input="updateColumnHeader(header.column, $event)"
              >
              </textarea>
            </div>
            <template v-else>
              <div v-if="!header.image && !imageState" :key="header.image">
                <button type="button" @click="ShowRightPanel(null, index)" class="twn-button">
                  Ajouter une image
                </button>
              </div>
              <div v-else-if="imageState && !header.image">
                <div
                  class="drop-zone p-10 w-full h-full border-dashed border-2"
                  @dragover="allowDrop"
                  @drop.prevent.stop="DropAssetInHeader($event, null, index)"
                  @click="ShowRightPanel(null, index)"
                >
                  <div class="m-auto w-1/3 break-words text-center text-xs">
                    Ajouter une image depuis la bibliothèque de média
                  </div>
                </div>
              </div>
              <div
                v-else-if="header.image && assets[header.image]"
                class="relative flex flex-center"
                @dragover="allowDrop"
                @drop.prevent.stop="DropAssetInHeader($event, null, index)"
              >
                <div
                  class="w-4 h-4 absolute right-0 top-0 cursor-pointer pt-1"
                  @pointerdown.stop="DeleteHeaderAsset(null, index)"
                >
                  <img src="@/assets/images/NODE_CROSS.svg" />
                </div>
                <img :src="assets[header.image].thumbnail_url" alt="" />
              </div>
              <div v-else-if="!assets[header.image]">Un probleme de média est survenu</div>
            </template>
          </th>
        </tr>

        <!-- Content lines -->
        <tr
          class="lms-line"
          :class="{
            selected: hoveredLine == index,
            highlighted: hoveredLine == index + 1,
          }"
          v-for="(row, index) in lineHeaders"
          :key="row.line"
        >
          <!-- Line header index label -->
          <td
            class="lms-line-header br-n py-6 px-3 cursor-pointer"
            :class="{
              'bb-n': index == lineHeaders.length - 1,
            }"
            :title="canAddLine ? 'Supprimer la ligne' : 'Ligne ' + (index + 1)"
            @mouseenter="hoveredLine = index"
            @mouseleave="lineHeaderMouseLeave(index)"
            @click="deleteLine(index)"
          >
            <span class="header-index" v-if="hoveredLine != index || !canAddLine">
              {{ index + 1 }}
            </span>
            <span class="header-delete cursor-pointer" v-else> x </span>
          </td>

          <!-- Line header title -->
          <td
            class="lms-line-header br-n text-center py-6 px-8"
            :class="{
              'bb-n': index == lineHeaders.length - 1,
              'highlighted': hoveredColumn == 0,
            }"
            title="Editer l'entête de la ligne"
            @mouseenter="hoveredLine = index"
            @mouseleave="lineHeaderMouseLeave(index)"
          >
            <div v-if="!hasPictureOnLineHeaders">
              <textarea
                class="w-full h-16 border-2"
                contenteditable
                :value="row.content"
                @input="updateLineHeader(row.line, $event)"
              >
              </textarea>
            </div>
            <template v-else>
              <div v-if="!row.image && !imageState">
                <button type="button" @click="ShowRightPanel(index, null)" class="twn-button">
                  Ajouter une image
                </button>
              </div>
              <div v-else-if="imageState && !row.image">
                <div
                  class="drop-zone p-10 w-full h-full border-dashed border-2"
                  @dragover="allowDrop"
                  @drop.prevent.stop="DropAssetInHeader($event, index, null)"
                  @click="ShowRightPanel(index, null)"
                >
                  <div class="m-auto w-1/3 break-words text-center text-xs">
                    Ajouter une image depuis la bibliothèque de média
                  </div>
                </div>
              </div>
              <div
                v-else-if="row.image && assets[row.image]"
                class="relative"
                @dragover="allowDrop"
                @drop.prevent.stop="DropAssetInHeader($event, null, index)"
              >
                <div
                  class="w-4 h-4 absolute right-0 top-0 cursor-pointer pt-1"
                  @pointerdown.stop="DeleteHeaderAsset(index, null)"
                >
                  <img src="@/assets/images/NODE_CROSS.svg" />
                </div>
                <img :src="assets[row.image].thumbnail_url" alt="" />
              </div>
              <div v-else-if="!assets[row.image]">Un probleme de média est survenu</div>
            </template>
          </td>

          <!-- Line answers questions -->
          <td
            class="lms-line-content"
            :class="{
              'bb-n': index == lineHeaders.length - 1,
              'selected': hoveredColumn == hIndex,
              'highlighted': hoveredColumn == hIndex + 1,
            }"
            v-for="(header, hIndex) in columnHeaders"
            :key="header.column"
            @dragenter.prevent
            @dragover.prevent
            @drop.prevent.stop="addChoice($event, row.line, hIndex)"
          >
            <slot
              :question="
                questions && questions[row.line] ? questions[row.line][header.column] : null
              "
            />
          </td>
        </tr>
      </table>

      <!-- Add column button -->
      <div
        v-if="canAddColumn"
        @click="addColumn"
        class="ml-5 plus m-auto rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl"
      >
        +
      </div>
    </div>

    <!-- Add line button -->
    <div
      v-if="canAddLine"
      @click="addLine"
      class="mt-5 plus m-auto rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl"
    >
      +
    </div>
    <RightPanel ref="rightPanel" title="Choix de média" @hide="onRightPanelHide">
      <Medias
        :defaultTypeFilterSlug="['image']"
        @select-media="SelectMedia"
        :onDragEnd="slideInRightPanel"
        :onDragStart="slideOutRightPanel"
        :selectOnly="true"
        :draggableAsset="true"
      ></Medias>
    </RightPanel>
  </div>
</template>

<script>
import Medias from '@/views/Medias'
import RightPanel from '@/components/RightPanel'

export default {
  name: 'Table',
  props: {
    game: {
      type: Object,
      required: true,
      default: null,
    },
    assets: {
      type: Object,
      required: false,
      default: null,
    },
    hasPictureOnColumnHeaders: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasPictureOnLineHeaders: {
      type: Boolean,
      required: false,
      default: false,
    },
    canAddColumn: {
      type: Boolean,
      required: false,
      default: true,
    },
    canAddLine: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    RightPanel,
    Medias,
  },
  data() {
    return {
      hoveredLine: null,
      hoveredColumn: null,
      imageState: false,
      selectedLine: null,
      selectedColumn: null,
    }
  },
  computed: {
    columnHeaders() {
      if (!this.game) return []

      return this.game.headers
        .filter((header) => header.column != null)
        .sort((h1, h2) => h1.column - h2.column)
    },
    lineHeaders() {
      if (!this.game) return []

      return this.game.headers
        .filter((header) => header.line != null)
        .sort((h1, h2) => h1.line - h2.line)
    },
    questions() {
      return this.game.questions.reduce((questions, question) => {
        // Check if line exist
        if (questions[question.line] == null || questions[question.line] == undefined) {
          questions[question.line] = {}
        }

        // Add question to the cell
        questions[question.line][question.column] = question

        return questions
      }, {})
    },
  },
  methods: {
    SelectMedia(asset) {
      if (
        (this.selectedLine != null && this.selectedLine != undefined) ||
        (this.selectedColumn != null && this.selectedColumn != undefined)
      ) {
        for (var i = 0; i < this.game.headers.length; i++) {
          if (
            this.selectedColumn !== null &&
            this.selectedColumn !== undefined &&
            this.game.headers[i].column == this.selectedColumn
          ) {
            this.$emit('update-header', i, {
              image: asset.id,
            })
          } else if (
            this.selectedLine !== null &&
            this.selectedLine !== undefined &&
            this.game.headers[i].line == this.selectedLine
          ) {
            this.$emit('update-header', i, {
              image: asset.id,
            })
          }
        }
        this.$forceUpdate()
      } else {
        this.$emit('media-selected', asset)
      }
    },
    getColumnIndexLabel(index) {
      let label = ''

      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      for (var i = 0; i < Math.ceil((index + 1) / letters.length); i++) {
        label += letters[index % letters.length]
      }

      return label
    },
    columnHeaderMouseLeave(index) {
      if (this.hoveredColumn == index) {
        this.hoveredColumn = null
      }
    },
    lineHeaderMouseLeave(index) {
      if (this.hoveredLine == index) {
        this.hoveredLine = null
      }
    },
    updateColumnHeader(column, e) {
      e.preventDefault()
      let text = e.target.value
      for (var i = 0; i < this.game.headers.length; i++) {
        if (this.game.headers[i].column == column) {
          this.$emit('update-header', i, {
            content: text,
          })
        }
      }
    },
    updateLineHeader(line, e) {
      e.preventDefault()
      let text = e.target.value
      for (var i = 0; i < this.game.headers.length; i++) {
        if (this.game.headers[i].line == line) {
          this.$emit('update-header', i, {
            content: text,
          })
        }
      }
    },
    addColumn() {
      this.$emit('add-column', {
        lineCount: this.lineHeaders.length,
        columnCount: this.columnHeaders.length,
      })
    },
    addLine() {
      this.$emit('add-line', {
        lineCount: this.lineHeaders.length,
        columnCount: this.columnHeaders.length,
      })
    },
    deleteColumn(column) {
      if (column < 0 || column >= this.columnHeaders.length || this.columnHeaders.length === 1)
        return

      this.$emit('delete-column', column)
    },
    deleteLine(line) {
      if (line < 0 || line >= this.lineHeaders.length || this.lineHeaders.length === 1) return

      this.$emit('delete-line', line)
    },
    ShowRightPanel(line, column) {
      this.selectedLine = null
      this.selectedColumn = null
      if ((line != null && line != undefined) || (column != null && column != undefined)) {
        this.selectedLine = line
        this.selectedColumn = column
      }
      this.imageState = true
      this.$refs['rightPanel'].show()
      this.$emit('show-right-panel')
    },
    DropAssetInHeader(e, line, column) {
      if (e.dataTransfer) {
        const assetJSON = e.dataTransfer.getData('application/json')
        const asset = assetJSON ? JSON.parse(assetJSON) : null
        for (var i = 0; i < this.game.headers.length; i++) {
          if (column !== null && column !== undefined && this.game.headers[i].column == column) {
            this.$emit('update-header', i, {
              image: asset.id,
            })
          } else if (line !== null && line !== undefined && this.game.headers[i].line == line) {
            this.$emit('update-header', i, {
              image: asset.id,
            })
          }
        }
      }
    },
    DeleteHeaderAsset(line, column) {
      for (var i = 0; i < this.game.headers.length; i++) {
        if (column !== null && column !== undefined && this.game.headers[i].column == column) {
          this.$emit('update-header', i, {
            image: null,
          })
        } else if (line !== null && line !== undefined && this.game.headers[i].line == line) {
          this.$emit('update-header', i, {
            image: null,
          })
        }
      }
      this.$forceUpdate()
    },
    allowDrop: function (event) {
      event.preventDefault()
    },
    onRightPanelHide() {
      this.imageState = false
    },
    slideInRightPanel() {
      this.$refs['rightPanel'].slideIn()
      this.imageState = false
    },
    slideOutRightPanel() {
      this.$refs['rightPanel'].slideOut()
      this.imageState = true
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  &-close {
    @apply hidden;
  }
}
</style>
