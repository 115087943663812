import { Node } from '@tiptap/core'

export default Node.create({
  name: 'pdf-button',
  atom: true,
  group: 'block',
  content: 'inline*',
  draggable: true,
  addAttributes() {
    return {
      'data-url': {
        default: '',
      },
      'data-filename': {
        default: 'file.pdf',
      },
      'class': {
        default: 'pdf-button c-button orange medium mb-4',
      },
    }
  },

  renderHTML({ HTMLAttributes }) {
    return ['button', HTMLAttributes, HTMLAttributes['data-filename']]
  },

  parseHTML() {
    return [
      {
        tag: 'button.pdf-button',
      },
    ]
  },

  addCommands() {
    return {
      setPdf:
        (media) =>
        ({ commands }) => {
          return commands.insertContent({
            type: 'pdf-button',
            attrs: {
              'data-url': media.path,
              'data-filename': media.title || media.identifier,
            },
          })
        },
    }
  },
})
