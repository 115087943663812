<template>
  <div class="true-false">
    <div class="my-4">
      Correction:
      <b-form-textarea v-model="correction"></b-form-textarea>
    </div>
    <div>
      <Toggle :checked="imageType" :onInput="changeReorderType" class="mb-5 mr-3"></Toggle>
      <span v-if="!imageType" class="uppercase font-principal-medium mr-3">Texte</span>
      <span v-else class="uppercase font-principal-medium mr-3">Images</span>
    </div>
    <Draggable v-model="questions" handle=".handle" v-bind="dragOptions">
      <div class="" v-for="(question, i) in questions" :key="i">
        <QuestionSimple
          :question="question"
          :number="i"
          class="my-5"
          @delete-question="deleteQuestion"
          @update-question="updateQuestion"
          @open-panel="OpenPanel"
          :options="options"
          :imageType="imageType"
          :mediaLoaded="mediaLoaded"
        >
        </QuestionSimple>
      </div>
    </Draggable>
    <div
      @click="addQuestion"
      class="plus m-auto rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl"
    >
      +
    </div>
    <RightPanel ref="rightPanel" title="Choix de média">
      <Medias :draggable-asset="true" @media-loaded="Medialoaded"></Medias>
    </RightPanel>
  </div>
</template>
<script>
import Toggle from '@/components/Toggle'
import RightPanel from '@/components/RightPanel'
import Draggable from 'vuedraggable'
import QuestionSimple from '@/components/Games/Question_simple'
import Medias from '@/views/Medias'

export default {
  name: 'Reorder',
  components: {
    QuestionSimple,
    Draggable,
    Toggle,
    Medias,
    RightPanel,
  },
  props: {
    game: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      imageType: false,
      options: {
        fixedAnswers: true,
        noCorrection: true,
        questionPlaceholder: 'Proposition',
        multipleAnswers: true,
      },
      mediaLoaded: false,
    }
  },
  mounted() {
    if (this.questions.length > 0 && this.questions[0].media_id) {
      this.imageType = true
    }
  },
  computed: {
    questions: {
      get() {
        if (!this.game) return []
        return JSON.parse(JSON.stringify(this.game.questions))
      },
      set(value) {
        this.$emit('update-all-questions', value)
      },
    },
    correction: {
      get() {
        return this.game.correction
      },
      set(value) {
        this.$emit('update-correction', value)
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
  },
  methods: {
    addQuestion() {
      let question = {
        answers: [],
        text: '',
        media_id: null,
      }
      this.$emit('add-questions', [question])
    },
    updateQuestion(i, data) {
      this.$emit('update-question', i, data)
    },
    deleteQuestion(i) {
      this.$emit('delete-questions', [i])
    },
    changeReorderType() {
      this.imageType ? (this.imageType = false) : (this.imageType = true)
    },
    OpenPanel() {
      this.$refs['rightPanel'].show()
    },
    Medialoaded() {
      this.mediaLoaded = true
    },
  },
}
</script>

<style lang="scss"></style>
