import gql from 'graphql-tag'

const GC_GET_PROMOTION_TYPE_LIST = gql`
  query GetPromotionTypeList {
    group_type {
      id
      name
      description
      slug
    }
  }
`

const GC_GET_PROMOTION_LIST = gql`
  query GetPromotionList {
    group {
      id
      identifier
      name
      group_type_id
      start_date
      end_date
      disabled
      customer {
        name
      }
      users_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

const GC_GET_PROMOTION_BY_ID = gql`
  query PromotionByID($id: uuid!) {
    group_by_pk(id: $id) {
      id
      identifier
      name
      start_date
      end_date
      course_id
      customer_id
      disabled
      promotion_type_id: group_type_id

      activities {
        id
        name: identifier
        title
        type {
          slug
        }
      }

      course {
        modules {
          id
          scenarios {
            scenario {
              id
              name: identifier
              title
              type {
                slug
              }
            }
          }
        }
      }

      sequences {
        sequence_id
        start_date
        end_date
        user_id
      }
      users {
        user_id
      }
      contentLogs(order_by: { created_at: desc }) {
        created_at
        user {
          name: last_name
          first_name
        }
      }
    }
  }
`

// $activities_ids: [uuid!]!
const GC_ADD_PROMOTION_ONE = gql`
  mutation AddPromotion(
    $identifier: String!
    $name: String!
    $start_date: date!
    $end_date: date!
    $customer_id: uuid
    $disabled: Boolean
    $promotion_type_id: uuid!
    $course_type_id: uuid!
    $sequences: [group_sequence_insert_input!]!
    $users: [group_user_insert_input!]!
    $scenarios: [module_scenario_insert_input!]!
    $activities: [activity_insert_input!]!
  ) {
    insert_group_one(
      object: {
        identifier: $identifier
        name: $name
        start_date: $start_date
        end_date: $end_date
        customer_id: $customer_id
        disabled: $disabled
        group_type_id: $promotion_type_id
        sequences: { data: $sequences }
        users: { data: $users }
        activities: {
          data: $activities
          on_conflict: { constraint: activity_pkey, update_columns: [group_id] }
        }
        course: {
          data: {
            identifier: $identifier
            title: $name
            course_type_id: $course_type_id
            modules: {
              data: {
                identifier: $identifier
                title: $name
                order: 1
                scenarios: { data: $scenarios }
              }
            }
          }
        }
      }
    ) {
      id
    }
  }
`
/*
	1) Update promotion base data
	2) TODO : Delete sequences that are no longer associated with this promotion
	3) TODO : Insert newly associated sequences or update existing ones
	4) Delete users that are no longer associated with this promotion
	5) Insert newly associated users
*/
const GC_UPDATE_PROMOTION_BY_ID = gql`
  mutation UpdatePromotion(
    $id: uuid!
    $identifier: String!
    $name: String!
    $start_date: date!
    $end_date: date!
    $course_id: uuid!
    $customer_id: uuid
    $disabled: Boolean
    $promotion_type_id: uuid!
    ### $sequences_ids: [uuid!]!,
    $sequences: [group_sequence_insert_input!]!
    $users_ids: [uuid!]!
    $users: [group_user_insert_input!]!
    $scenarios: [module_scenario_insert_input!]!
    $activities_id: [uuid!]!
  ) {
    update_group_by_pk(
      pk_columns: { id: $id }
      _set: {
        identifier: $identifier
        name: $name
        start_date: $start_date
        end_date: $end_date
        course_id: $course_id
        customer_id: $customer_id
        disabled: $disabled
        group_type_id: $promotion_type_id
      }
    ) {
      id
    }

    remove_activities_group_id: update_activity(
      where: { group_id: { _eq: $id } }
      _set: { group_id: null }
    ) {
      affected_rows
    }

    add_activities_group_id: update_activity(
      where: { id: { _in: $activities_id } }
      _set: { group_id: $id }
    ) {
      affected_rows
    }

    delete_module_scenario(where: { module: { course: { groups: { id: { _eq: $id } } } } }) {
      affected_rows
    }

    insert_module_scenario(objects: $scenarios) {
      affected_rows
    }

    delete_group_sequence(
      where: {
        ### sequence_id: {_nin: $sequences_ids},
        group_id: { _eq: $id }
      }
    ) {
      affected_rows
    }
    insert_group_sequence(
      objects: $sequences
      on_conflict: {
        constraint: group_sequence_group_id_sequence_id_user_id_key
        update_columns: [start_date, end_date]
      }
    ) {
      affected_rows
    }

    delete_group_user(where: { user_id: { _nin: $users_ids }, group_id: { _eq: $id } }) {
      affected_rows
    }
    insert_group_user(
      objects: $users
      on_conflict: { constraint: group_user_pkey, update_columns: [] }
    ) {
      affected_rows
    }
  }
`

const GC_DELETE_PROMOTION_BY_ID = gql`
  mutation DeletePromotion($id: uuid!) {
    delete_group_by_pk(id: $id) {
      id
      identifier
      name
    }
  }
`

export {
  GC_GET_PROMOTION_TYPE_LIST,
  GC_GET_PROMOTION_LIST,
  GC_GET_PROMOTION_BY_ID,
  GC_ADD_PROMOTION_ONE,
  GC_UPDATE_PROMOTION_BY_ID,
  GC_DELETE_PROMOTION_BY_ID,
}
