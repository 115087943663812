import Vue from 'vue'
import VueRouter from 'vue-router'
import Authentication from '@/views/Authentication'
import UserList from '@/views/UserList'
import NotFound from '@/views/NotFound'
import UserDetail from '@/views/UserDetail'
import ScenarioList from '@/views/ScenarioList'
import ScenarioDetailLight from '@/views/ScenarioDetailLight'
import MediaList from '@/views/Medias'
import GameCreation from '@/views/GameCreation'
import PromotionList from '@/views/Promotions/PromotionList'
import PromotionDetail from '@/views/Promotions/PromotionDetail'
import MinigamesList from '../views/MinigamesList.vue'
import PageDetail from '@/views/PageDetail'
import PlanningList from '@/views/PlanningList'
import PlanningDetail from '@/views/PlanningDetail'

import store from '@/store/index'
import ActivityComments from '../views/Comments/ActivityComments.vue'

Vue.use(VueRouter)

const routes = [
  /**
   * Comments
   */
  {
    path: '/homepage',
    name: 'homepage',
    component: PageDetail,
    meta: {
      breadcrumb: "Page d'accueil",
      title: "Page d'accueil",
    },
  },

  /**
   * Comments
   */
  {
    path: '/comments',
    name: 'comments',
    component: ActivityComments,
    meta: {
      breadcrumb: 'Commentaires',
      title: 'Commentaires',
    },
  },

  /**
   * Groups
   */
  {
    path: '/groups/list',
    name: 'groups-list',
    component: PromotionList,
    props: true,
    meta: {
      breadcrumb: 'Liste des programmes',
      title: 'Liste des programmes',
    },
  },
  {
    path: '/groups/edit/:itemID?',
    name: 'groups-edit',
    component: PromotionDetail,
    props: true,
    meta: {
      breadcrumb: 'Programme',
      title: 'Éditer un programme',
    },
  },
  {
    path: '/promotion/create',
    name: 'groups-create',
    component: PromotionDetail,
    props: true,
    meta: {
      breadcrumb: 'Ajouter un programme',
      title: 'Ajouter un programme',
    },
  },

  /**
   * Plannings
   */
  {
    path: '/planning/list',
    name: 'planning-list',
    component: PlanningList,
    props: true,
    meta: {
      breadcrumb: 'Liste des plannings',
      title: 'Liste des plannings',
    },
  },
  {
    path: '/planning/edit/:itemID?',
    name: 'planning-edit',
    component: PlanningDetail,
    props: true,
    meta: {
      breadcrumb: 'Planning',
      title: 'Éditer un planning',
    },
  },
  {
    path: '/planning/create',
    name: 'planning-create',
    component: PlanningDetail,
    props: true,
    meta: {
      breadcrumb: 'Ajouter un planning',
      title: 'Ajouter un planning',
    },
  },

  /**
   * Users
   */
  {
    path: '/',
    name: 'users-list',
    props: true,
    component: UserList,
    meta: {
      breadcrumb: 'Étudiants',
      title: 'Liste des étudiant',
    },
  },
  {
    path: '/user/edit/:itemID?',
    name: 'user-edit',
    component: UserDetail,
    props: true,
    meta: {
      breadcrumb: 'Étudiants',
      title: 'Editer un étudiant',
    },
  },
  {
    path: '/user/create',
    name: 'users-create',
    component: UserDetail,
    props: true,
    meta: {
      breadcrumb: 'Utilisateurs',
      title: 'Ajouter un utilisateur',
    },
  },

  /**
   * Scenarios
   */
  {
    path: '/scenario/list/:typeSlug',
    name: 'scenario-list',
    props: true,
    component: ScenarioList,
    meta: {
      breadcrumb(route) {
        switch (route.params.typeSlug) {
          case 'basic':
            return 'UE'
          case 'chaptered_course':
            return 'Cours'
        }

        return 'Scénario'
      },
      titleFn(route) {
        switch (route.params.typeSlug) {
          case 'basic':
            return 'Liste des UE'
          case 'chaptered_course':
            return 'Liste des cours'
        }

        return 'Liste des scénario'
      },
    },
  },
  {
    path: '/scenario/edit/:typeSlug/:itemID?',
    name: 'scenario-edit',
    component: ScenarioDetailLight,
    props: true,
    meta: {
      titleFn(route) {
        switch (route.params.typeSlug) {
          case 'basic':
            return 'Éditer une UE'
          case 'chaptered_course':
            return 'Éditer un cours'
        }

        return 'Éditer un scénario'
      },
      noMargin: true,
    },
  },

  /**
   * Activities
   */
  {
    path: '/activity/list/:type',
    name: 'activity-list',
    props: true,
    component: MinigamesList,
    meta: {
      breadcrumb: (route) => {
        let type = store.state.Games.type_list.find((type) => type.slug == route.params.type)
        if (type) {
          let name = type.name

          if (type.name === 'QCM') {
            name = 'chapitres'
          }

          return name
        }
      },
      titleFn: (route, store) => {
        let name = ''

        if (route) {
          switch (route.params.type) {
            case 'quiz_simple':
              name = 'chapitres'
              break

            case 'colle':
              name = 'colles'
              break

            case 'mock_exam':
              name = 'concours blancs'
              break
          }

          return `Liste des ${name}`
        }

        return name
      },
    },
  },
  {
    path: '/activity/edit/:gameTypeSlug/:itemID?',
    name: 'activity-edit',
    component: GameCreation,
    props: true,
    meta: {
      breadcrumb: (route) => {
        let type = store.state.Games.type_list.find(
          (type) => type.slug == route.params.gameTypeSlug
        )
        if (type) {
          let name = type.name

          if (type.name === 'QCM') {
            name = 'chapitres'
          }

          return name
        }
      },
      titleFn: (route) => {
        let name = ''

        if (route) {
          switch (route.params.gameTypeSlug) {
            case 'quiz_simple':
              name = 'un chapitre'
              break

            case 'colle':
              name = 'une colle'
              break

            case 'mock_exam':
              name = 'un concours blanc'
              break
          }

          if (!route.params.itemID) {
            return `Créer ${name}`
          } else {
            return `Modifier ${name}`
          }
        }

        return name
      },
    },
  },

  /**
   * Medias
   */
  {
    path: '/media/list',
    name: 'media-list',
    component: MediaList,
    meta: {
      breadcrumb: 'Médias',
      title: 'Médias',
    },
  },

  /**
   * Misc
   */
  {
    path: '/auth',
    name: 'auth',
    component: Authentication,
    meta: {
      title: 'Authentication',
      layout: 'no-sidebar',
      noAuth: true,
    },
  },
  {
    path: '*',
    component: NotFound,
    name: 'notfound',
    meta: {
      breadcrumb: '404',
      layout: 'no-sidebar',
      title: '404 - Not Found',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
})

// Authentication check
router.beforeEach(async (to, from, next) => {
  // Check if we need to check authentication status
  if (to.meta.noAuth) {
    return next()
  }

  // Check the authentication status of the user
  if (!store.getters['Auth/isLoggedIn']) {
    return next({ name: 'auth' })
  }

  // Handle first page load
  if (!store.state.Auth.userInfo) {
    try {
      await store.dispatch('Auth/getCurrentUserInfo')
    } catch (error) {
      return next(new Error('Failed to load user info'))
    }

    // Check if we didn't get userInfo (can still be null if token has expired)
    if (!store.state.Auth.userInfo) {
      return next({ name: 'auth' })
    }
  }

  return next()
})

export default router
