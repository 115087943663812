import gql from 'graphql-tag'

const GC_GET_MODAL_LIST = gql`
  query GetModalList {
    modal {
      id
      title
      text
    }
  }
`

const GC_ADD_MODAL_ONE = gql`
  mutation AddModalOne($title: String = "", $text: String = "") {
    insert_modal_one(object: { title: $title, text: $text }) {
      id
    }
  }
`

const GC_UPDATE_MODAL_BY_ID = gql`
  mutation UpdateModalOne($id: uuid!, $title: String = "", $text: String = "") {
    update_modal(where: { id: { _eq: $id } }, _set: { title: $title, text: $text }) {
      affected_rows
      returning {
        id
      }
    }
  }
`

const GC_DELETE_MODAL_ONE = gql`
  mutation DeleteModal($id: uuid!) {
    delete_modal(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`

export { GC_GET_MODAL_LIST, GC_ADD_MODAL_ONE, GC_UPDATE_MODAL_BY_ID, GC_DELETE_MODAL_ONE }
