<template>
  <div class="flex" :class="isAdminComment ? 'justify-end' : ''">
    <div :class="commentClasses" class="px-4 pb-2 pt-3 rounded-lg shadow-sm mb-4 text-sm w-2/3">
      <div class="mb-2 font-semibold">
        {{ comment.user.first_name }} - {{ comment.user.last_name }}
      </div>

      <div class="font-thin">
        {{ comment.content }}
      </div>
      <div class="text-xs text-gray-400 flex justify-end">
        {{ comment.created_at }}
      </div>
    </div>

    <div class="flex flex-col text-lg px-2 space-y-2 order-1 items-end">
      <b-icon-check
        v-if="comment.validated === null || comment.validated === false"
        class="text-dashboard-green cursor-pointer"
        @click="onValidateClick"
      ></b-icon-check>
      <b-icon-x
        v-if="comment.validated === null || comment.validated === true"
        class="text-red-600 cursor-pointer"
        @click="onRefuseClick"
      ></b-icon-x>
      <span
        @click="onMakePublicClick"
        v-if="comment.validated === false && !comment.public"
        class="underline italic text-xs"
        >Rendre visible</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isAdminComment() {
      return ['admin', 'superadmin'].includes(this.comment.user.role)
    },

    commentClasses() {
      const classes = []

      if (this.isAdminComment) {
        classes.push(['bg-blue', 'text-white', 'order-2'])
      } else {
        classes.push(['bg-gray-200'])
      }

      if (this.comment.validated === null) {
        classes.push(['bg-blue-100'])
      }

      if (this.comment.validated === false) {
        classes.push(['bg-gray-100 opacity-50'])
      }

      return classes
    },
  },

  methods: {
    onValidateClick() {
      this.$emit('validate', this.comment)
    },

    onRefuseClick() {
      this.$emit('refuse', this.comment)
    },

    onMakePublicClick() {
      this.$emit('make-public', this.comment)
    },
  },
}
</script>
