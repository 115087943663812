import {
  GC_GET_VIDEO_PART_LIST,
  GC_ADD_VIDEO_PART_ONE,
  GC_UPDATE_VIDEO_PART_BY_ID,
  GC_DELETE_VIDEO_PART_ONE,
} from '@/graphql/video-part'
import apollo from '@/apolloClient'

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_VIDEO_PART_LIST(state, video_parts) {
      state.list = video_parts || []
    },
  },
  actions: {
    async getList({ commit }) {
      const response = await apollo.query({ query: GC_GET_VIDEO_PART_LIST })

      commit('SET_VIDEO_PART_LIST', response.data.video_part)
    },
    async save(ctx, { id, media_id, start_time, end_time, frame_id }) {
      // Create or update a video part
      if (id) {
        // Update remote data
        const response = await apollo.mutate({
          mutation: GC_UPDATE_VIDEO_PART_BY_ID,
          variables: {
            id,
            media_id,
            start_time,
            end_time,
            frame_id,
          },
        })

        return response && response.data && response.data.update_video_part.returning[0]
          ? response.data.update_video_part.returning[0].id
          : null
      } else {
        // Add remote data
        const response = await apollo.mutate({
          mutation: GC_ADD_VIDEO_PART_ONE,
          variables: {
            media_id,
            start_time,
            end_time,
            frame_id,
          },
        })

        return response && response.data && response.data.insert_video_part_one
          ? response.data.insert_video_part_one.id
          : null
      }
    },
    delete(ctx, id) {
      // Delete from db
      return apollo.mutate({
        mutation: GC_DELETE_VIDEO_PART_ONE,
        variables: { id },
      })
    },
  },
}
