import apollo from '@/apolloClient'
import {
  CG_GET_ORGAS,
  // CG_GET_STATUS,
  GC_GET_USERS,
  GC_GET_FEREDATIONS,
  CG_GET_META_TYPES,
} from '@/graphql/utils'

export default {
  namespaced: true,
  state: {
    organisationNameList: [],
    statusNameList: [],
    userList: [],
    federationList: [],
    logTypes: [],
    metaTypeList: [],
  },
  getters: {
    getOrgaNameList(state) {
      return state.organisationNameList
    },
    getStatusNameList(state) {
      return state.statusNameList
    },
    getUserList(state) {
      return state.userList
    },
  },
  mutations: {
    SET_ORGA_LIST(state, orgaList) {
      state.organisationNameList = orgaList
    },
    SET_STATUS_LIST(state, statusList) {
      if (statusList && Array.isArray(statusList)) {
        let filteredArr = statusList.filter((e) => e.experienced !== null)
        state.statusNameList = filteredArr
      }
    },
    SET_USER_LIST(state, userList) {
      state.userList = userList
    },
    SET_FEREDATIONS_LIST(state, fedList) {
      state.federationList = fedList
    },
    SET_META_TYPE_LIST(state, metaTypeList) {
      state.metaTypeList = metaTypeList
    },
  },
  actions: {
    async initAll({ state, dispatch }) {
      // if (state.organisationNameList.length == 0){
      //     await dispatch('getOrgas');
      // }
      // if (state.statusNameList.length == 0){
      //     await dispatch('getStatus');
      // }
      if (state.userList.length == 0) {
        await dispatch('getUsers')
      }
      if (state.metaTypeList.length == 0) {
        await dispatch('getMetaTypeList')
      }
      // if (state.federationList.length == 0){
      //     await dispatch('getFederations')
      // }
    },
    async getOrgas({ commit }) {
      const response = await apollo.query({
        query: CG_GET_ORGAS,
      })
      commit('SET_ORGA_LIST', response.data.organisations)
      return response
    },
    async getMetaTypeList({ commit }) {
      const response = await apollo.query({
        query: CG_GET_META_TYPES,
      })
      commit('SET_META_TYPE_LIST', response.data.meta_type)
      return response
    },
    // async getStatus({commit}){
    //     const response = await apollo.query({
    //         query: CG_GET_STATUS
    //     })
    //     commit('SET_STATUS_LIST', response.data.user_aggregate.nodes);
    //     return response;
    // },
    async getUsers({ commit }) {
      const response = await apollo.query({
        query: GC_GET_USERS,
      })
      commit('SET_USER_LIST', response.data.user)
      return response
    },
    async getFederations({ commit }) {
      const response = await apollo.query({
        query: GC_GET_FEREDATIONS,
      })
      commit('SET_FEREDATIONS_LIST', response.data.federations)
      return response
    },
  },
}
