import gql from 'graphql-tag'

const GC_GET_SCENARIO_TYPE_LIST = gql`
  query GetScenarioTypeList {
    scenario_type {
      id
      name
      description
      slug
    }
  }
`

const GC_GET_SCENARIO_NAME_BY_ID = gql`
  query GetScenarioNames($ids: [uuid!]!) {
    scenario(where: { id: { _in: $ids } }) {
      id
      name: identifier
      title
      subject
      semester
    }
  }
`

const GC_GET_SCENARIO_LIST = gql`
  query GetScenarioList {
    scenario {
      id
      name: identifier
      title
      semester
      subject
      description
      scenario_type_id
      type {
        slug
      }
    }
  }
`

const GC_GET_SCENARIO_BY_ID = gql`
  query ScenarioByID($id: uuid!) {
    scenario(where: { id: { _eq: $id } }) {
      id
      name: identifier
      title
      description
      subject
      semester
      scenario_type_id
      nodes {
        id
        node_content
        nodeType: type {
          slug
        }
        scenario_id
        position_x
        position_y
        triggers {
          name
          node_id
          triggered_node_id
          triggerType: type {
            slug
          }
        }
      }
      contentLogs(order_by: { created_at: desc }) {
        created_at
        user {
          name: last_name
          first_name
        }
      }
    }
  }
`

const GC_GET_SCENARIO_NODES_BY_ID = gql`
  query ScenarioNodesByID($id: uuid!) {
    scenario(where: { id: { _eq: $id } }) {
      id
      nodes {
        id
        node_content
        nodeType: type {
          slug
        }
        triggers {
          node_id
          triggered_node_id
          triggerType: type {
            slug
          }
        }
      }
    }
  }
`

const GC_ADD_SCENARIO_ONE = gql`
  mutation AddScenario(
    $name: String!
    $title: String
    $description: String
    $scenario_type_id: uuid!
    $semester: String!
    $subject: String
    $nodes: [node_insert_input!]! = []
  ) {
    insert_scenario_one(
      object: {
        identifier: $name
        description: $description
        title: $title
        semester: $semester
        subject: $subject
        scenario_type_id: $scenario_type_id
        nodes: { data: $nodes }
      }
    ) {
      id
      nodes {
        id
      }
    }
  }
`

/*
  1) Update scenario base data
  2) Delete scenario locations that no longer exist, insert newly added scenario locations
  3) Delete scenario scenes that no longer exist, insert newly added scenario scenes
  4) Delete nodes that no longer exist, insert newly added nodes
*/
const GC_UPDATE_SCENARIO_BY_ID = gql`
  mutation UpdateScenario(
    $id: uuid!
    $name: String!
    $title: String
    $description: String
    $scenario_type_id: uuid!
    $semester: String!
    $subject: String
    $nodes: [node_insert_input!]! = []
  ) {
    update_scenario_by_pk(
      pk_columns: { id: $id }
      _set: {
        identifier: $name
        title: $title
        description: $description
        scenario_type_id: $scenario_type_id
        semester: $semester
        subject: $subject
      }
    ) {
      id
    }

    delete_node(where: { scenario_id: { _eq: $id } }) {
      affected_rows
    }
    insert_node(objects: $nodes) {
      affected_rows
      returning {
        id
      }
    }
  }
`

const GC_ADD_NODE_TRIGGERS = gql`
  mutation AddTriggers($triggers: [trigger_insert_input!]! = []) {
    insert_trigger(objects: $triggers) {
      affected_rows
    }
  }
`

export {
  GC_GET_SCENARIO_TYPE_LIST,
  GC_GET_SCENARIO_LIST,
  GC_GET_SCENARIO_BY_ID,
  GC_GET_SCENARIO_NODES_BY_ID,
  GC_ADD_SCENARIO_ONE,
  GC_UPDATE_SCENARIO_BY_ID,
  GC_ADD_NODE_TRIGGERS,
  GC_GET_SCENARIO_NAME_BY_ID,
}
