import gql from 'graphql-tag'

const GC_GET_COURSE_LIST = gql`
  query GetCourses {
    course {
      id
      identifier
      title
      description
    }
  }
`

const GC_GET_COURSE = gql`
  query GetCourse($id: uuid!) {
    course_by_pk(id: $id) {
      id
      type: course_type_id
      identifier
      title
      metas {
        id
        value
        meta_type_id
      }
      modules(order_by: { order: asc }) {
        id
        order
        identifier
        title
        metas {
          id
          value
          meta_type_id
        }
        scenarios(order_by: { order: asc }) {
          scenario_id
          order
        }
        media(order_by: { order: asc }) {
          medium_id
          order
        }
        sequences(order_by: { order: asc }) {
          id
          identifier
          order
          title
          metas {
            id
            value
            meta_type_id
          }
          scenarios(order_by: { order: asc }) {
            scenario_id
            order
          }
          media(order_by: { order: asc }) {
            order
            media {
              id
              identifier
              title
              type {
                slug
              }
            }
          }
        }
      }
    }
  }
`

const GC_GET_COURSE_ID_BY_TYPE = gql`
  query GetCourseIDByType($typeSlug: String!) {
    course(where: { type: { slug: { _eq: $typeSlug } } }) {
      id
    }
  }
`

const GC_GET_COURSE_BY_TYPE = gql`
  query GetCourseByType($typeSlug: String!) {
    course(where: { type: { slug: { _eq: $typeSlug } } }) {
      id
      modules_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

const GC_ADD_COURSE_ONE = gql`
  mutation AddCourse($course: course_insert_input!) {
    insert_course_one(object: $course) {
      id
    }
  }
`

const GC_UPDATE_COURSE = gql`
  mutation UpdateCourse(
    $id: uuid!
    $identifier: String!
    $title: String!
    $description: String!
    $type: uuid!
    $meta_ids: [uuid!]!
    $metas: [course_meta_insert_input!]!
    $module_ids: [uuid!]!
    $modules: [module_insert_input!]!
    $delete_module_metas_condition: module_meta_bool_exp!
    $module_meta_list: [module_meta_insert_input!]!
    $delete_module_media_condition: module_media_bool_exp!
    $module_media_list: [module_media_insert_input!]!
    $delete_module_scenarios_condition: module_scenario_bool_exp!
    $module_scenario_list: [module_scenario_insert_input!]!
    $delete_sequence_condition: sequence_bool_exp!
    $module_sequence_list: [sequence_insert_input!]!
    $delete_sequence_scenarios_condition: sequence_scenario_bool_exp!
    $sequence_scenario_list: [sequence_scenario_insert_input!]!
    $delete_sequence_media_condition: sequence_media_bool_exp!
    $sequence_media_list: [sequence_media_insert_input!]!
    $delete_sequence_metas_condition: sequence_meta_bool_exp!
    $sequence_meta_list: [sequence_meta_insert_input!]!
  ) {
    ## Update course base data
    update_course_by_pk(
      pk_columns: { id: $id }
      _set: {
        identifier: $identifier
        title: $title
        description: $description
        course_type_id: $type
      }
    ) {
      id
    }

    ## Delete old meta
    delete_course_meta(where: { id: { _nin: $meta_ids }, course_id: { _eq: $id } }) {
      affected_rows
    }

    ## Insert new metas or update base data for existing ones
    insert_course_meta(
      objects: $metas
      on_conflict: { constraint: course_meta_pkey, update_columns: [value] }
    ) {
      affected_rows
    }

    ## Delete old module
    delete_module(where: { id: { _nin: $module_ids }, course_id: { _eq: $id } }) {
      affected_rows
    }

    ## Insert new modules or update base data for existing ones
    insert_module(
      objects: $modules
      on_conflict: {
        constraint: module_pkey
        update_columns: [identifier, title, order, course_id]
      }
    ) {
      affected_rows
    }

    ### Update existing module associations ###

    ## Delete/Update module associated metas
    delete_module_meta(where: $delete_module_metas_condition) {
      affected_rows
    }
    insert_module_meta(
      objects: $module_meta_list
      on_conflict: { constraint: module_meta_pkey, update_columns: [value] }
    ) {
      affected_rows
    }

    ## Delete/Update module associated media
    delete_module_media(where: $delete_module_media_condition) {
      affected_rows
    }
    insert_module_media(
      objects: $module_media_list
      on_conflict: { constraint: module_media_pkey, update_columns: [order] }
    ) {
      affected_rows
    }

    ## Delete/Update module associated scenarios
    delete_module_scenario(where: $delete_module_scenarios_condition) {
      affected_rows
    }
    insert_module_scenario(
      objects: $module_scenario_list
      on_conflict: { constraint: module_scenario_pkey, update_columns: [order] }
    ) {
      affected_rows
    }

    ### Add/Update sequences ###

    ## Delete old sequences
    delete_sequence(where: $delete_sequence_condition) {
      affected_rows
    }

    ## Insert new sequences or update base data for existing ones
    insert_sequence(
      objects: $module_sequence_list
      on_conflict: {
        constraint: sequence_pkey
        update_columns: [identifier, title, order, module_id]
      }
    ) {
      affected_rows
    }

    ### Update existing sequences associations ###

    ## Delete old sequences scenarios associations
    delete_sequence_scenario(where: $delete_sequence_scenarios_condition) {
      affected_rows
    }

    ## Add new sequence scenarios associations or update base data for existing ones
    insert_sequence_scenario(
      objects: $sequence_scenario_list
      on_conflict: { constraint: sequence_scenario_pkey, update_columns: [order] }
    ) {
      affected_rows
    }

    ## Delete old sequences media associations
    delete_sequence_media(where: $delete_sequence_media_condition) {
      affected_rows
    }

    ## Add new sequence media associations or update base data for existing ones
    insert_sequence_media(
      objects: $sequence_media_list
      on_conflict: { constraint: sequence_media_pkey, update_columns: [order] }
    ) {
      affected_rows
    }

    ## Delete old sequences meta associations
    delete_sequence_meta(where: $delete_sequence_metas_condition) {
      affected_rows
    }

    ## Add new sequence meta associations or update base data for existing ones
    insert_sequence_meta(
      objects: $sequence_meta_list
      on_conflict: { constraint: sequence_meta_pkey, update_columns: [value] }
    ) {
      affected_rows
    }
  }
`

const GC_GET_COURSE_TYPES = gql`
  query GetCourseTypes {
    course_type {
      id
      name
      slug
    }
  }
`

const GC_GET_COURSE_LOGS = gql`
  query GetCourseLogs {
    app_log(where: { type: { slug: { _eq: "course" } } }) {
      id
      created_at
      data_key
      data
    }
  }
`

const SEARCH_SCENARIO = gql`
  query SearchScenario($identifier: String, $slugs: [String!]!) {
    scenario(
      where: { identifier: { _ilike: $identifier }, type: { slug: { _in: $slugs } } }
      limit: 10
    ) {
      id
      identifier
      scenario_type_id
    }
  }
`

const GC_DELETE_COURSE = gql`
  mutation DeleteCourse($id: uuid!) {
    delete_course_by_pk(id: $id) {
      id
      identifier
    }
  }
`

const GC_CHECK_NAME = gql`
  query CheckName($identifier: String) {
    course(where: { identifier: { _eq: $identifier } }) {
      id
    }
  }
`

export {
  GC_GET_COURSE_LIST,
  GC_ADD_COURSE_ONE,
  GC_GET_COURSE_TYPES,
  GC_GET_COURSE,
  GC_GET_COURSE_ID_BY_TYPE,
  GC_GET_COURSE_BY_TYPE,
  SEARCH_SCENARIO,
  GC_DELETE_COURSE,
  GC_UPDATE_COURSE,
  GC_CHECK_NAME,
  GC_GET_COURSE_LOGS,
}
