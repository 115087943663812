<template>
  <div class="grid grid-cols-2 gap-x-8">
    <List
      listing="activity"
      :fields="fields"
      :where-filter="whereFilter"
      searchText="Rechercher un cours"
      editRouteName="activity-edit"
      read-only
      defaultSortBy="last_comment_date"
      :defaultSortDesc="true"
      @select-item="onSelectItem"
    >
      <template v-slot:cell(unread)="{ item }">
        <div
          v-if="item.unread > 0"
          class="bg-red-500 rounded-full p-2 text-sm text-white w-8 h-8 flex items-center justify-center"
        >
          {{ item.unread }}
        </div>
        <!-- <Toggle :checked="item.is_public" :onInput="onPublicClick.bind(null, item)" /> -->
      </template>
    </List>

    <div v-if="selectedCourse">
      <CommentsChat :course="selectedCourse" />
    </div>
  </div>
</template>

<script>
import List from '@/components/List'
import { mapActions } from 'vuex'
import CommentsChat from '@/components/comments/CommentsChat.vue'

export default {
  components: { List, CommentsChat },

  data() {
    return {
      selectedCourse: null,
      fields: [
        {
          key: 'unread',
          label: '',
          nested: 'count',
          show: false,
          visible: false,
          db: `
          unread: comments_aggregate(where: {validated: {_is_null: true}}) {
            aggregate {
              count
            }
          }`,
        },
        {
          key: 'identifier',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'title',
          label: 'Cours',
          sortable: true,
        },
        {
          key: 'last_comment_date',
          label: 'Dern. comm.',
          sortable: true,
          nested: 'created_at',
          db: `
          last_comment_date: comments_aggregate {
            aggregate {
              max {
                created_at
              }
            }
          }`,
        },
      ],
    }
  },

  computed: {
    whereFilter() {
      return `type: { slug: {_eq: "quiz_simple" } }`
    },
  },

  async created() {
    await this.getTypeList()

    this.selectedCourse = Object.values(this.$store.state.Games.items)[0]
  },

  methods: {
    ...mapActions('Games', ['getTypeList']),

    onSelectItem(data) {
      this.selectedCourse = data
    },
  },
}
</script>
