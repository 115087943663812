import {
  GC_GET_CHARACTER_LIST,
  GC_ADD_CHARACTER_ONE,
  GC_UPDATE_CHARACTER_BY_ID,
  GC_DELETE_CHARACTER_ONE,
} from '@/graphql/character'
import apollo from '@/apolloClient'

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_CHARACTER_LIST(state, characters) {
      state.list = characters || []
    },
  },
  actions: {
    async getList({ commit }) {
      const response = await apollo.query({ query: GC_GET_CHARACTER_LIST })

      commit('SET_CHARACTER_LIST', response.data.character)
    },
    async save(ctx, { id, name, job, avatar_path }) {
      // Create or update character
      if (id) {
        // Update remote data
        const response = await apollo.mutate({
          mutation: GC_UPDATE_CHARACTER_BY_ID,
          variables: {
            id,
            name,
            job,
            avatar_path,
          },
        })

        return response && response.data && response.data.update_character.returning[0]
          ? response.data.update_character.returning[0].id
          : null
      } else {
        // Add remote data
        const response = await apollo.mutate({
          mutation: GC_ADD_CHARACTER_ONE,
          variables: {
            name,
            job,
            avatar_path,
          },
        })

        return response && response.data && response.data.insert_character_one
          ? response.data.insert_character_one.id
          : null
      }
    },
    delete(ctx, id) {
      // Delete from db
      return apollo.mutate({
        mutation: GC_DELETE_CHARACTER_ONE,
        variables: { id },
      })
    },
  },
}
