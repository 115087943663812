const fieldsValues = {
  job: [
    { label: "Gardien.ne d'immeuble", value: 'caretaker' },
    {
      label: 'Responsable de site/secteur',
      labelShort: 'Resp. de site/secteur',
      value: 'manager',
    },
    { label: 'Technicien.ne de patrimoine', value: 'technician' },
    { label: 'Autre', value: 'other' },
  ],
  state: [
    { label: 'Inscrit', value: 'exist' },
    { label: 'En attente', value: 'pending' },
    { label: 'Inactif', value: 'inactive' },
    { label: 'Actif', value: 'active' },
    { label: 'Suspendu', value: 'disabled' },
    { label: 'Promotion suspendu', value: 'group_disabled' },
  ],
  gender: [
    { label: 'Un homme', value: 'h' },
    { label: 'Une femme', value: 'f' },
  ],
  age: [
    { label: '18 - 25 ans', value: '18_25' },
    { label: '26 - 35 ans', value: '26_35' },
    { label: '36 - 45 ans', value: '36_45' },
    { label: '46 - 55 ans', value: '46_55' },
    { label: '56 ans et +', value: '56_et_+' },
  ],
  level_studies: [
    { label: 'Niveau 3 : BEP, CAP...', value: 'niveau_3_bep_cap' },
    {
      label: 'Niveau 4 : Baccalauréat ou équivalent',
      value: 'niveau_4_baccalaureat_equivalent',
    },
    {
      label: 'Niveau 5 : DEUG, BTS, DUT... (Bac + 2)',
      value: 'niveau_5_deug_bts_bac+2',
    },
    {
      label: 'Niveau 6 : Licence, licence professionnelle ( Bac + 3)',
      value: 'niveau_6_licence',
    },
    {
      label: 'Niveau 6 : Master I, Maitrise (Bac + 4)',
      value: 'niveau_6_master_1_maitrise',
    },
    {
      label: 'Niveau 7 : Master II, DEA, DESS, ingénieur (Bac + 5)',
      value: 'niveau_7_master_2_dea_dess',
    },
    {
      label: 'Niveau 8 : Doctorat, habilitation à la recherche (Bac + 8)',
      value: 'niveau_8_doctorat',
    },
    { label: 'Sans diplôme', value: 'sans_diplome' },
  ],
  contract: [
    { label: 'En CDI (Contrat à Durée Indéterminée)', value: 'cdi' },
    { label: 'En CDD (Contrat à Durée Déterminée)', value: 'cdd' },
    { label: 'Intérimaire', value: 'interimaire' },
    {
      label: 'Indépendant, particulier employeur',
      value: 'independant_particulier',
    },
  ],
  experiences: [
    { label: "Moin d'un an", value: 'moin_1_an' },
    { label: 'Entre 1 et 3 ans', value: 'entre_1_3_an' },
    { label: 'Entre 3 et 5 ans', value: 'entre_3_5_an' },
    { label: 'Entre 5 et 10 ans', value: 'entre_5_10_an' },
    { label: 'Plus 10 ans', value: 'plus_10_an' },
    {
      label: 'Indépendant, particulier employeur',
      value: 'independant_particulier',
    },
  ],
}

function formatedFieldsValues() {
  const formatedFieldsValues = {}

  for (const fieldKey in fieldsValues) {
    formatedFieldsValues[fieldKey] = fieldsValues[fieldKey]
    formatedFieldsValues[fieldKey + 'Map'] = fieldsValues[fieldKey].reduce((dict, fieldValue) => {
      dict[fieldValue.value] = fieldValue

      return dict
    }, {})
  }

  return formatedFieldsValues
}

export default formatedFieldsValues()
