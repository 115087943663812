import axios from 'axios'

import { GC_USER_INFOS } from '@/graphql/auth'

import apollo from '@/apolloClient'
import { parseJWT, userStorage } from '@/utils/utils'

const authURL = process.env.VUE_APP_API_URL + '/auth'

let pendingRefreshTokenRequest = null

const validateRole = (role, userTokenPayload) => {
  // Check user role
  const allowedRoles = ['superadmin', 'manager']

  if (allowedRoles.indexOf(role.trim()) < 0) {
    return false
  } else {
    // Check against JWT if possible
    const hasuraClaims =
      userTokenPayload &&
      userTokenPayload['https://hasura.io/jwt/claims'] &&
      JSON.parse(userTokenPayload['https://hasura.io/jwt/claims'])
    const hasuraDefaultRole = hasuraClaims && hasuraClaims['x-hasura-default-role']

    if (hasuraDefaultRole && hasuraDefaultRole !== role) {
      return false
    }
  }

  return true
}

export default {
  namespaced: true,
  state: {
    user: userStorage.get(),
    userInfo: null,
  },
  getters: {
    userToken(state) {
      return state.user && state.user.id ? state.user.id.token : null
    },
    isLoggedIn(state, getters) {
      return getters.userToken ? true : false
    },
  },
  mutations: {
    SET_USER(state, data) {
      state.user = {
        id: data.id,
        access: data.access,
      }
      userStorage.set(state.user)
    },
    SET_USER_INFO(state, data) {
      state.userInfo = data
    },
    CLEAR_USER(state) {
      state.user = null
    },
    CLEAR_USER_INFO(state) {
      state.userInfo = null
    },
  },
  actions: {
    async login({ commit }, data) {
      let user = {
        username: data.email,
        password: data.password,
        remember: data.remember,
      }

      // Try to authenticate user
      const auth = await axios.post(authURL + '/authenticate', user, { withCredentials: true })

      // Update user data
      commit('SET_USER', auth.data)
    },
    async loginWthToken({ commit }, { token, expires }) {
      const payload = parseJWT(token)

      if (!payload) throw Error('invalid token')

      // Update user data
      commit('SET_USER', {
        id: {
          token,
          expires,
        },
        access: {},
      })
    },

    async forgotPassword(_, { email }) {
      await axios.post(authURL + '/reset', { username: email })
    },

    async getCurrentUserInfo({ getters, commit, dispatch, state }) {
      if (!getters.userToken) return

      const payload = parseJWT(getters.userToken)
      const cognitoUserID = payload ? payload['cognito:username'] : null

      if (!cognitoUserID) {
        await dispatch('logout')
        return
      }

      try {
        const response = await apollo.query({
          query: GC_USER_INFOS,
          variables: {
            cognito_id: cognitoUserID,
          },
        })

        commit('SET_USER_INFO', response.data.user[0])

        // Check info and role validity
        if (!state.userInfo || !validateRole(state.userInfo.role, payload)) {
          await dispatch('logout')
          return
        }

        await dispatch('Utils/initAll', {}, { root: true })
      } catch (err) {
        await dispatch('logout')
      }
    },
    async refreshToken({ getters, state, commit }) {
      // Handle concurent call while waiting for a new token
      if (pendingRefreshTokenRequest) {
        // Wait on the same promise as other concurent call
        return await pendingRefreshTokenRequest
      }

      // No token
      if (!getters.userToken) return false

      const payload = parseJWT(getters.userToken)

      // No valid payload
      if (!payload) return false

      // If the token is expired try to refresh it
      let now = Date.now() / 1000
      if (payload && payload.exp < now) {
        // Create a new singleton promise to handle concurent request
        pendingRefreshTokenRequest = axios
          .post(authURL + '/authenticate', null, { withCredentials: true })
          .then((auth) => {
            // Update user data
            commit('SET_USER', auth.data)

            // Check role validity if possible
            if (state.userInfo && !validateRole(state.userInfo.role, parseJWT(getters.userToken))) {
              return false
            }

            return true
          })
          .catch(() => {
            // Refresh failed
            return false
          })

        const success = await pendingRefreshTokenRequest

        pendingRefreshTokenRequest = null

        return success
      }

      // No need to refresh the token
      return true
    },
    async logout({ commit, state }) {
      const token = state.user && state.user.access ? state.user.access.token : null

      commit('CLEAR_USER')
      commit('CLEAR_USER_INFO')

      userStorage.clear()

      if (token) {
        try {
          await axios.post(authURL + '/leave', null, {
            headers: {
              authorization: `Bearer ${token}`,
            },
          })
        } catch (error) {
          return false
        }
      }

      return true
    },
  },
}
