<template>
  <div class="plannings">
    <List
      ref="list"
      listing="planning"
      :fields="fields"
      modify-button-text="Éditer le planning"
      :with-delete-button="false"
      :searchText="'Rechercher un planning'"
      editRouteName="planning-edit"
      order-by="name: asc"
    >
      <template v-slot:buttons>
        <div class="md:flex flex-row justify-around hidden md:w-1/3">
          <button
            v-if="isSuperAdmin"
            type="button"
            class="twn-button secondary whitespace-nowrap overflow-hidden text-xs mr-2"
            @click="onAddPlanningClick"
          >
            Créer un planning
          </button>
        </div>
      </template>
    </List>
  </div>
</template>

<script>
import List from '@/components/List'
import { mapActions, mapState } from 'vuex'
import dispatchStoreRequest from '@/mixins/dispatchStoreRequest'

export default {
  name: 'PlanningList',
  components: { List },
  mixins: [dispatchStoreRequest],
  props: ['itemID'],
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Nom',
          sortable: true,
        },
      ],
    }
  },

  computed: {
    ...mapState('Auth', ['userInfo']),
    isSuperAdmin() {
      return this.userInfo && this.userInfo.role == 'superadmin'
    },
  },

  methods: {
    ...mapActions('EditItem', ['clearCurrentID']),
    onAddPlanningClick() {
      this.clearCurrentID()
      this.$router.push({ name: 'planning-create' })
    },
  },
}
</script>
