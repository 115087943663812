<template>
  <div
    class="header px-10 text-xl font-principal text-principal-gray bg-gray-light h-32 font-medium flex-initial"
  >
    <div class="mobile-header">
      <b-icon-list @click="toggleNav($event)" class="h1 mb-0"></b-icon-list>
      <div>Tableau de bord</div>
    </div>
    <div class="desktop-header pt-8">
      <div class="flex flex-col gap-4">
        <h1 class="text-blue h1 mb-0 flex justify-start">
          {{ title }}
        </h1>
        <Breadcrumb :crumbs="crumbs"> </Breadcrumb>
      </div>
      <div class="flex flex-col gap-4">
        <div class="flex justify-end text-principal-gray text-sm">
          {{ `${firstName} ${lastName}` }}
        </div>
        <div @click="logout" class="cursor-pointer text-pink text-sm flex justify-end items-center">
          <img class="logout h-3 mr-1" src="@/assets/images/POWER.svg" />
          Déconnexion
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Breadcrumb from './Breadcrumb.vue'

export default {
  components: { Breadcrumb },
  props: {
    crumbs: {
      type: Array,
      required: true,
    },
  },
  name: 'Header',
  computed: {
    ...mapState({
      firstName: (state) => state.Auth.userInfo?.first_name,
      lastName: (state) => state.Auth.userInfo?.last_name,
    }),
    title() {
      if (this.$route.meta.titleFn) {
        return this.$route.meta.titleFn(this.$route, this.$store)
      }
      return this.$route.meta.title
    },
  },
  methods: {
    ...mapActions('Header', ['toggleNav']),
    async logout() {
      try {
        await this.$store.dispatch('Auth/logout')
        await this.$router.push({ name: 'auth' })
      } catch (error) {
        location.reload()
      }
    },
  },
}
</script>

<style lang="scss">
.header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.header > div {
  @apply flex-row justify-between items-center h-full;
  &.desktop-header {
    @apply hidden;
    @screen md {
      @apply flex;
    }
  }
  &.mobile-header {
    @apply flex;
    @screen md {
      @apply hidden;
    }
  }

  .logout {
    margin-bottom: 5px;
  }
}
</style>
