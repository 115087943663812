import {
  GC_GET_EMBED_LIST,
  GC_ADD_EMBED_ONE,
  GC_UPDATE_EMBED_BY_ID,
  GC_DELETE_EMBED_ONE,
} from '@/graphql/embed'
import apollo from '@/apolloClient'

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_EMBED_LIST(state, embeds) {
      state.list = embeds || []
    },
  },
  actions: {
    async getList({ commit }) {
      const response = await apollo.query({ query: GC_GET_EMBED_LIST })

      commit('SET_EMBED_LIST', response.data.embed)
    },
    async save(ctx, { id, url }) {
      // Create or update embed
      if (id) {
        // Update remote data
        const response = await apollo.mutate({
          mutation: GC_UPDATE_EMBED_BY_ID,
          variables: {
            id,
            url,
          },
        })

        return response && response.data && response.data.update_embed.returning[0]
          ? response.data.update_embed.returning[0].id
          : null
      } else {
        // Add remote data
        const response = await apollo.mutate({
          mutation: GC_ADD_EMBED_ONE,
          variables: {
            url,
          },
        })

        return response && response.data && response.data.insert_embed_one
          ? response.data.insert_embed_one.id
          : null
      }
    },
    delete(ctx, id) {
      // Delete from db
      return apollo.mutate({
        mutation: GC_DELETE_EMBED_ONE,
        variables: { id },
      })
    },
  },
}
