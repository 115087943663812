<template>
  <div>
    <cooc-list
      v-if="!isLoading"
      listing="user"
      :fields="fields"
      :filters="filters"
      searchText="Suggestions de recherche..."
      :with-delete-button="false"
      modify-button-text="Éditer la fiche étudiant"
    >
      <template v-slot:buttons>
        <div v-if="!isReadOnly" class="flex flex-row justify-between user-buttons w-full">
          <a
            class="flex items-center italic text-blue hover:text-gray text-xs underline mr-2"
            @click="exportUserList"
            href="#"
          >
            Télécharger le modèle CSV
            <img src="@/assets/images/DOWNLOAD.svg" alt="download icon" class="ml-1" />
          </a>
          <a ref="fileDownloadLink" download="etudiants" hidden></a>
          <div class="">
            <button class="twn-button mr-4" @click="$refs.fileInput.click()">Import CSV</button>
            <input ref="fileInput" type="file" @change="importUserList" hidden />
            <button class="twn-button secondary" @click="addUser" type="secondary">
              Ajouter un étudiant
            </button>
          </div>
        </div>
      </template>
      <template v-slot:fab>
        <vue-fab
          class="md:hidden"
          fabItemAnimate="alive"
          fabAliveAnimateBezier="ease-in-out"
          mainBtnColor="#fff"
          size="big"
        >
          <fab-item
            class="twn-fab import"
            @clickItem="$refs.fileInput.click()"
            color="#888888"
            :idx="2"
            title="Importer des étudiant"
            icon="add"
          />
          <fab-item
            class="twn-fab add"
            @clickItem="addUser"
            color="#DC9799"
            :idx="0.5"
            title="Ajouter un étudiant"
            icon="add"
          />
        </vue-fab>
      </template>
    </cooc-list>

    <b-modal
      v-if="!isReadOnly"
      ref="import-modal"
      class=""
      centered
      hide-footer
      id="import-modal"
      hide-header
      :no-close-on-backdrop="noClose"
      :no-close-on-esc="noClose"
    >
      <div class="d-block text-center my-6 uppercase font-semibold">
        <h3>Import CSV</h3>
      </div>

      <div class="flex-col gap-4">
        <p class="mb-4">
          Veuillez définir des valeurs par défaut à appliquer à la liste d'utilisateurs
        </p>

        <div class="input-group mb-2">
          <label for="user-promotion">Programme *</label>

          <v-select
            id="user-promotion"
            class="twn-select w-full"
            placeholder="Choisir un programme..."
            :options="promotionList"
            :reduce="(course) => course.id"
            :getOptionLabel="(option) => `${option.identifier} - ${option.name}`"
            v-model="userImportDefault.group"
            :class="{ invalid: !userImportDefault.group }"
          />
        </div>

        <div class="flex-col mb-2">
          <label class="text-sm">Début d'abonnement</label>
          <b-form-input
            :value="formatDate(userImportDefault.subscription_start_date)"
            @input="userImportDefault.subscription_start_date = $event"
            type="date"
          />
        </div>

        <div class="flex-col mb-2">
          <label class="text-sm">Fin d'abonnement</label>
          <b-form-input
            :value="formatDate(userImportDefault.subscription_end_date)"
            @input="userImportDefault.subscription_end_date = $event"
            type="date"
          />
        </div>

        <ul v-if="alreadyRegisterdUsers && alreadyRegisterdUsers.length" class="mt-4">
          <div class="mb-3">Certains utilisateurs sont déjà inscrit, ils seront ignorés :</div>
          <li v-for="user in alreadyRegisterdUsers" :key="user.id" class="ml-3 list-disc">
            {{ user.last_name }} {{ user.first_name }} ({{ user.email }})
          </li>
        </ul>
      </div>

      <div v-if="importFeedback" class="bg-warning p-2 mt-4 font-bold">
        {{
          importFeedback === 'loading'
            ? "Merci de patienter pendant l'importation"
            : importFeedback === 'finished'
            ? 'Importation terminée !'
            : ''
        }}
      </div>

      <div class="flex flex-row justify-evenly items-center">
        <button
          type="button"
          class="mt-4 twn-button"
          @click="onImportClose"
          :disabled="importFeedback === 'loading'"
        >
          {{ importFeedback === 'finished' ? 'Fermer' : 'Retour' }}
        </button>
        <button
          v-if="importFeedback !== 'finished'"
          type="button"
          class="mt-4 twn-button danger"
          @click="saveImportedUserList"
          :disabled="importFeedback === 'loading' || !userImportDefault.group"
        >
          Importer
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

import dispatchStoreRequest from '@/mixins/dispatchStoreRequest'
import List from '@/components/List'

export default {
  name: 'UserList',
  mixins: [dispatchStoreRequest],
  data() {
    return {
      userLabels: {
        user: 'Étudiant',
        manager: 'Professeur',
        superadmin: 'Administrateur',
      },
      usersToImport: null,
      alreadyRegisterdUsers: null,
      userImportDefault: {
        group: null,
        subscription_start_date: '',
        subscription_end_date: '',
      },
      importFeedback: '',
      isLoading: true,
      fields: [
        {
          key: 'last_name',
          label: 'Nom',
          sortable: true,
        },
        {
          key: 'first_name',
          label: 'Prénom',
          sortable: true,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
        },
        {
          key: 'groups',
          label: 'programme',
          sortable: true,
          nested: 'group_name',
          db: `groups {
            group {
              group_name: name
            }
          }`,
        },
        {
          key: 'subscription_start_date',
          label: 'Abonnement',
          db: `subscription_start_date, subscription_end_date, planning_id`,
          sortable: true,
          formatter: (value, key, data) => {
            if (data.subscription_start_date && data.subscription_end_date) {
              return `${this.formatDate(data.subscription_start_date)} - ${this.formatDate(
                data.subscription_end_date
              )}`
            }
          },
        },
        {
          key: 'role',
          label: 'Rôle',
          sortable: true,
          formatter: (value, key, data) => {
            return this.userLabels[data.role]
          },
        },
      ],
    }
  },
  props: {
    previousPage: {
      type: Number,
      required: false,
      default: null,
    },
  },
  components: { 'cooc-list': List },
  computed: {
    ...mapState('Auth', ['userInfo']),
    ...mapState({
      promotionList(state) {
        return state.Promotion.list
      },
      planningList(state) {
        return state.Planning.list
      },
    }),
    isSuperAdmin() {
      return this.userInfo && this.userInfo.role == 'superadmin'
    },
    isReadOnly() {
      return !this.isSuperAdmin
    },
    filters() {
      if (this.isSuperAdmin) {
        return [
          {
            key: 'role',
            label: 'Rôle',
            labels: this.userLabels,
          },
          {
            key: 'planning_id',
            label: 'Planning',
            labels: Object.fromEntries(
              this.planningList.map((planning) => [planning.id, planning.name])
            ),
          },
        ]
      }

      return []
    },
    noClose() {
      return this.importFeedback === 'loading'
    },
  },
  async mounted() {
    this.dispatchStoreRequest('Promotion/getList')
    this.dispatchStoreRequest('Planning/getList')
    this.isLoading = false
  },
  methods: {
    ...mapActions('EditItem', ['clearCurrentID']),

    formatDate(date) {
      return moment(new Date(date)).locale('fr').format('YYYY-MM-DD')
    },

    addUser() {
      this.clearCurrentID()
      this.$router.push({ name: 'users-create' })
    },

    importUserList(e) {
      let fr = new FileReader()
      fr.onload = async (re) => {
        try {
          const emails = (await this.dispatchStoreRequest('Users/getList')).map(
            (user) => user.email
          )
          const data = this.formatCSV(re.target.result)
          this.usersToImport = data.filter((user) => !emails.includes(user.email))
          this.alreadyRegisterdUsers = data.filter((user) => emails.includes(user.email))
          this.$refs['import-modal'].show()
        } catch (e) {
          // DISPLAY TOAST ERROR
          this.$bvToast.toast(
            `Une erreur est survenue en lisant le fichier CSV. ${e.message} Impossible d'importer les utilisateurs`,
            { title: "Erreur d'importation", solid: true, autoHideDelay: 5000 }
          )
        }
      }

      fr.readAsText(e.target.files[0])
    },

    formatCSV(rawCSV) {
      const defaultValues = {
        phone: '',
        free: false,
      }
      const separator = rawCSV.includes(';') ? ';' : ','
      let [cols, ...rows] = rawCSV
        .split(/\r\n|\n/)
        .filter(Boolean)
        .map((line) =>
          line.split(separator).map((w) => {
            w = w.trim()
            if (w) {
              if (w.toLowerCase() === 'gratuit') return true
              if (w.toLowerCase() === 'payant') return false
            }
            return w !== '' ? w : null
          })
        )
      const colNames = ['last_name', 'first_name', 'email', 'number', 'phone', 'abonnement']
      const formEq = ['last_name', 'first_name', 'email', 'number', 'phone', 'free']
      if (!colNames.every((name, i) => cols[i] === name)) {
        throw new Error('Les colonnes du tableau ne correspondent pas au modèle.')
      }

      // Basic pre validation
      rows.forEach((row, ri) => {
        colNames.forEach((key, i) => {
          if (['last_name', 'first_name', 'email', 'number'].includes(key) && row[i] === null) {
            throw new Error(`Information manquante dans la colonne "${key}", rangée ${ri + 1}.`)
          }
        })
      })

      return rows.map((row) => {
        return Object.fromEntries(row.map((data, i) => [formEq[i], data ?? defaultValues[cols[i]]]))
      })
    },

    async saveImportedUserList() {
      this.isLoading = true
      this.importFeedback = 'loading'
      const defValues = Object.fromEntries(
        Object.entries(this.userImportDefault).filter(([key, value]) => !!value)
      )

      const users = this.usersToImport.map((user) => {
        Object.keys(defValues).forEach((key) => {
          user[key] = defValues[key]
        })
        user.role = 'user'
        user.disabled = false
        user.name = user.last_name
        user.metas = []
        user.free = user.free ?? false
        delete user.last_name
        return user
      })

      for (let user of users) {
        await this.dispatchStoreRequest('Users/save', {
          user,
        })
      }

      this.importFeedback = 'finished'
      this.isLoading = false
    },

    onImportClose() {
      this.importFeedback = ''
      this.usersToImport = null
      this.alreadyRegisterdUsers = null
      this.$refs['import-modal'].hide()
    },

    exportUserList() {
      this.dispatchStoreRequest('Users/getList').then((users) => {
        const columns = [
          'last_name',
          'first_name',
          'email',
          'number',
          'phone',
          'role',
          'subscription_end_date',
          'subscription_start_date',
          'disabled',
          'free',
          'program',
          'program_id',
          'planning',
          'planning_id',
          'created_at',
          'id',
        ]
        const planningNames = Object.fromEntries(
          this.planningList.map((planning) => [planning.id, planning.name])
        )
        const programNames = Object.fromEntries(
          this.promotionList.map((promotion) => [promotion.id, promotion.name])
        )
        const rows = users.map((user) => {
          return columns.map((key) => {
            if (key.includes('program')) {
              const programId = user['group']?.[0]?.id
              if (!programId) return ''
              if (key === 'program') return programNames[programId]
              return programId
            }
            if (key.includes('planning')) {
              const planningId = user.planning_id
              if (!planningId) return ''
              if (key === 'planning') return planningNames[planningId]
              return planningId
            }
            return user[key] === null ? '' : user[key]
          })
        })
        rows.unshift(columns)
        const data = URL.createObjectURL(new Blob([rows.join('\n')], { type: 'text/csv' }))
        var link = this.$refs['fileDownloadLink']
        link.setAttribute('href', data)
        link.click()
        link.removeAttribute('href')
        URL.revokeObjectURL(data)
      })
    },
  },
}
</script>
