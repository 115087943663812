import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Modules
import Auth from './authentication'
import Header from './header'
import List from './list'
import EditItem from './edit'
import Assets from './assets'
import Logs from './logs'
import Utils from './utils'
import Games from './game'
import Locations from './location'
import Scenarios from './scenario'
import Nodes from './node'
import Media from './media'
import Triggers from './trigger'
import Proposals from './proposal'
import VideoParts from './video-part'
import Modals from './modal'
import Course from './course'
import Characters from './character'
import Messages from './message'
import Entity from './entity'
import Promotion from './promotion'
import Embeds from './embed'
import Users from './user'
import Narratives from './narrative'
import Customers from './customer'
import Page from './page'
import Planning from './planning'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ['EditItem.currentID', 'Games'],
    }),
  ],
  modules: {
    Utils,
    Auth,
    Logs,
    Header,
    List,
    EditItem,
    Assets,
    Games,
    Locations,
    Scenarios,
    Nodes,
    Media,
    Course,
    Triggers,
    Proposals,
    VideoParts,
    Modals,
    Characters,
    Messages,
    Entity,
    Promotion,
    Embeds,
    Users,
    Narratives,
    Customers,
    Page,
    Planning,
  },
})
