import gql from 'graphql-tag'

const GC_GET_PAGE_LIST = gql`
  query GetPageList {
    page {
      id
      content
      medias {
        id
        path
        name: identifier
        title
        type {
          slug
        }
      }
    }
  }
`

const GC_ADD_PAGE_ONE = gql`
  mutation AddPage($content: String!) {
    insert_page_one(object: { content: $content }) {
      id
    }
  }
`

const GC_UPDATE_PAGE = gql`
  mutation UpdatePage($id: uuid!, $content: String!) {
    update_page_by_pk(pk_columns: { id: $id }, _set: { content: $content }) {
      id
    }
  }
`

export { GC_GET_PAGE_LIST, GC_ADD_PAGE_ONE, GC_UPDATE_PAGE }
