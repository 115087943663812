<template>
  <div class="flex breadcrumb p-0">
    <div class="breadcrumb-item" v-for="crumb in crumbs" :key="crumb.text">
      {{ crumb.text }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'Breadcrumb',
  props: {
    crumbs: Array,
  },
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  background-color: #fcfcfc;
  .breadcrumb-item {
    @apply uppercase text-sm text-principal-list-item;
    &.active {
      font-weight: 700;
    }
  }

  .breadcrumb-item + .breadcrumb-item::before {
    content: '>';
  }
}
</style>
