import gql from 'graphql-tag'

const GC_GET_USER_LIST = gql`
  query GetUserList {
    user {
      last_name
      first_name
      id
      number
      email
      phone
      role
      subscription_end_date
      subscription_start_date
      disabled
      created_at
      free
      group: groups {
        id: group_id
      }
      planning_id
      details
    }
  }
`

const GC_GET_USER_BY_ID = gql`
  query UserByID($id: uuid!) {
    user_by_pk(id: $id) {
      id
      name: last_name
      first_name
      number
      email
      role
      phone
      disabled
      cognito_id
      cognito_confirmed
      subscription_start_date
      subscription_end_date
      free
      planning_id
      details

      metas {
        id
        value
        meta_type_id
      }
      group: groups {
        id: group_id
      }
      content_logs(order_by: { created_at: desc }) {
        created_at
        user {
          name: last_name
          first_name
        }
      }
    }
  }
`

const GC_ADD_USER_ONE = (user) => {
  return gql`
    mutation AddUser(
      $name: String!
      $first_name: String!
      $number: String!
      $email: String!
      $role: String!
      $disabled: Boolean!
      $cognito_id: String
      $metas: [user_meta_insert_input!]!
      $subscription_start_date: timestamp
      $subscription_end_date: timestamp
      $phone: String
      $free: Boolean!
      $group: uuid!
      $planning_id: uuid
      $details: String
    ) {
      insert_user_one(
        object: {
          last_name: $name
          first_name: $first_name
          number: $number
          email: $email
          role: $role
          disabled: $disabled
          cognito_id: $cognito_id
          subscription_start_date: $subscription_start_date
          subscription_end_date: $subscription_end_date
          phone: $phone
          free: $free
          metas: { data: $metas }
          groups: { data: { group_id: $group } }
          planning_id: $planning_id
          details: $details
        }
      ) {
        id
      }
    }
  `
}

const GC_UPDATE_USER_BY_ID = gql`
  mutation UpdateUser(
    $id: uuid!
    $name: String!
    $first_name: String!
    $number: String!
    $role: String!
    $disabled: Boolean!
    $cognito_id: String
    $metas: [user_meta_insert_input!]!
    $subscription_start_date: timestamp
    $subscription_end_date: timestamp
    $phone: String
    $free: Boolean
    $group: uuid!
    $planning_id: uuid
    $details: String
  ) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: {
        last_name: $name
        first_name: $first_name
        number: $number
        role: $role
        disabled: $disabled
        subscription_start_date: $subscription_start_date
        subscription_end_date: $subscription_end_date
        phone: $phone
        free: $free
        cognito_id: $cognito_id
        planning_id: $planning_id
        details: $details
      }
    ) {
      id
    }

    delete_user_meta(where: { user_id: { _eq: $id } }) {
      affected_rows
    }

    insert_user_meta(objects: $metas) {
      affected_rows
    }

    delete_group_user(where: { user_id: { _eq: $id } }) {
      affected_rows
    }

    insert_group_user(objects: [{ group_id: $group, user_id: $id }]) {
      affected_rows
    }
  }
`

const GC_DELETE_USER_BY_ID = gql`
  mutation DeleteUser($id: uuid!) {
    delete_user_by_pk(id: $id) {
      id
    }
  }
`

export {
  GC_GET_USER_LIST,
  GC_GET_USER_BY_ID,
  GC_ADD_USER_ONE,
  GC_UPDATE_USER_BY_ID,
  GC_DELETE_USER_BY_ID,
}
