<template>
  <div class="user-detail mt-8 md:mt-0 px-8">
    <div class="buttons flex flex-row justify-around md:justify-between">
      <button class="twn-button" type="button" @click="goBack">Retour</button>
      <div v-if="user && user.id" class="flex items-center">
        <div class="inline-block mr-4">
          <span :class="!user.disabled ? 'text-blue' : 'text-gray-disabled'">Compte actif</span>
          <Toggle
            :disabled="isReadOnly || hasPendingStoreRequest"
            :checked="user.disabled"
            :onInput="
              () => {
                user.disabled = !user.disabled
              }
            "
            class="mx-4"
          />
          <span :class="user.disabled ? 'text-gray-disabled' : 'text-blue'">Compte suspendu</span>
        </div>
        <button
          v-if="!isReadOnly"
          type="button"
          class="twn-button danger mr-4"
          :disabled="hasPendingStoreRequest"
          @click="showDeleteModal"
        >
          Supprimer
        </button>
        <button
          v-if="!isReadOnly"
          type="button"
          class="twn-button secondary"
          :disabled="!formIsValid"
          @click="onSave"
        >
          Enregistrer
        </button>
      </div>
      <div v-else-if="!isReadOnly">
        <button type="submit" class="twn-button text-sm" :disabled="!formIsValid" @click="save">
          Ajouter et envoyer l'email de confirmation
        </button>
      </div>
    </div>
    <div class="flex flex-col" v-if="user">
      <div class="user-card my-6">
        <div class="flex justify-between items-center">
          <div class="uppercase font-principal-bold text-sm" v-if="!user.id">Ajouter un profil</div>
          <div class="text-blue capitalize font-light text-xl" v-else>
            {{ `${user.first_name} ${user.name}` }}
          </div>

          <button
            v-if="!isReadOnly && user.id && !isUserValidated"
            type="button"
            class="twn-button text-xs"
            @click="sendValidationCode"
            :disabled="isUserValidated"
          >
            Envoyer un code d'inscription
          </button>
        </div>
        <form @submit="save">
          <div class="flex flex-row flex-wrap justify-around">
            <div class="left">
              <div class="input-group">
                <label for="first_name">Prénom *</label>
                <b-form-input
                  :disabled="isReadOnly"
                  v-model="user.first_name"
                  type="text"
                  id="first_name"
                  :state="nameValidation"
                ></b-form-input>
              </div>
              <div class="input-group">
                <label for="name">Nom *</label>
                <b-form-input
                  :disabled="isReadOnly"
                  v-model="user.name"
                  type="text"
                  id="name"
                  :state="nameValidation"
                ></b-form-input>
              </div>
              <div class="input-group">
                <label for="number">Numéro étudiant *</label>
                <b-form-input
                  :disabled="isReadOnly"
                  v-model="user.number"
                  type="text"
                  id="number"
                  :state="isDefined(user.number)"
                ></b-form-input>
              </div>
              <div class="input-group">
                <label for="email">Email *</label>
                <b-form-input
                  v-model="user.email"
                  type="email"
                  id="email"
                  :state="emailValidation"
                  :readonly="user.id || isReadOnly ? true : false"
                  :disabled="user.id || isReadOnly ? true : false"
                ></b-form-input>
              </div>
            </div>
            <div class="right">
              <div v-if="!isReadOnly" class="input-group">
                <label for="user-role">Rôle *</label>

                <v-select
                  id="user-role"
                  class="twn-select"
                  label="label"
                  placeholder="Selectionner un rôle..."
                  :reduce="(course) => course.value"
                  :options="roleList"
                  v-model="user.role"
                  :class="{ invalid: !user.role }"
                />
              </div>

              <div class="input-group">
                <label for="user-planning">Planning</label>

                <v-select
                  id="user-planning"
                  class="twn-select"
                  label="label"
                  placeholder="Selectionner un planning..."
                  :options="planningList"
                  :reduce="(planning) => planning.id"
                  :getOptionLabel="(planning) => planning.name"
                  v-model="user.planning_id"
                />
              </div>

              <div class="input-group">
                <label for="email">Téléphone</label>
                <b-form-input v-model="user.phone" type="number" id="phone"></b-form-input>
              </div>

              <!-- Courses -->
              <div v-if="!isReadOnly" class="input-group">
                <label for="user-promotion">Programme *</label>

                <!-- Add input -->
                <v-select
                  id="user-promotion"
                  class="twn-select"
                  placeholder="Ajouter un programme..."
                  :options="promotionList"
                  :reduce="(course) => course.id"
                  :getOptionLabel="getPromotionLabel"
                  v-model="promotion"
                  :class="{ invalid: !promotion }"
                />
              </div>

              <div class="flex space-x-2">
                <div class="flex-col">
                  <label class="text-sm">Début d'abonnement</label>
                  <b-form-input
                    :value="formatDate(user.subscription_start_date)"
                    @input="user.subscription_start_date = $event"
                    type="date"
                  />
                </div>

                <div class="flex-col">
                  <label class="text-sm">Fin d'abonnement</label>
                  <b-form-input
                    :value="formatDate(user.subscription_end_date)"
                    @input="user.subscription_end_date = $event"
                    type="date"
                  />
                </div>
              </div>
              <div class="inline-block mr-4">
                <label
                  for="user-free"
                  :class="!user.free ? 'text-blue' : 'text-gray-disabled'"
                  :aria-hidden="user.free"
                >
                  Abonné payant
                </label>
                <Toggle
                  id="user-free"
                  name="user-free"
                  :disabled="isReadOnly || hasPendingStoreRequest"
                  :checked="user.free"
                  :onInput="() => (user.free = !user.free)"
                  class="mx-3"
                />
                <label
                  for="user-free"
                  :class="user.free ? 'text-blue' : 'text-gray-disabled'"
                  :aria-hidden="!user.free"
                >
                  Abonné gratuit
                </label>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <label class="text-sm">Informations complémentaires</label>
            <TextEditor v-model="user.details" all-features no-medias />
          </div>
        </form>
      </div>
    </div>

    <Modal modal-id="delete-modal-detail-user" @onConfirm="deleteItem" action-btn-text="Supprimer">
      Voulez-vous supprimer cet étudiant ? Vous pouvez également suspendre son compte.
    </Modal>

    <Modal v-if="user && user.id" modal-id="subscription-modal-detail-user" @onConfirm="save">
      Êtes-vous sûr de vouloir changer le status d'abonnement de cet étudiant à
      {{ user.free ? 'gratuit' : 'payant' }} ?
    </Modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import dispatchStoreRequest from '@/mixins/dispatchStoreRequest'
import userFieldsValues from '@/constants/userFieldsValues'
import Toggle from '@/components/Toggle'
import Modal from '../components/base/Modal.vue'
import TextEditor from '@/components/base/TextEditor.vue'

export default {
  name: 'UserDetail',
  mixins: [dispatchStoreRequest],
  props: {
    itemID: {
      type: String,
      required: false,
    },
    previousPage: {
      type: Number,
      required: false,
    },
  },
  components: {
    Toggle,
    Modal,
    TextEditor,
  },
  data() {
    return {
      showSubscribeModal: false,
      selectedPromotion: null,
      user: null,
      hasGroup: false,
      isUserValidated: true,
      userFieldsValues,
      promotionArrFields: [
        {
          key: 'index',
          label: '',
          sortable: false,
        },
        {
          key: 'title',
          label: 'Promotions associées',
          sortable: false,
        },
        {
          key: 'start_date',
          label: 'Date de début',
          sortable: false,
        },
        {
          key: 'end_date',
          label: 'Date de fin',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('Auth', ['userInfo']),
    ...mapState('Utils', ['organisationNameList', 'statusNameList', 'userList']),
    ...mapState({
      promotionList(state) {
        return state.Promotion.list
      },
      planningList(state) {
        return state.Planning.list
      },
      metaTypes: (state) => {
        return state.Utils.metaTypeList.reduce((dict, meta) => {
          dict[meta.slug] = meta
          return dict
        }, {})
      },
    }),
    promotion: {
      get() {
        return this.user.group
      },
      set(value) {
        this.user.group = value
      },
    },
    isSuperAdmin() {
      return this.userInfo && this.userInfo.role == 'superadmin'
    },
    isReadOnly() {
      return !this.isSuperAdmin
    },
    nameValidation() {
      return this.user.name !== undefined && this.user.name !== null && this.user.name !== ''
    },
    emailValidation() {
      const valid =
        this.user.email !== undefined && this.user.email !== null && this.user.email !== ''

      // Check if email exist when creating an user
      if (!this.user || !this.user.id) {
        return (
          valid &&
          !this.userList.find((user) => {
            return user.email == this.user.email
          })
        )
      }

      return valid
    },
    formIsValid() {
      if (!this.user) return false
      return (
        this.nameValidation &&
        this.emailValidation &&
        !!this.user.number &&
        !!this.user.group &&
        !this.hasPendingStoreRequest
      )
    },
    roleList() {
      return [
        { label: 'Étudiant', value: 'user' },
        { label: 'Professeur', value: 'manager' },
        { label: 'Administrateur', value: 'superadmin' },
      ]
    },
  },
  async mounted() {
    await this.dispatchStoreRequest('Promotion/getList')
    await this.dispatchStoreRequest('Planning/getList')
  },
  watch: {
    itemID: {
      async handler(id) {
        // Load current user data, if needed
        if (id) {
          await this.loadData()

          // todo: common/cleaner system
          document.title =
            process.env.VUE_APP_NAME + ' - ' + [this.user.name, this.user.first_name].join(' - ')
        } else {
          // Get default object without cache
          this.user = await this.dispatchStoreRequest('Users/getDefault', null, true)
          // this.hasGroup = false
        }
      },
      immediate: true,
    },
  },
  methods: {
    async loadData() {
      // Get user from store
      // todo: handle invalid uuid response
      await this.dispatchStoreRequest('Users/getByID', this.itemID, true)

      // Create a deep local copy of the store data (todo: really needed ?)
      this.user = JSON.parse(JSON.stringify(this.$store.state.Users.items[this.itemID]))
      this.previousStatus = this.user.free
      // this.hasGroup = (this.user.groups && this.user.groups.length > 0)
    },

    onSave() {
      if (this.previousStatus !== this.user.free) {
        this.$bvModal.show('subscription-modal-detail-user')
      } else {
        this.save()
      }
    },

    goBack() {
      this.$router.push({
        name: 'users-list',
        params: {
          previousPage: this.previousPage,
        },
      })
    },

    showDeleteModal() {
      this.$bvModal.show('delete-modal-detail-user')
    },

    async subscribeUser() {
      // this.user.subscription_end_date = moment().add(1, 'year').format('YYYY-MM-DD')
      // this.user.subscription_start_date = moment().format('YYYY-MM-DD')

      return this.save()
    },

    async deleteItem() {
      if (this.hasPendingStoreRequest) return

      await this.dispatchStoreRequest('Users/delete', this.user)

      this.$router.push({ name: 'users-list' })
    },
    async save(e) {
      e?.preventDefault()

      if (!this.formIsValid) return

      // Cleanup data
      delete this.user.content_logs

      const response = await this.dispatchStoreRequest('Users/save', {
        user: this.user,
      })

      if (response.error) {
        this.$bvToast.toast("Erreur de création de l'étudiant.", {
          title: `Erreur`,
        })
        return
      } else if (response.id) {
        this.$router.push({
          name: 'user-edit',
          params: {
            itemID: response.id,
          },
        })
      } else {
        // Refresh user data
        await this.loadData()
      }

      this.$bvToast.toast('Vos modifications ont bien été enregistrés !', {
        title: `Succès !`,
      })
    },
    async sendValidationCode() {
      if (this.isUserValidated) return

      this.isUserValidated = true

      try {
        await this.$store.dispatch('Auth/sendValidationToken', this.user.email) //todo: email check ? real email ?
        alert('Code envoyer à ' + this.user.email)
      } catch (error) {
        this.isUserValidated = false
      }
    },
    getMeta(slug) {
      if (!this.metaTypes[slug]) return { meta: { value: '~ Meta value error ~' }, index: -1 }

      for (var i = 0; i < this.user.metas.length; i++) {
        if (this.user.metas[i].meta_type_id == this.metaTypes[slug].id) {
          return { meta: this.user.metas[i], index: i }
        }
      }

      return { meta: null, index: -1 }
    },
    getMetaValue(slug, defaultValue = null) {
      const { meta } = this.getMeta(slug)

      return (meta && meta.value) || defaultValue
    },
    setMetaValue(slug, value) {
      // Try to find existing meta with this slug
      let { meta, index } = this.getMeta(slug)

      // Update, add or remove meta value
      if (meta) {
        if (value != null) {
          meta.value = value
        } else {
          this.user.metas.splice(index, 1)
        }
      } else if (value) {
        this.user.metas.push({
          value,
          meta_type_id: this.metaTypes[slug].id,
        })
      }
    },
    getPromotionLabel(option) {
      return `${option.identifier} - ${option.name}`
    },
    formatDate(date) {
      return moment(new Date(date)).locale('fr').format('YYYY-MM-DD')
    },
    isDefined(v) {
      // eslint-disable-next-line
      return !!v ? null : false
    },
  },
}
</script>
<style lang="scss">
.user-card {
  > div {
    @apply my-5;
  }
  .left,
  .right {
    @apply w-full;
    > .input-group {
      @apply flex flex-col my-2 flex-nowrap;
      > label {
        @apply text-xs;
      }
      > input {
        &:not(.is-invalid) {
          @apply border-line-top;
        }
        @apply w-2/3 flex-auto;
        border-radius: 0.275rem !important;
      }
      .twn-select {
        @apply w-2/3;
        .vs__dropdown-toggle {
          @apply border-line-top;
        }
        &.is-invalid {
          .vs__dropdown-toggle {
            border-color: #dc3545;
          }
        }
      }
    }
    @screen xs {
      > div > label {
        @apply text-sm;
      }
    }
  }
  @screen md {
    .left,
    .right {
      > div {
        @apply my-5;
      }
      @apply w-1/2;
      .button-holder {
        @apply flex justify-end items-end;
      }
    }
  }
  hr {
    box-sizing: border-box;
    border: 1px solid;
    @apply border-line-top rounded-lg;
  }

  .user-course {
    @apply w-2/3 flex items-center bg-gray-background mb-2;

    div {
      @apply px-2 py-1 cursor-grab;
    }

    span {
      @apply flex-grow pl-3;
    }

    button {
      @apply px-3 py-1 text-red-700;
    }
  }
}
</style>
