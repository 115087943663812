import {
  GC_GET_GAME_RESULTS,
  GC_GET_SCENARIO_COMPLETION,
  GC_GET_NEW_USERS_PER_WEEK,
  GC_GET_USER_ACTIVITY,
  GC_GET_AVERAGE_CO_TIME,
  GC_GET_FINISHED_USERS,
  GC_GET_LOG_TYPES,
  GC_GET_USERS_PROMOTION,
  GC_INSERT_CONTENT_LOG,
  GC_GET_USERS_TRACKING_DATA,
  GC_GET_COURSES_TRACKING_DATA,
  GC_GET_PROMOTIONS_TRACKING_DATA,
  GC_GET_USER_TRACKING_DATA,
  GC_GET_GROUP_USERS_TRACKING_DATA,
  GC_GET_CUSTOMER_USERS_TRACKING_DATA,
} from '@/graphql/logs'
import apollo from '@/apolloClient'

export default {
  namespaced: true,
  state: {
    scenarios: {},
    revisions: {},
    gameNamesByID: {},
    months: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Aout',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ],
    totalUsers: 0,
    logTypes: [],
  },
  mutations: {
    SET_LOG_TYPES(state, logTypes) {
      state.logTypes = logTypes.reduce((dict, logType) => {
        dict[logType.slug] = logType.id

        return dict
      }, {})
    },
  },
  actions: {
    SetTotalUsers({ state }, total) {
      state.totalUsers = total
    },
    SetScenarios({ state }, scenarios) {
      state.scenarios = scenarios
    },
    SetRevisions({ state }, revisions) {
      state.revisions = revisions
    },
    SetGameNamesByID({ state }, gameNamesByID) {
      state.gameNamesByID = gameNamesByID
    },
    async GetGameResults({ state }, filters) {
      const response = await apollo.query({
        query: GC_GET_GAME_RESULTS,
        variables: {
          company: filters.company,
          from: filters.fromDate,
          to: filters.toDate,
          xp: filters.experienced,
          mailing: filters.mailing,
          course: filters.course,
          other: filters.other,
          seminar: filters.seminar,
          seniority: filters.seniority,
          status: filters.status,
          id: filters.id,
        },
      })
      if (response && response.data.user) {
        let retGameResults = {}
        let retGameResultAverageRaw = {}
        let xlsData = {
          scenario: {},
          game: {},
        }
        let totalResultAverage = 0

        const users = response.data.user
        let averageTryPerGame = {}
        let exercicesPerScenario = {}

        let sumPerUser = {}
        let tryPerUser = {}

        users.forEach((user) => {
          //Sum the score by scenario[user][game]
          user.logs.forEach((log) => {
            let gameName = state.gameNamesByID[log.data_key]
            if (
              state.scenarios[log.data.scenario_id] &&
              gameName &&
              state.scenarios[log.data.scenario_id].stats &&
              state.scenarios[log.data.scenario_id].stats.game
            ) {
              let scenarioID = log.data.scenario_id
              if (!exercicesPerScenario[scenarioID]) {
                exercicesPerScenario[scenarioID] = {}
              }
              if (!averageTryPerGame[scenarioID]) {
                averageTryPerGame[scenarioID] = {}
              }
              if (!averageTryPerGame[scenarioID][user.id]) {
                averageTryPerGame[scenarioID][user.id] = {}
              }
              if (!averageTryPerGame[scenarioID][user.id][gameName]) {
                averageTryPerGame[scenarioID][user.id][gameName] = 0
              }
              if (!exercicesPerScenario[scenarioID][user.id]) {
                exercicesPerScenario[scenarioID][user.id] = {}
              }
              if (!exercicesPerScenario[scenarioID][user.id][gameName]) {
                exercicesPerScenario[scenarioID][user.id][gameName] = 0
              }
              if (!tryPerUser[user.id]) {
                tryPerUser[user.id] = 0
              }
              if (!sumPerUser[user.id]) {
                sumPerUser[user.id] = 0
              }
              exercicesPerScenario[scenarioID][user.id][gameName] += log.data.value
              averageTryPerGame[scenarioID][user.id][gameName] += 1
              sumPerUser[user.id] += log.data.value
              tryPerUser[user.id]++
            }
          })
        })

        let numScenario = 0
        //Do the average with all users
        for (const scenarioID in exercicesPerScenario) {
          if (Object.prototype.hasOwnProperty.call(exercicesPerScenario, scenarioID)) {
            //Format for csv
            const users = exercicesPerScenario[scenarioID]
            numScenario++
            let label = state.scenarios[scenarioID].name
            if (!xlsData.game[label]) xlsData.game[label] = {}
            if (!xlsData.scenario[label]) xlsData.scenario[label] = {}
            if (!retGameResults[label]) {
              retGameResults[label] = {}
            }
            for (const userID in users) {
              if (Object.prototype.hasOwnProperty.call(users, userID)) {
                const games = users[userID]

                //Sum per game
                for (const gameName in games) {
                  if (Object.prototype.hasOwnProperty.call(games, gameName)) {
                    if (!retGameResults[label][gameName]) {
                      retGameResults[label][gameName] = 0
                    }
                    const gameResult = games[gameName]
                    let result = gameResult / averageTryPerGame[scenarioID][userID][gameName]

                    retGameResults[label][gameName] += result
                  }
                }
              }
            }
            //Do the average per game
            let sum = 0
            let num = 0
            for (const gameName in retGameResults[label]) {
              if (Object.prototype.hasOwnProperty.call(retGameResults[label], gameName)) {
                const sumOfResult = retGameResults[label][gameName]

                retGameResults[label][gameName] = Math.round(
                  (sumOfResult / Object.keys(users).length) * 100
                )
                sum += retGameResults[label][gameName]
                num++
                xlsData.game[label][gameName] = retGameResults[label][gameName]
              }
            }
            retGameResultAverageRaw[label] = Math.round(sum / num)
            totalResultAverage += retGameResultAverageRaw[label]
          }
        }
        return {
          dashboard: {
            gameResults: retGameResults,
            gameResultAverageRaw: retGameResultAverageRaw,
            totalResultAverage: totalResultAverage / numScenario,
          },
          xls: {
            sumPerUser,
            tryPerUser,
          },
        }
      }
      return {}
    },
    async GetScenarioCompletion({ state }, filters) {
      const response = await apollo.query({
        query: GC_GET_SCENARIO_COMPLETION,
        variables: {
          company: filters.company,
          from: filters.fromDate,
          to: filters.toDate,
          xp: filters.experienced,
          mailing: filters.mailing,
          course: filters.course,
          other: filters.other,
          seminar: filters.seminar,
          seniority: filters.seniority,
          status: filters.status,
          id: filters.id,
        },
      })
      if (response && response.data.user) {
        const scenarioNodesPerUser = response.data.user
        let scenarioCompletionFormat = {}
        let scenarioCompletionPre = {}
        let averagePdf = {}
        let totalScenarioCompletion = 0
        let videoPerScenario = {}

        let tryPerUser = {}
        let progressPerUser = {}

        //Format for each scenario
        scenarioNodesPerUser.forEach((user) => {
          user.logs.forEach((log) => {
            if (state.scenarios[log.data_key] && log.data.stats) {
              if (!scenarioCompletionFormat[log.data_key]) {
                scenarioCompletionFormat[log.data_key] = {}
              }
              if (!scenarioCompletionFormat[log.data_key][user.id]) {
                scenarioCompletionFormat[log.data_key][user.id] = {
                  progress: 0,
                  progressPDF: 0,
                  progressMedia: 0,
                }
              }
              state.scenarios[log.data_key].stats = log.data.stats
              //Get the better progress per scenario per user
              if (scenarioCompletionFormat[log.data_key][user.id].progress < log.data.progress) {
                scenarioCompletionFormat[log.data_key][user.id].progress = log.data.progress
              }
              if (
                scenarioCompletionFormat[log.data_key][user.id].progressPDF < log.data.progressPDF
              ) {
                scenarioCompletionFormat[log.data_key][user.id].progressPDF = log.data.progressPDF
              }
              if (
                scenarioCompletionFormat[log.data_key][user.id].progressMedia <
                log.data.progressMedia
              ) {
                scenarioCompletionFormat[log.data_key][user.id].progressMedia =
                  log.data.progressMedia
              }
            }
          })
        })

        let formatedScenarioXls = {}
        for (const scenarioID in scenarioCompletionFormat) {
          if (Object.prototype.hasOwnProperty.call(scenarioCompletionFormat, scenarioID)) {
            const scenario = scenarioCompletionFormat[scenarioID]
            let progress = 0
            let progressPDF = 0
            let progressMedia = 0
            let totalUsers = Object.keys(scenario).length

            for (const userID in scenario) {
              if (Object.prototype.hasOwnProperty.call(scenario, userID)) {
                const user = scenario[userID]

                if (!progressPerUser[userID]) {
                  progressPerUser[userID] = 0
                }
                if (!tryPerUser[userID]) {
                  tryPerUser[userID] = 0
                }

                progressPerUser[userID] += Math.min(user.progress, 1)
                tryPerUser[userID] += 1

                progress += Math.min(user.progress, 1)
                progressPDF += Math.min(user.progressPDF, 1)
                progressMedia += Math.min(user.progressMedia, 1)
              }
            }

            scenarioCompletionPre[scenarioID] = Math.round((progress / totalUsers) * 100)
            totalScenarioCompletion += scenarioCompletionPre[scenarioID]
            averagePdf[scenarioID] = Math.round((progressPDF / totalUsers) * 100)
            videoPerScenario[scenarioID] = Math.round((progressMedia / totalUsers) * 100)

            if (!formatedScenarioXls[state.scenarios[scenarioID].name])
              formatedScenarioXls[state.scenarios[scenarioID].name] = {}

            formatedScenarioXls[state.scenarios[scenarioID].name].completion =
              scenarioCompletionPre[scenarioID]
            formatedScenarioXls[state.scenarios[scenarioID].name]['PDF Visionnés'] =
              averagePdf[scenarioID]
            formatedScenarioXls[state.scenarios[scenarioID].name]['Vidéos vues en entier'] =
              videoPerScenario[scenarioID]
          }
        }

        return {
          dashboard: {
            averagePdf,
            videoPerScenario,
            scenarioCompletionPre,
            totalScenarioCompletion: totalScenarioCompletion / Object.keys(state.scenarios).length,
          },
          xls: {
            scenarioData: formatedScenarioXls,
            tryPerUser,
            progressPerUser,
          },
        }
      }
      return {}
    },
    async GetActiveUsersPerWeek({ state }, filters) {
      //Get TO date as the next monday
      let to = new Date(filters.toDate ? filters.toDate : Date.now())
      to.setDate(to.getDate() + ((1 + 7 - to.getDay()) % 7))
      to.setHours(0, 0, 0)

      //Get FROM time, equivalent to X weeks since next monday
      let from = new Date()
      if (!filters.fromDate) {
        let day = 1000 * 3600 * 24
        let weeks = 8
        from.setTime(to.getTime() - day * 7 * weeks)
      } else {
        from = new Date(filters.fromDate)
        from.setDate(from.getDate() + ((1 + 7 - from.getDay()) % 7))
      }
      const response = await apollo.query({
        query: GC_GET_USER_ACTIVITY,
        variables: {
          company: filters.company,
          from,
          to,
          xp: filters.experienced,
          mailing: filters.mailing,
          course: filters.course,
          other: filters.other,
          seminar: filters.seminar,
          seniority: filters.seniority,
          status: filters.status,
          id: filters.id,
        },
      })
      if (response && response.data.user) {
        let activePerWeek = {}
        const users = response.data.user
        let dailyActivityPerUser = {}
        let activeUserPerWeek = {}
        users.forEach((user) => {
          let previousWeek = ''
          dailyActivityPerUser[user.id] = {}
          user.logs.forEach((log) => {
            let d = new Date(log.created_at)
            d.setDate(d.getDate() - ((6 + d.getDay()) % 7))
            let weekID =
              d.getFullYear() +
              '-' +
              d.getMonth() +
              '-' +
              (d.getDate() > 9 ? d.getDate() : '0' + d.getDate())

            if (!activeUserPerWeek[weekID]) {
              activeUserPerWeek[weekID] = 0
            }

            if (previousWeek !== weekID) {
              activeUserPerWeek[weekID] += 1
            }
            previousWeek = weekID
          })
        })
        Object.keys(activeUserPerWeek)
          .sort()
          .forEach(function (key) {
            let oldKey = key.split('-')
            let label = oldKey[2] + ' ' + state.months[oldKey[1]] + ' ' + oldKey[0]
            activePerWeek[label] = activeUserPerWeek[key]
          }, this)

        return activePerWeek
      }
      return 0
    },
    async NewUsersPerWeek({ state }, filters) {
      //Get TO date as the next monday
      let to = new Date(filters.toDate ? filters.toDate : Date.now())
      to.setDate(to.getDate() + ((1 + 7 - to.getDay()) % 7))
      to.setHours(0, 0, 0)

      //Get FROM time, equivalent to X weeks since next monday
      let from = new Date()
      if (!filters.fromDate) {
        let day = 1000 * 3600 * 24
        let weeks = 8
        from.setTime(to.getTime() - day * 7 * weeks)
      } else {
        from = new Date(filters.fromDate)
        from.setDate(from.getDate() + ((1 + 7 - from.getDay()) % 7))
      }

      const response = await apollo.query({
        query: GC_GET_NEW_USERS_PER_WEEK,
        variables: {
          company: filters.company,
          from,
          to,
          xp: filters.experienced,
          mailing: filters.mailing,
          course: filters.course,
          other: filters.other,
          seminar: filters.seminar,
          seniority: filters.seniority,
          status: filters.status,
          id: filters.id,
        },
      })

      if (response.data && response.data.user) {
        let countPerWeek = {}
        let users = response.data.user
        users.forEach((user) => {
          let d = new Date(user.created_at)
          d.setDate(d.getDate() - ((6 + d.getDay()) % 7))
          let weekID = d.getDate() + ' ' + state.months[d.getMonth()] + ' ' + d.getFullYear()
          if (!countPerWeek[weekID]) {
            countPerWeek[weekID] = 0
          }
          countPerWeek[weekID] += 1
        })
        return countPerWeek
      }
      return 0
    },
    async GetFinishedUsers({ state }, filters) {
      const response = await apollo.query({
        query: GC_GET_FINISHED_USERS,
        variables: {
          company: filters.company,
          from: filters.from,
          to: filters.to,
          xp: filters.experienced,
          mailing: filters.mailing,
          course: filters.course,
          other: filters.other,
          seminar: filters.seminar,
          seniority: filters.seniority,
          status: filters.status,
          id: filters.id,
        },
      })

      if (response.data && response.data.user) {
        let scenarioIDS = Object.keys(state.scenarios).sort()
        const users = response.data.user
        let sumFinished = 0
        let finishedByUser = {}

        users.forEach((user) => {
          if (user.logs && user.logs.length > 0) {
            let uniqueIDS = []
            user.logs.forEach((log) => {
              if (!uniqueIDS.includes(log.data_key)) {
                uniqueIDS.push(log.data_key)
              }
            })
            let index = 0
            uniqueIDS.forEach((id) => {
              if (scenarioIDS.includes(id)) {
                index++
                finishedByUser[user.id] = index
              }
            })
            if (index === scenarioIDS.length) {
              sumFinished++
            }
          }
        })

        return {
          finished: Math.round((sumFinished / state.totalUsers) * 100),
          finishedByUser,
        }
      }
      return {}
    },
    async GetAverageCoTime({ state }, filters) {
      const response = await apollo.query({
        query: GC_GET_AVERAGE_CO_TIME,
        variables: {
          company: filters.company,
          from: filters.from,
          to: filters.to,
          xp: filters.experienced,
          mailing: filters.mailing,
          course: filters.course,
          other: filters.other,
          seminar: filters.seminar,
          seniority: filters.seniority,
          status: filters.status,
          id: filters.id,
        },
      })

      if (response.data && response.data.user) {
        let sumOfTime = 0
        let totalOccurences = 0
        let sessionTimePerUser = {}
        let timePerUser = {}
        response.data.user.forEach((user) => {
          if (user.logs) {
            let days = 0
            let sumOfTimePerUser = 0

            let previousDay
            if (!sessionTimePerUser[user.id]) {
              sessionTimePerUser[user.id] = {}
            }

            user.logs.forEach((log) => {
              let created = new Date(log.created_at)
              let updated = new Date(log.updated_at)
              let dayID = created.getDate() + created.getMonth() + created.getFullYear()
              if (previousDay == dayID || !previousDay) {
                if (log.logType.slug == 'scenario_nodes') {
                  //difference between created and updated times
                  let diff = updated.getTime() - created.getTime()
                  if (diff >= 200 && diff <= 3600000) {
                    if (!sessionTimePerUser[user.id][dayID]) {
                      sessionTimePerUser[user.id][dayID] = 0
                    }
                    sessionTimePerUser[user.id][dayID] += diff
                  }
                } else {
                  //visited media library
                  if (!sessionTimePerUser[user.id][dayID]) {
                    sessionTimePerUser[user.id][dayID] = 0
                  }
                  sessionTimePerUser[user.id][dayID] += 1000 * 60
                }
              }
              previousDay = dayID
            })

            for (const dayID in sessionTimePerUser[user.id]) {
              if (Object.prototype.hasOwnProperty.call(sessionTimePerUser[user.id], dayID)) {
                const timePerDay = sessionTimePerUser[user.id][dayID]
                days++
                sumOfTimePerUser += timePerDay
              }
            }

            if (days != 0) {
              let avg = sumOfTimePerUser / days
              timePerUser[user.id] = avg / 3600000
              totalOccurences++
              sumOfTime += avg
            }
          }
        })

        return {
          totalUserTime: sumOfTime / totalOccurences / 3600000,
          timePerUser,
        }
      }
      return {}
    },
    async GetAllOfficeData({ state, dispatch }, data) {
      let ids = data.ids
      let filters = data.filters
      let officeData = {}
      for (const id of ids) {
        if (!officeData[id]) {
          officeData[id] = {}
        }
        filters.id = id
        let gameResults = await dispatch('GetGameResults', filters)
        let completion = await dispatch('GetScenarioCompletion', filters)
        let finishedUsers = await dispatch('GetFinishedUsers', filters)
        let coTime = await dispatch('GetAverageCoTime', filters)

        if (gameResults && gameResults.dashboard) {
          officeData[id]['Taux de réussite aux exercices'] = gameResults.dashboard
            .totalResultAverage
            ? Math.round(gameResults.dashboard.totalResultAverage)
            : 0
        }
        if (completion && completion.dashboard) {
          officeData[id]['Completion totale'] = completion.dashboard.totalScenarioCompletion
            ? Math.round(completion.dashboard.totalScenarioCompletion)
            : 0
        }
        if (
          finishedUsers &&
          finishedUsers.finished !== null &&
          finishedUsers.finished !== undefined
        ) {
          officeData[id]['Utilisateurs ayant terminé'] = finishedUsers.finished
            ? finishedUsers.finished
            : 0
        }
        if (coTime && coTime.totalUserTime) {
          officeData[id]['Temps de connexion moyen'] = coTime.totalUserTime
            ? coTime.totalUserTime
            : 0
        }
      }

      return officeData
    },
    async GetLogTypes({ commit }) {
      const response = await apollo.query({
        query: GC_GET_LOG_TYPES,
      })
      commit('SET_LOG_TYPES', response.data.log_type)
      return response
    },
    async GetUsersTrackingData(context, { roles, logTypes }) {
      const response = await apollo.query({
        query: GC_GET_USERS_TRACKING_DATA,
        variables: {
          roles,
          logTypes,
        },
      })

      return response.data.user
    },
    async GetUserTrackingData(context, { id, logTypes }) {
      const response = await apollo.query({
        query: GC_GET_USER_TRACKING_DATA,
        variables: {
          id,
          logTypes,
        },
      })

      return response.data.user_by_pk
    },
    async GetCoursesTrackingData() {
      const response = await apollo.query({
        query: GC_GET_COURSES_TRACKING_DATA,
      })

      return response.data.course
    },
    async GetPromotionsTrackingData() {
      const response = await apollo.query({
        query: GC_GET_PROMOTIONS_TRACKING_DATA,
      })

      return response.data.group
    },
    async GetGroupUsersTrackingData(context, { id, roles, logTypes }) {
      const response = await apollo.query({
        query: GC_GET_GROUP_USERS_TRACKING_DATA,
        variables: {
          id,
          roles,
          logTypes,
        },
      })

      return response.data.group_by_pk.users.map((groupUser) => groupUser.user)
    },
    async GetCustomerUsersTrackingData(context, { id, roles, logTypes }) {
      const response = await apollo.query({
        query: GC_GET_CUSTOMER_USERS_TRACKING_DATA,
        variables: {
          id,
          roles,
          logTypes,
        },
      })

      return response.data.customer_by_pk.groups.reduce((userList, group) => {
        group.users.forEach((groupUser) => userList.push(groupUser.user))

        return userList
      }, [])
    },
    async ContentLog({ state, dispatch, rootState }, data) {
      // if (!data.id || !data.action) return
      // if (state.logTypes.length == 0) {
      //   await dispatch('GetLogTypes')
      // }
      // let obj = {
      //   log_type_id: state.logTypes[data.action],
      //   data_key: data.id,
      //   user_id: rootState['Auth'].userInfo.id,
      // }
      // await apollo.mutate({
      //   mutation: GC_INSERT_CONTENT_LOG,
      //   variables: {
      //     obj,
      //   },
      // })
    },
    async PromotionLogs({ dispatch }, { ids, from, to }) {
      let idList = ids.map((val) => val.id)
      const response = await apollo.query({
        query: GC_GET_USERS_PROMOTION,
        variables: {
          ids: idList,
          from,
          to,
        },
      })

      let promotionData = {
        averageCoTime: 0,
        additionalCoTime: 0,
        formatedAverageCoTime: '',
        formatedAdditionalCoTime: '',
        averageScenarioTime: {},
        users: {},
      }
      let cumulatedScenarioTime = {}
      let scenarioOccurences = {}

      let users = response.data.user

      for (let i = 0; i < users.length; i++) {
        const user = users[i]
        let userTime = await dispatch('getUserConnexionTime', user)
        // let coTime = userTime.coTimePerDay
        let totalTime = userTime.totalTime
        let formattedCoTimes = userTime.formatedCoTimePerDay

        let scenarioTimes = await dispatch('getUserScenarioTime', user)
        let avgTimePerScenario = scenarioTimes.avgTimePerScenario
        let formatedScenarioTimes = scenarioTimes.formatedScenarioTimes

        for (const scenarioID in avgTimePerScenario) {
          if (Object.hasOwnProperty.call(avgTimePerScenario, scenarioID)) {
            const time = avgTimePerScenario[scenarioID]
            if (!cumulatedScenarioTime[scenarioID]) {
              cumulatedScenarioTime[scenarioID] = 0
            }
            if (!scenarioOccurences[scenarioID]) {
              scenarioOccurences[scenarioID] = 0
            }
            cumulatedScenarioTime[scenarioID] += time
            scenarioOccurences[scenarioID] += 1
          }
        }
        promotionData.additionalCoTime += totalTime
        promotionData.users[user.name] = {
          name: user.name,
          firstName: user.first_name,
          connexionTime: formattedCoTimes,
          formatedScenarioTimes,
        }
      }

      promotionData.averageCoTime = promotionData.additionalCoTime / response.data.user.length
      promotionData.formatedAverageCoTime = await dispatch(
        'formatUserTime',
        promotionData.averageCoTime
      )
      promotionData.formatedAdditionalCoTime = await dispatch(
        'formatUserTime',
        promotionData.additionalCoTime
      )

      for (const scenarioID in cumulatedScenarioTime) {
        if (Object.hasOwnProperty.call(cumulatedScenarioTime, scenarioID)) {
          const time = cumulatedScenarioTime[scenarioID]
          promotionData.averageScenarioTime[scenarioID] = await dispatch(
            'formatUserTime',
            time / scenarioOccurences[scenarioID]
          )
        }
      }

      return promotionData
    },
    async getUserConnexionTime({ dispatch }, user) {
      if (user.connexion.length == 0) return 0

      let logs = user.connexion
      let coTimePerDay = {}
      let formatedCoTimePerDay = {}
      let totalTime = 0

      for (let i = 0; i < logs.length; i++) {
        const currentLog = logs[i]
        let created = new Date(currentLog.created_at)
        let nextLog
        if (logs[i + 1]) {
          nextLog = logs[i + 1]
          if (currentLog.logType.slug == 'login' && nextLog.logType.slug == 'logout') {
            let nextCreate = new Date(nextLog.created_at)
            let diff = nextCreate.getTime() - created.getTime()
            let day = created.getDate() < 9 ? '0' + created.getDate() : created.getDate()
            let month =
              created.getMonth() + 1 < 9 ? '0' + (created.getMonth() + 1) : created.getMonth() + 1
            let dayID = day + '/' + month + '/' + created.getFullYear()
            coTimePerDay[dayID] = diff
            formatedCoTimePerDay[dayID] = await dispatch('formatUserTime', diff)
            totalTime += diff
          } else {
            //count like X h ?
          }
        }
      }
      return {
        coTimePerDay,
        totalTime,
        formatedCoTimePerDay,
      }
    },
    async getUserScenarioTime({ dispatch }, user) {
      if (user.scenario.length == 0) return 0
      let occurencesPerScenario = {}
      let timePerScenario = {}
      let formatedScenarioTimes = {}

      user.scenario.forEach((log) => {
        let created = new Date(log.created_at)
        let updated = new Date(log.updated_at)
        let diff = updated.getTime() - created.getTime()

        if (!timePerScenario[log.data_key]) {
          timePerScenario[log.data_key] = 0
          occurencesPerScenario[log.data_key] = 0
        }
        timePerScenario[log.data_key] += diff
        occurencesPerScenario[log.data_key] += 1
      })

      let avgTimePerScenario = {}
      for (const scenarioID in timePerScenario) {
        if (Object.prototype.hasOwnProperty.call(timePerScenario, scenarioID)) {
          const scenarioTime = timePerScenario[scenarioID] / occurencesPerScenario[scenarioID]
          avgTimePerScenario[scenarioID] = scenarioTime
          formatedScenarioTimes[scenarioID] = await dispatch('formatUserTime', scenarioTime)
        }
      }
      return {
        avgTimePerScenario,
        formatedScenarioTimes,
      }
    },
    formatUserTime({ commit }, coTime) {
      let hour10 = coTime / 3600000

      let hour = Math.floor(hour10)
      let decimalPart = hour10 - hour

      let min = 1 / 60

      decimalPart = min * Math.round(decimalPart / min)
      let minute = Math.floor(decimalPart * 60) + ''

      if (minute.length < 2) {
        minute = '0' + minute
      }
      return hour + 'h' + minute
    },
  },
}
