import gql from 'graphql-tag'

const GC_GET_USER_N_ORGAS = gql`
  query GetUserInfo($id: uuid!) {
    user(where: { id: { _eq: $id } }) {
      id
      email
      first_name
      name: last_name
      role
      promotion_users: groups(distinct_on: group_id) {
        promotion: group {
          title
          id
          start_date
          end_date
        }
      }
    }
  }
`

const GC_EDIT_USER = gql`
  mutation UpdateUser($id: uuid!, $email: String!, $fName: String!, $lName: String!) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: { email: $email, first_name: $fName, name: $lName, role: $role }
    ) {
      id
    }
  }
`

const GC_EDIT_USER_AND_PASSWORD = gql`
  mutation UpdateUser(
    $id: uuid!
    $email: String!
    $company: String
    $seniority: Int
    $status: Int
    $role: String
    $fName: String!
    $lName: String!
    $password: String!
  ) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: { email: $email, first_name: $fName, name: $lName, role: $role, password: $password }
    ) {
      id
    }
  }
`

const GC_ADD_USER_ONE = gql`
  mutation AddUser(
    $email: String!
    $company: String
    $status: Int
    $seniority: Int
    $role: String
    $fName: String!
    $lName: String
    $password: String
  ) {
    insert_user_one(
      object: { email: $email, first_name: $fName, name: $lName, role: $role, password: $password }
    ) {
      id
    }
  }
`

const GC_EDIT_ORGA_ONE = gql`
  mutation UpdateOrga($id: uuid!, $orga: organisations_set_input!) {
    update_organisations_by_pk(pk_columns: { id: $id }, _set: $orga) {
      id
    }
  }
`

const GC_ADD_ORGA_ONE = gql`
  mutation AddOrga($orga: organisations_insert_input!) {
    insert_organisations_one(object: $orga) {
      id
    }
  }
`

const GC_GET_ORGA_ONE = gql`
  query GetOrga($id: uuid!) {
    organisations_by_pk(id: $id) {
      name
      id
      city
      address2
      address
      post_code
      country
      admin_id
      federation_id
      housing
      hlm
      admin {
        id
        name
      }
      userOrganisations(distinct_on: user) {
        user
      }
    }
  }
`

export {
  GC_GET_USER_N_ORGAS,
  GC_EDIT_USER,
  GC_EDIT_USER_AND_PASSWORD,
  GC_EDIT_ORGA_ONE,
  GC_GET_ORGA_ONE,
  GC_ADD_USER_ONE,
  GC_ADD_ORGA_ONE,
}
