<template>
  <label :class="`switch switch--${type}`">
    <input :disabled="disabled" type="checkbox" :checked="checked" @input="onInput" />
    <span class="slider round"></span>
  </label>
</template>
<script>
export default {
  props: {
    onInput: {
      type: Function,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },
}
</script>
<style lang="scss" scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin-bottom: 0;
}

/* Hide default HTML checkbox */
.switch input[type='checkbox'] {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  @apply bg-blue;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  @apply bg-white;
}

input:checked + .slider {
  @apply bg-pink-pretty;
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
  @apply bg-gray-light;
  box-shadow: 0px 0px 4px 0px #0000000d inset;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
