<template>
  <div
    id="nav"
    class="bg-gray-background text-principal-gray p-4"
    @mouseover="open()"
    @mouseleave="close()"
  >
    <!-- LOGO -->
    <div
      class="my-4 logo-container m-auto items-center"
      :class="{
        'h-32': showNav,
        'h-14': !showNav,
      }"
    >
      <div class="flex flex-row">
        <img class="logo" src="/logo.svg" alt="logo" />
      </div>
      <router-link
        v-if="showNav"
        :to="{ name: 'users-list' }"
        class="flex items-center my-4 italic text-blue underline hover:text-gray"
      >
        www.parcoursmed.fr
        <img src="@/assets/images/ARROW_RIGHT.svg" alt="right arrow" class="ml-1" />
      </router-link>
    </div>
    <!-- LINKS -->
    <div class="nav-links w-full flex-grow space-y-2" @click="toggleMobile">
      <router-link class="items-center navbar__nav-link" :to="{ name: 'users-list' }">
        <div class="absolute w-full left-0" v-if="isCurrentPage('users-list')">
          <div class="w-full bg-blue relative" style="top: -14px; height: 42px">.</div>
        </div>
        <div>
          <img
            src="@/assets/images/USERS.svg"
            alt="Etudiants"
            class="twn-icon filter"
            :class="{
              'brightness-200': isCurrentPage('users-list'),
            }"
          />
          <span
            class="uppercase font-medium text-blue"
            :class="{
              'text-white': isCurrentPage('users-list'),
            }"
            >Etudiants</span
          >
        </div>
      </router-link>
      <router-link class="items-center navbar__nav-link" :to="{ name: 'groups-list' }">
        <div class="absolute w-full left-0" v-if="isCurrentPage('groups-list')">
          <div class="w-full bg-blue relative" style="top: -14px; height: 42px">.</div>
        </div>

        <div>
          <img
            src="@/assets/images/HAT.svg"
            alt="Programmes"
            class="twn-icon filter"
            :class="{
              'brightness-200': isCurrentPage('groups-list'),
            }"
          />
          <span
            :class="{
              'text-white': isCurrentPage('groups-list'),
            }"
            class="uppercase text-blue font-medium"
            >Programmes</span
          >
        </div>
      </router-link>
      <router-link class="items-center navbar__nav-link" :to="{ name: 'comments' }">
        <div class="absolute w-full left-0" v-if="isCurrentPage('comments')">
          <div class="w-full bg-blue relative" style="top: -14px; height: 42px">.</div>
        </div>
        <div>
          <img
            src="@/assets/images/CONVERSATION.svg"
            alt="Programmes"
            class="twn-icon filter"
            :class="{
              'brightness-200': isCurrentPage('comments'),
            }"
          />
          <span
            :class="{
              'text-white': isCurrentPage('comments'),
            }"
            class="uppercase text-blue font-medium"
            >Commentaires</span
          >
        </div>
      </router-link>
      <router-link class="items-center navbar__nav-link" :to="{ name: 'homepage' }">
        <div class="absolute w-full left-0" v-if="isCurrentPage('homepage')">
          <div class="w-full bg-blue relative" style="top: -14px; height: 42px">.</div>
        </div>
        <div>
          <img
            src="@/assets/images/MEGAPHONE.svg"
            alt="Page d'accueil"
            class="twn-icon filter"
            :class="{
              'brightness-200': isCurrentPage('homepage'),
            }"
          />
          <span
            class="uppercase font-medium text-blue"
            :class="{
              'text-white': isCurrentPage('homepage'),
            }"
            >Page d'accueil</span
          >
        </div>
      </router-link>
      <router-link class="items-center navbar__nav-link" :to="{ name: 'planning-list' }">
        <div class="absolute w-full left-0" v-if="isCurrentPage('planning-list')">
          <div class="w-full bg-blue relative" style="top: -14px; height: 42px">.</div>
        </div>
        <div>
          <img
            src="@/assets/images/MEGAPHONE.svg"
            alt="Plannings"
            class="twn-icon filter"
            :class="{
              'brightness-200': isCurrentPage('planning-list'),
            }"
          />
          <span
            class="uppercase font-medium text-blue"
            :class="{
              'text-white': isCurrentPage('planning-list'),
            }"
            >Plannings</span
          >
        </div>
      </router-link>
      <hr class="w-full border-blue opacity-50" />
      <router-link
        class="items-center navbar__nav-link"
        :to="{ name: 'scenario-list', params: { typeSlug: 'basic' } }"
      >
        <div
          class="absolute w-full left-0"
          v-if="isCurrentPage('scenario-list', { typeSlug: 'basic' })"
        >
          <div class="w-full bg-blue relative" style="top: -14px; height: 42px">.</div>
        </div>
        <div>
          <img
            src="@/assets/images/UE.svg"
            alt="Programmes"
            class="twn-icon filter"
            :class="{
              'brightness-200': isCurrentPage('scenario-list', { typeSlug: 'basic' }),
            }"
          />
          <span
            :class="{
              'text-white': isCurrentPage('scenario-list', { typeSlug: 'basic' }),
            }"
            class="uppercase text-blue font-medium"
            >UE</span
          >
        </div>
      </router-link>
      <router-link
        class="items-center navbar__nav-link"
        :to="{ name: 'scenario-list', params: { typeSlug: 'chaptered_course' } }"
      >
        <div
          class="absolute w-full left-0"
          v-if="isCurrentPage('scenario-list', { typeSlug: 'chaptered_course' })"
        >
          <div class="w-full bg-blue relative" style="top: -14px; height: 42px">.</div>
        </div>
        <div>
          <img
            src="@/assets/images/BOOK.svg"
            alt="Programmes"
            class="twn-icon filter"
            :class="{
              'brightness-200': isCurrentPage('scenario-list', { typeSlug: 'chaptered_course' }),
            }"
          />
          <span
            :class="{
              'text-white': isCurrentPage('scenario-list', { typeSlug: 'chaptered_course' }),
            }"
            class="uppercase text-blue font-medium"
            >Cours</span
          >
        </div>
      </router-link>
      <router-link
        class="items-center navbar__nav-link"
        :to="{ name: 'activity-list', params: { type: 'quiz_simple' } }"
      >
        <div
          class="absolute w-full left-0"
          v-if="isCurrentPage('activity-list', { type: 'quiz_simple' })"
        >
          <div class="w-full bg-blue relative" style="top: -14px; height: 42px">.</div>
        </div>
        <div>
          <img
            src="@/assets/images/CHAPTER.svg"
            alt="Programmes"
            class="twn-icon filter"
            :class="{
              'brightness-200': isCurrentPage('activity-list', { type: 'quiz_simple' }),
            }"
          />
          <span
            :class="{
              'text-white': isCurrentPage('activity-list', { type: 'quiz_simple' }),
            }"
            class="uppercase text-blue font-medium"
            >Chapitres</span
          >
        </div>
      </router-link>
      <router-link
        class="items-center navbar__nav-link"
        :to="{ name: 'activity-list', params: { type: 'mock_exam' } }"
      >
        <div
          class="absolute w-full left-0"
          v-if="isCurrentPage('activity-list', { type: 'mock_exam' })"
        >
          <div class="w-full bg-blue relative" style="top: -14px; height: 42px">.</div>
        </div>
        <div>
          <img
            src="@/assets/images/CLOCK.svg"
            alt="Programmes"
            class="twn-icon filter"
            :class="{
              'brightness-200': isCurrentPage('activity-list', { type: 'mock_exam' }),
            }"
          />
          <span
            :class="{
              'text-white': isCurrentPage('activity-list', { type: 'mock_exam' }),
            }"
            class="uppercase text-blue font-medium"
            >Concours blancs</span
          >
        </div>
      </router-link>
      <router-link
        class="items-center navbar__nav-link"
        :to="{ name: 'activity-list', params: { type: 'colle' } }"
      >
        <div
          class="absolute w-full left-0"
          v-if="isCurrentPage('activity-list', { type: 'colle' })"
        >
          <div class="w-full bg-blue relative" style="top: -14px; height: 42px">.</div>
        </div>
        <div>
          <img
            src="@/assets/images/CLOCK.svg"
            alt="Programmes"
            class="twn-icon filter"
            :class="{
              'brightness-200': isCurrentPage('activity-list', { type: 'colle' }),
            }"
          />
          <span
            :class="{
              'text-white': isCurrentPage('activity-list', { type: 'colle' }),
            }"
            class="uppercase text-blue font-medium"
            >Colles</span
          >
        </div>
      </router-link>
      <hr class="w-full border-blue opacity-50" />
      <router-link class="items-center navbar__nav-link" :to="{ name: 'media-list' }">
        <div class="absolute w-full left-0" v-if="isCurrentPage('media-list')">
          <div class="w-full bg-blue relative" style="top: -14px; height: 42px">.</div>
        </div>
        <div>
          <img
            src="@/assets/images/MEDIAS.svg"
            alt="Programmes"
            class="twn-icon filter"
            :class="{
              'brightness-200': isCurrentPage('media-list'),
            }"
          />
          <span
            :class="{
              'text-white': isCurrentPage('media-list'),
            }"
            class="uppercase text-blue font-medium"
            >Medias</span
          >
        </div>
      </router-link>
    </div>
    <!-- FOOTER -->
    <div
      @click="toggleNav"
      class="arrows bootstrap md:visible md:block text-center cursor-pointer w-full"
    >
      <b-icon-arrow-right class="right-arrow h1 m-0" />
      <div class="left-arrow flex justify-end items-center">
        <span class="uppercase mr-4 font-medium text-sm pt-1">Réduire</span>
        <img src="@/assets/images/ARROW_LEFT.svg" alt="Réduire" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Navbar',
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      publicPath: process.env.BASE_URL,
      openedItem: null,
    }
  },
  computed: {
    ...mapState('Auth', ['userInfo']),
    ...mapState('Header', ['showNav']),
    isUserAdmin() {
      return (
        this.userInfo && (this.userInfo.role == 'superadmin' || this.userInfo.role == 'manager')
      )
    },
    isUserSuperAdmin() {
      return this.userInfo && this.userInfo.role == 'superadmin'
    },
  },
  methods: {
    ...mapActions('Header', ['toggleNav', 'openNav', 'closeNav']),

    isCurrentPage(name, params = {}) {
      return (
        this.$route.name == name && JSON.stringify(this.$route.params) == JSON.stringify(params)
      )
    },

    toggleMobile(e) {
      if (this.window.width <= parseInt(640, 10)) {
        this.toggleNav(e)
      }
    },
    open() {
      this.openNav()
    },
    close() {
      this.closeNav()
      this.openedItem = null
    },
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    onItemClick(itemName) {
      this.openedItem = this.openedItem === itemName ? null : itemName
    },
    isItemIsThePageWhereUserIs(item) {
      return this.$route.matched.some(({ name }) => name === item)
    },
  },
  async created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style lang="scss">
#nav {
  @apply border-line-left left-0 right-auto overflow-y-auto flex flex-col items-center;
  border-right-width: 1px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.3s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transform: translate3d(0px, -100vh, 0) !important;
  overflow-x: hidden;
  width: 100%;
  z-index: 99;

  .logo {
    @apply w-full;
    max-width: 13rem;
  }

  &.nav-open {
    transform: translate3d(0px, 0, 0) !important;
    .logo {
      transition: all 0.1s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }

    > div:not(.arrows) {
      &.nav-links {
        @apply items-start;
      }
    }
  }

  > div:not(.arrows) {
    @apply flex flex-col;

    &.logo-container {
      @apply self-start;
    }
    &.nav-links {
      @apply text-xs tracking-tighter leading-loose items-center;
      opacity: 0.9;

      .navbar__nav-link {
        @apply w-full;
        div {
          @apply flex items-center;
        }
        &:hover {
          @apply text-principal-selected no-underline cursor-pointer;
        }
      }

      div,
      a {
        .twn-icon {
          @apply mb-0 transition duration-100 ease-linear;
        }
        span {
          @apply transition duration-100 ease-linear tracking-normal text-base absolute;
          @screen md {
            @apply text-sm;
          }
          left: 70px;
        }
        .navbar__nav-link-children {
          a {
            @apply mt-4;
          }
          span {
            @apply text-xs;
          }
        }
      }

      > a {
        @apply py-2 px-1 rounded;
      }

      > hr {
        @apply my-3;
      }
    }
  }

  @screen md {
    @apply flex flex-col;
    overflow-y: none;
    width: $navbarLength;

    transform: translate3d(0px, 0, 0) !important;

    .arrows {
      .up-arrow {
        @apply hidden;
      }
    }
    .right-arrow {
      @apply hidden m-0;
    }
    .left-arrow {
      @apply visible;
    }
    .nav-links {
      span {
        @apply opacity-100;
      }
    }
    &:not(.nav-open) {
      .left-arrow {
        @apply hidden;
      }
      .right-arrow {
        @apply block visible;
      }
      width: 5rem !important;
      .nav-links {
        span {
          @apply opacity-0;
        }
      }
    }
  }
}
</style>
