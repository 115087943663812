import gql from 'graphql-tag'

const GC_USER_INFOS = gql`
  query getCurrentUser($cognito_id: String!) {
    user(where: { cognito_id: { _eq: $cognito_id } }) {
      id
      email
      first_name
      last_name
      role
      cognito_id
    }
  }
`

const CG_GET_ORGA = gql`
  query getCurrentOrga($id: uuid!) {
    organisations(where: { admin_id: { _eq: $id } }) {
      id
      name
    }
  }
`

export { GC_USER_INFOS, CG_GET_ORGA }
