import gql from 'graphql-tag'

export const GC_GET_ACTIVITY_COMMENTS = gql`
  query GetActivityComments($activityId: uuid!) {
    activity_by_pk(id: $activityId) {
      comments(order_by: { created_at: asc }) {
        content
        created_at
        public
        user {
          id
          first_name
          last_name
          role
        }
        validated
        id
      }
    }
  }
`

/**
 * Insert an activity comment already published and valid
 */
export const GC_INSERT_ACTIVITY_COMMENT = gql`
  mutation InsertActivityComment($activityId: uuid!, $userId: uuid!, $content: String!) {
    insert_activity_comments_one(
      object: {
        activity_id: $activityId
        content: $content
        user_id: $userId
        public: true
        validated: true
      }
    ) {
      content
      created_at
      public
      user {
        first_name
        last_name
        role
      }
      validated
      id
    }
  }
`

export const GC_UPDATE_ACTIVITY_COMMENT = gql`
  mutation UpdateActivityComment($commentId: uuid!, $public: Boolean!, $validated: Boolean!) {
    update_activity_comments_by_pk(
      pk_columns: { id: $commentId }
      _set: { public: $public, validated: $validated }
    ) {
      content
      created_at
      public
      user {
        first_name
        last_name
        role
      }
      validated
      id
    }
  }
`

/**
 * Insert comment notifications
 */
export const GC_INSERT_NOTIFICATIONS = gql`
  mutation InsertNotifications($notifications: [notifications_insert_input!]!) {
    insert_notifications(objects: $notifications) {
      affected_rows
    }
  }
`
