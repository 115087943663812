<template>
  <div class="planning-detail mt-8 md:mt-0 px-8">
    <div
      class="buttons flex flex-row justify-around md:justify-between border-b border-gray-400 pb-4 mb-4"
    >
      <button type="button" class="twn-button text-xs" @click="goBack()">Retour</button>

      <div>
        <button
          v-if="isSuperAdmin && itemID"
          type="button"
          class="twn-button text-xs danger mr-4"
          @click="showDeleteModal()"
          :disabled="hasPendingStoreRequest"
        >
          Supprimer le planning
        </button>
        <button
          type="button"
          class="twn-button secondary text-xs"
          @click="onSave"
          :disabled="hasPendingStoreRequest"
        >
          Enregistrer
        </button>
      </div>
    </div>

    <div class="my-10">
      <div class="form-group inline-block w-2/3 mb-8">
        <label for="game-name" class="uppercase font-principal text-sm">Nom du planning *</label>
        <b-form-input
          :disabled="!isSuperAdmin"
          class="w-full"
          v-model="planning.name"
          type="text"
          :state="!!planning.name ? null : false"
        />
      </div>

      <div class="mb-4 flex" v-if="itemID">
        <Dropzone
          @file-uploaded="onFileUpload"
          :accepted-files="['image/*']"
          type="file_image"
          :planning-id="planning.id"
          class="mr-4"
          :with-select="false"
        />

        <Dropzone
          @file-uploaded="onFileUpload"
          :accepted-files="['application/pdf']"
          type="file_pdf"
          :planning-id="planning.id"
          :with-select="false"
        />
      </div>

      <ul v-if="mediasList.length" class="mb-10">
        Fichiers actuels:
        <li v-for="media in mediasList" :key="media.id" class="list">
          <a :href="media.path" target="_blank" class="text-blue underline">{{
            media.title || media.name
          }}</a>
          {{ media.used ? '' : '(Non utilisé dans le document)' }}
          <button type="button" @click="deleteMedia(media)" v-if="!media.used">
            <b-icon-x class="text-xl text-red-600 mb-0"></b-icon-x>
          </button>
        </li>
      </ul>

      <TextEditor v-model="planning.content" :medias="planning.medias" all-features />

      <div v-if="isSuperAdmin && itemID" class="mt-8">
        <h3 class="title text-2xl text-blue font-light mb-8">
          Étudiants inscrits dans ce planning
        </h3>

        <PromotionInternsForm
          :promotion="planning"
          @add-intern="onAddIntern"
          @remove-intern="onRemoveIntern"
          is-planning
        />
      </div>
    </div>

    <b-modal
      ref="delete-modal-detail-planning"
      class="bootstrap"
      centered
      hide-footer
      id="delete-modal-detail-planning"
      hide-header
    >
      <div class="d-block text-center my-6 uppercase font-semibold">
        <h3>Confirmer la suppression de "{{ planning.name }}"</h3>
      </div>
      <div class="flex flex-row justify-evenly items-center">
        <button
          type="button"
          class="mt-4 twn-button"
          @click="$bvModal.hide('delete-modal-detail-planning')"
        >
          Retour
        </button>
        <button type="button" class="mt-4 twn-button danger" @click="onConfirmDelete">
          Supprimer
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import TextEditor from '@/components/base/TextEditor.vue'
import Dropzone from '@/components/Dropzone'
import dispatchStoreRequest from '@/mixins/dispatchStoreRequest'
import PromotionInternsForm from '@/views/Promotions/PromotionInternsForm.vue'

export default {
  name: 'PlanningDetail',

  mixins: [dispatchStoreRequest],

  components: {
    TextEditor,
    Dropzone,
    PromotionInternsForm,
  },

  props: ['itemID'],

  data: () => ({
    planning: {
      id: null,
      name: '',
      content: '',
      users: [],
      medias: [],
    },
  }),

  computed: {
    ...mapState('Auth', ['userInfo']),

    isSuperAdmin() {
      return this.userInfo && this.userInfo.role == 'superadmin'
    },

    mediasList() {
      return this.planning.medias.map((media) => ({
        ...media,
        used: this.planning.content.includes(media.path),
        path: process.env.VUE_APP_AWS_S3_URL + '/' + media.path,
      }))
    },
  },

  watch: {
    itemID: {
      async handler(itemID) {
        if (itemID) {
          this.getPlanning()
        } else {
          this.planning = {
            id: null,
            name: '',
            content: '',
            users: [],
            medias: [],
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    async onSave() {
      if (!this.planning.name) this.planning.name = null

      const response = await this.dispatchStoreRequest('Planning/save', this.planning)

      if (response.id) {
        this.$router.push({
          name: 'planning-edit',
          params: {
            itemID: response.id,
          },
        })
      }

      this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })
    },

    async getPlanning(savedData = {}) {
      await this.dispatchStoreRequest('Planning/getByID', this.itemID, true)
      const remoteData = this.$store.state.Planning.items[this.itemID]
      // todo: handle error
      if (!remoteData) return
      const { name, content, users, medias } = remoteData
      this.planning = {
        id: this.itemID,
        name,
        content,
        users,
        medias,
        ...savedData,
      }
    },

    async onFileUpload() {
      // Refetch medias data but keep probably unsaved content
      this.getPlanning({
        name: this.planning.name,
        content: this.planning.content,
      })
    },

    async deleteMedia(media) {
      await this.dispatchStoreRequest('Assets/deleteFile', media)
      await this.onFileUpload()
    },

    showDeleteModal() {
      this.$refs['delete-modal-detail-planning'].show()
    },
    async onConfirmDelete() {
      await this.$store.dispatch('Planning/delete', this.planning)
      this.$bvModal.hide('delete-modal-detail-promotion')
      this.goBack()
    },
    goBack() {
      this.$router.push({ name: 'planning-list' })
    },

    onAddIntern(intern) {
      this.planning.users.unshift({ id: intern.id })
    },
    onRemoveIntern(intern) {
      this.planning.users = this.planning.users.filter((user) => user.id !== intern.id)
    },
  },
}
</script>
