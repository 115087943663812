<template>
  <div class="drop-zone">
    <Dashboard :uppy="uppy" :plugins="['Webcam']" :props="dashboardOptions" />

    <div v-if="error" class="text-red-400 mt-2">
      <p>{{ error }}</p>
    </div>
    <v-select
      v-if="withSelect"
      id="asset-select"
      class="twn-select mt-2"
      :placeholder="`Choisir ${typeText === 'pdf' ? 'un' : 'une'} ${typeText} dans la liste...`"
      :options="filteredAssets"
      :reduce="(asset) => asset.id"
      :getOptionLabel="(asset) => asset.title || asset.name"
      v-model="selectedFile"
      @input="onFileChange"
    />

    <div v-if="currentFile">
      <a
        target="_blank"
        :href="currentFilePath"
        class="inline-block mt-2 text-blue border-blue border-1 px-2 py-1"
      >
        Voir le fichier actuel
      </a>
    </div>
  </div>
</template>

<script>
import { Dashboard } from '@uppy/vue'
import AwsS3 from '@uppy/aws-s3'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

import Uppy from '@uppy/core'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    Dashboard,
  },

  props: {
    currentFile: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: true,
    },
    acceptedFiles: {
      type: Array,
      default: () => ['image/*'],
    },
    pageId: {
      type: String,
      default: null,
    },
    planningId: {
      type: String,
      default: null,
    },
    withSelect: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      dashboardOptions: {
        width: 300,
        height: 250,
        locale: {
          strings: {
            dropPasteFiles: `Déposer des ${this.type.split('_')[1]}s ou %{browseFiles}`,
            browseFiles: 'parcourir',
          },
        },
      },
      error: null,
      uppy: null,
      selectedFile: null,
    }
  },

  computed: {
    ...mapGetters('Assets', ['assetList']),
    currentFilePath() {
      return process.env.VUE_APP_AWS_S3_URL + '/' + this.currentFile
    },
    typeText() {
      return this.type.split('_')[1]
    },
    filteredAssets() {
      return this.assetList.filter((asset) => asset.type.slug === this.type)
    },
  },

  async created() {
    this.uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: this.acceptedFiles,
      },
    })

    this.uppy.use(AwsS3, {
      getUploadParameters: async (file) => {
        file.name = Date.now() + '_' + file.name
        const url = await this.getSignedUrl(file)
        return { method: 'PUT', url, headers: { 'Content-Type': file.type } }
      },
    })

    this.uppy.on('complete', async (result) => {
      this.error = null
      try {
        const file = result.successful[0]
        const types = await this.getTypesList()
        const type = types.find((t) => t.slug === this.type)

        file.assetType = { id: type.id }
        file.title = file.name.substring(file.name.indexOf('_') + 1)
        if (this.pageId) {
          file.pageId = this.pageId
        }
        if (this.planningId) {
          file.planningId = this.planningId
        }
        const hasuraFile = await this.sendFileToHasura(result.successful[0])
        hasuraFile.data.insert_media_one.id
        this.$emit('file-uploaded', hasuraFile.data.insert_media_one)
        await this.getAssetList()
        this.selectedFile = hasuraFile.data.insert_media_one.id
      } catch {
        this.error = "Une erreur est survenue lors de l'envoi du fichier"
        this.uppy.reset()
      }
    })

    await this.getAssetList()
    if (this.currentFile) {
      this.selectedFile =
        this.assetList.find((asset) => asset.path === this.currentFile)?.id || null
    }
  },

  methods: {
    ...mapActions('Assets', [
      'addAsset',
      'getAssetList',
      'getTypesList',
      'getSignedUrl',
      'deleteFile',
      'setFileThumbnail',
      'deleteFileFromAWS',
      'sendFileToAWS',
      'sendFileToHasura',
      'getAssetFromPath',
    ]),

    onFileChange(id) {
      this.$emit('file-uploaded', this.assetList.find((asset) => asset.id === id) || null)
    },
  },

  beforeDestroy() {
    this.uppy.close()
  },
}
</script>

<style land="scss" scoped>
.drop-zone ::v-deep {
  max-width: 300px;
}
</style>
