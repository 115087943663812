var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.course)?_c('p',{staticClass:"mt-4 text-center"},[_vm._v("Aucun parcours sélectionné")]):(!_vm.course.id)?_c('p',{staticClass:"mt-4 text-center"},[_vm._v("Chargement du parcours...")]):_c('div',{staticClass:"mt-4 border rounded p-4"},[_c('div',{staticClass:"flex mb-4"},[_c('div',{staticClass:"w-1/4"}),_c('div',{staticClass:"w-1/4"}),(_vm.promotionType && _vm.promotionType.slug == 'collective_promotion')?[_c('p',{staticClass:"w-1/4 px-2 text-center"},[_vm._v("Date")]),_c('p',{staticClass:"w-1/4 px-2 text-center"},[_vm._v("Disponible jusqu'au")])]:[_c('p',{staticClass:"w-1/4 px-2 text-center"},[_vm._v("Du")]),_c('p',{staticClass:"w-1/4 px-2 text-center"},[_vm._v("Au")])]],2),_vm._l((_vm.course.modules),function(module,moduleIndex){return _vm._l((module.sequences),function(sequence){return _c('div',{key:sequence.id,staticClass:"flex mb-4"},[_c('p',{staticClass:"w-1/4"},[_vm._v(" "+_vm._s('Niveau ' + (moduleIndex + 1) + ' - ' + module.name + (module.title ? ' - ' + module.title : ''))+" ")]),_c('p',{staticClass:"w-1/4"},[_vm._v(_vm._s(sequence.name + ' - ' + sequence.title))]),_c('div',{staticClass:"w-1/4 px-2"},[_c('b-form-datepicker',{attrs:{"date-format-options":{
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            weekday: 'long',
          },"min":_vm.promotion.startDate,"max":_vm.promotion.endDate,"value":_vm.sequencesData[sequence.id] ? _vm.sequencesData[sequence.id].startDate : null},on:{"input":function($event){return _vm.onSequenceStartDateUpdate(sequence.id, $event)}}})],1),_c('div',{staticClass:"w-1/4 px-2"},[_c('b-form-datepicker',{attrs:{"date-format-options":{
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            weekday: 'long',
          },"min":_vm.sequencesData[sequence.id] && _vm.sequencesData[sequence.id].startDate
              ? _vm.sequencesData[sequence.id].startDate
              : _vm.promotion.startDate,"max":_vm.promotion.endDate,"value":_vm.sequencesData[sequence.id] ? _vm.sequencesData[sequence.id].endDate : null},on:{"input":function($event){return _vm.onSequenceEndDateUpdate(sequence.id, $event)}}})],1)])})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }