import gql from 'graphql-tag'

const GC_GET_MESSAGE_LIST = gql`
  query GetMessageList {
    message {
      id
      text
      media_id
      character_id
      action_id
    }
  }
`

const GC_ADD_MESSAGE_ONE = gql`
  mutation AddMessageOne(
    $text: String = ""
    $media_id: uuid = ""
    $character_id: uuid = ""
    $action_id: uuid
  ) {
    insert_message_one(
      object: {
        text: $text
        media_id: $media_id
        character_id: $character_id
        action_id: $action_id
      }
    ) {
      id
    }
  }
`

const GC_UPDATE_MESSAGE_BY_ID = gql`
  mutation UpdateMessageOne(
    $id: uuid!
    $text: String = ""
    $media_id: uuid = ""
    $character_id: uuid = ""
    $action_id: uuid
  ) {
    update_message(
      where: { id: { _eq: $id } }
      _set: { text: $text, media_id: $media_id, character_id: $character_id, action_id: $action_id }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`

const GC_DELETE_MESSAGE_ONE = gql`
  mutation DeleteMessage($id: uuid!) {
    delete_message(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`

export { GC_GET_MESSAGE_LIST, GC_ADD_MESSAGE_ONE, GC_UPDATE_MESSAGE_BY_ID, GC_DELETE_MESSAGE_ONE }
