import apollo from '@/apolloClient'

import {
  GC_GET_PROMOTION_TYPE_LIST,
  GC_GET_PROMOTION_LIST,
  GC_GET_PROMOTION_BY_ID,
  GC_ADD_PROMOTION_ONE,
  GC_UPDATE_PROMOTION_BY_ID,
  GC_DELETE_PROMOTION_BY_ID,
} from '@/graphql/promotion'

export default {
  namespaced: true,
  state: {
    list: [],
    items: {},
    typeList: [],
  },
  mutations: {
    SET_PROMOTION_LIST(state, promotions) {
      state.list = promotions || []
    },
    SET_PROMOTION_TYPE_LIST(state, types) {
      state.typeList = types
    },
    SET_PROMOTION(state, promotion) {
      if (!promotion || !promotion.id) return

      state.items[promotion.id] = promotion
    },
  },
  actions: {
    async getTypeList({ commit }) {
      const response = await apollo.query({ query: GC_GET_PROMOTION_TYPE_LIST })

      commit('SET_PROMOTION_TYPE_LIST', response.data.group_type)
    },
    async getList({ commit }) {
      const response = await apollo.query({ query: GC_GET_PROMOTION_LIST })

      commit('SET_PROMOTION_LIST', response.data.group)
    },
    async getByID({ commit }, id) {
      let response = await apollo.query({
        query: GC_GET_PROMOTION_BY_ID,
        variables: { id },
      })

      if (!response.data.group_by_pk) {
        return
      }

      commit('SET_PROMOTION', response.data.group_by_pk)
    },
    async save(context, data) {
      let response = null
      let result = {}

      let promotion = {
        identifier: data.identifier,
        name: data.name,
        start_date: data.startDate,
        end_date: data.endDate,
        course_id: data.course,
        customer_id: data.customer_id,
        promotion_type_id: data.type,
        disabled: data.disabled,
      }

      // Update or add the promotion
      if (data.id) {
        // Set id
        promotion.id = data.id

        // Get current sequences association and their ids
        promotion.sequences = []
        // promotion.sequences_ids = []

        // todo/note : shouldn't this be done in the caller ? (ie I don't have to know/don't care how you structure your data... I need MY format :p)
        // todo: compare to old db data and uniquIFY whith course sequences data ?? (or in the caller ?)
        data.sequences.forEach((sequence) => {
          // promotion.sequences_ids.push(sequence.id)

          promotion.sequences.push({
            group_id: data.id,
            sequence_id: sequence.id,
            user_id: sequence.userID || null,
            start_date: sequence.startDate,
            end_date: sequence.endDate,
          })
        })

        // Get current users association and their ids
        promotion.users = []
        promotion.users_ids = []

        data.users.forEach((user) => {
          promotion.users_ids.push(user.id)

          promotion.users.push({
            group_id: data.id,
            user_id: user.id,
          })
        })

        promotion.scenarios = data.scenarios.map((scenario, idx) => ({
          order: idx,
          scenario_id: scenario.id,
          module_id: data.module_id,
        }))

        promotion.activities_id = [...data.mock_exams, ...data.colles].map(
          (activity) => activity.id
        )

        // Update remote data
        response = await apollo.mutate({
          mutation: GC_UPDATE_PROMOTION_BY_ID,
          variables: promotion,
        })

        result.success = true
      } else {
        // Get current sequences association
        promotion.sequences = data.sequences.map((sequence) => {
          return {
            sequence_id: sequence.id,
            user_id: sequence.userID || null,
            start_date: sequence.startDate,
            end_date: sequence.endDate,
          }
        })

        promotion.users = data.users.map((user) => ({ user_id: user.id }))
        promotion.scenarios = data.scenarios.map((scenario, idx) => ({
          order: idx,
          scenario_id: scenario.id,
        }))

        delete promotion.course_id
        const courseTypeId = context.rootState['Course'].types[0].id

        promotion.activities = [...data.mock_exams, ...data.colles].map((activity) => {
          const game = context.rootState['Games'].list.find((game) => game.id === activity.id)
          const gameTypeId = game.type.id
          const name = game.name
          delete game.type
          delete game.game_type_id
          delete game.name

          return {
            ...game,
            identifier: name,
            activity_type_id: gameTypeId,
            time_per_question: 0,
            points_per_question: 0,
            title: name,
          }
        })

        response = await apollo.mutate({
          mutation: GC_ADD_PROMOTION_ONE,
          variables: {
            ...promotion,
            course_type_id: courseTypeId,
          },
        })

        result.id = response.data.insert_group_one.id
      }

      await context.dispatch('Utils/getUsers', null, { root: true })

      return result
    },
    async delete(context, id) {
      const response = await apollo.mutate({
        mutation: GC_DELETE_PROMOTION_BY_ID,
        variables: { id },
      })

      return response.data.delete_group_by_pk
    },
  },
}
