<template>
  <b-modal
    ref="middle-modal"
    class="middle-panel overflow-auto"
    centered
    hide-footer
    ignore-enforce-focus-selector=".right-pane"
  >
    <template v-slot:modal-title>
      {{ title }}
    </template>
    <div class="bg-white" v-if="tabs">
      <nav class="mb-4 flex flex-col sm:flex-row">
        <button
          v-for="(tab, i) in tabs"
          :key="tab.title"
          class="w-full py-4 px-4 block focus:outline-none hover:text-principal-selected hover:font-principal-medium border-b-2 font-medium border-principal-selected"
          @click="ChangeTab(i)"
          :class="{ 'text-principal-selected font-principal-medium': selectedTab == i }"
        >
          {{ tab.title }}
        </button>
      </nav>

      <div>
        <div v-for="(tab, i) in tabs" :key="tab.slot">
          <slot :name="tab.slot" v-if="selectedTab === i"></slot>
        </div>
      </div>
    </div>
    <slot v-if="!tabs"></slot>
  </b-modal>
</template>
<script>
export default {
  name: 'MiddlePanel',
  data() {
    return {
      isShow: false,
      selectedTab: -1,
      init: false,
    }
  },
  props: {
    defaultTabIndex: {
      type: Number,
      required: false,
      default: 0,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    tabs: {
      type: Array,
      required: false,
    },
    onTabChange: {
      type: Function,
      required: false,
    },
  },
  methods: {
    ChangeTab(i) {
      if (this.onTabChange) this.onTabChange(i)
      this.selectedTab = i
    },
    show() {
      this.isShow = true
      if (this.tabs) {
        if (this.selectedTab == -1) {
          this.selectedTab = this.defaultTabIndex
        }
        if (!this.init) {
          this.tabs.forEach((tab) => {
            if (tab.onInit) {
              tab.onInit(tab.slot)
            }
          })
          this.init = true
          this.ChangeTab(this.selectedTab)
        }
      }
      this.$refs['middle-modal'].show()
      this.$emit('show')
    },
    hide() {
      this.isShow = false
      this.$refs['middle-modal'].hide()
      this.$emit('hide')
    },
  },
}
</script>
<style lang="scss">
.middle-panel {
  @apply absolute bg-white right-0 bottom-0 z-10 w-2/5 p-6;
  .title {
    @apply text-xs uppercase font-principal-bold;
  }
}
</style>
