<template>
  <div class="page-detail mt-8 md:mt-0 px-8">
    <div
      class="buttons flex flex-row justify-around md:justify-between border-b border-gray-400 pb-4 mb-4"
    >
      <h2 class="text-blue text-2xl font-thin">Message en page d’accueil</h2>

      <button
        type="button"
        class="twn-button secondary ml-auto"
        :disabled="hasPendingStoreRequest"
        @click="onSave"
      >
        Publier les changements
      </button>
    </div>

    <div v-if="page">
      <div class="mb-4 flex">
        <Dropzone
          @file-uploaded="onFileUpload"
          :accepted-files="['image/*']"
          type="file_image"
          :page-id="page.id"
          class="mr-4"
          :with-select="false"
        />

        <Dropzone
          @file-uploaded="onFileUpload"
          :accepted-files="['application/pdf']"
          type="file_pdf"
          :page-id="page.id"
          :with-select="false"
        />
      </div>

      <ul v-if="mediasList.length" class="mb-10">
        Fichiers actuels:
        <li v-for="media in mediasList" :key="media.id" class="list">
          <a :href="media.path" target="_blank" class="text-blue underline">{{
            media.title || media.name
          }}</a>
          {{ media.used ? '' : '(Non utilisé dans le document)' }}
          <button type="button" @click="deleteMedia(media)" v-if="!media.used">
            <b-icon-x class="text-xl text-red-600 mb-0"></b-icon-x>
          </button>
        </li>
      </ul>

      <TextEditor v-model="page.content" :medias="page.medias" :page-id="page.id" all-features />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import TextEditor from '@/components/base/TextEditor.vue'
import Dropzone from '@/components/Dropzone'
import dispatchStoreRequest from '@/mixins/dispatchStoreRequest'

export default {
  name: 'PageDetail',

  mixins: [dispatchStoreRequest],

  components: {
    TextEditor,
    Dropzone,
  },

  computed: {
    ...mapState('Page', ['page']),

    mediasList() {
      return this.page.medias.map((media) => ({
        ...media,
        used: this.page.content.includes(media.path),
        path: process.env.VUE_APP_AWS_S3_URL + '/' + media.path,
      }))
    },
  },

  async mounted() {
    await this.dispatchStoreRequest('Page/getPage')
  },

  methods: {
    onSave() {
      const response = this.dispatchStoreRequest('Page/savePage', { page: this.page })
      if (!response.error) {
        this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })
      }
    },

    async onFileUpload() {
      const savedContent = this.page.content
      await this.dispatchStoreRequest('Page/getPage', null, true)
      this.page.content = savedContent
    },

    async deleteMedia(media) {
      await this.dispatchStoreRequest('Assets/deleteFile', media)
      await this.onFileUpload()
    },
  },
}
</script>
