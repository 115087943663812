<template>
  <div>
    <h3 class="text-2xl text-blue">Commentaires {{ course.name }} - {{ course.title }}</h3>

    <div style="max-height: 500px; overflow-y: auto" ref="comments" class="flex flex-col p-2">
      <Comment
        v-for="(comment, id) in comments"
        :key="id"
        :comment="comment"
        @validate="onValidate"
        @refuse="onRefuse"
        @make-public="onMakePublic"
      />
    </div>

    <!-- Text box -->
    <div class="mt-4 flex space-x-4">
      <input
        type="text"
        class="block twn-input w-full"
        placeholder="Votre commentaire"
        v-model="message"
      />
      <button type="button" class="twn-button" @click="onSubmitMessage">Envoyer</button>
    </div>
  </div>
</template>

<script>
import apollo from '@/apolloClient'
import {
  GC_GET_ACTIVITY_COMMENTS,
  GC_INSERT_ACTIVITY_COMMENT,
  GC_UPDATE_ACTIVITY_COMMENT,
} from '@/graphql/comments.js'
import { GC_INSERT_NOTIFICATIONS } from '../../graphql/comments'
import Comment from './Comment.vue'
import { uniqBy } from '@/utils/utils'

export default {
  components: { Comment },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    isLoading: false,
    comments: [],
    message: '',
  }),

  watch: {
    course: {
      immediate: true,
      async handler(activity) {
        await this.fetchActivityComments(activity.id)
      },
    },
  },

  methods: {
    /**
     * Fetch the comments of the given activity
     */
    async fetchActivityComments(activityId) {
      this.isLoading = true
      const response = await apollo.query({
        query: GC_GET_ACTIVITY_COMMENTS,
        variables: {
          activityId,
        },
      })
      this.comments = response.data.activity_by_pk.comments

      /**
       * Scroll to bottom of comment list
       */
      if (this.$refs.comments) {
        this.$nextTick(() => {
          this.$refs.comments.scrollTop = this.$refs.comments.scrollHeight
        })
      }

      this.isLoading = false
    },

    /**
     * Insert the new comment in Hasura
     */
    async onSubmitMessage() {
      if (this.message.length === 0) {
        return
      }

      this.isLoading = true

      await apollo.mutate({
        mutation: GC_INSERT_ACTIVITY_COMMENT,
        variables: {
          activityId: this.course.id,
          content: this.message,
          userId: this.$store.state.Auth.userInfo.id,
        },
      })

      this.message = ''
      await this.fetchActivityComments(this.course.id)
      this.isLoading = false
    },

    async onValidate(comment) {
      this.isLoading = true
      await apollo.mutate({
        mutation: GC_UPDATE_ACTIVITY_COMMENT,
        variables: { commentId: comment.id, validated: true, public: true },
      })
      await this.fetchActivityComments(this.course.id)

      const userNotification = {
        activity_id: this.course.id,
        type: 'COMMENT-VALIDATED',
        user_id: comment.user.id,
      }

      const usersThatPreviouslyCommented = uniqBy(
        this.comments,
        (comment) => comment.user.id
      ).filter((oldComment) => oldComment.user.id !== comment.user.id)

      const otherNotifications = usersThatPreviouslyCommented
        .map((comment) => ({
          activity_id: this.course.id,
          type: 'NEW-COMMENT',
          user_id: comment.user.id,
        }))
        .filter(Boolean)

      await apollo.mutate({
        mutation: GC_INSERT_NOTIFICATIONS,
        variables: { notifications: [userNotification, ...otherNotifications] },
      })

      this.isLoading = false
    },

    async onRefuse(comment) {
      this.isLoading = true
      await apollo.mutate({
        mutation: GC_UPDATE_ACTIVITY_COMMENT,
        variables: { commentId: comment.id, validated: false, public: false },
      })
      await this.fetchActivityComments(this.course.id)

      this.isLoading = false
    },

    async onMakePublic(comment) {
      this.isLoading = true
      await apollo.mutate({
        mutation: GC_UPDATE_ACTIVITY_COMMENT,
        variables: { commentId: comment.id, public: true, validated: false },
      })
      await this.fetchActivityComments(this.course.id)
      this.isLoading = false
    },
  },
}
</script>
