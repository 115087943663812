<template>
  <div class="scenarios">
    <List
      ref="list"
      listing="scenario"
      :fields="fields"
      :filters="filters"
      :where-filter="whereFilter"
      :default-sort-by="'location'"
      :default-sort-desc="false"
      modify-button-text="Éditer le Scénario"
      :with-delete-button="false"
      :searchText="'Rechercher ' + typeSlugText"
      editRouteName="scenario-edit"
      :customRouteParams="() => ({ typeSlug: $route.params.typeSlug })"
    >
      <template v-slot:buttons>
        <div class="md:flex flex-row justify-around hidden md:w-1/3">
          <button
            type="button"
            class="twn-button secondary whitespace-nowrap overflow-hidden text-xs mr-2"
            @click="addScenario"
          >
            {{ 'Créer ' + typeSlugText }}
          </button>
        </div>
      </template>
      <template v-slot:fab>
        <vue-fab
          class="md:hidden"
          fabItemAnimate="alive"
          fabAliveAnimateBezier="ease-in-out"
          mainBtnColor="#fff"
          size="big"
        >
          <fab-item
            class="twn-fab add"
            @clickItem="addScenario"
            color="#DC9799"
            :idx="0.5"
            title="Ajouter un scénario"
            icon="add"
          />
        </vue-fab>
      </template>
    </List>
  </div>
</template>

<script>
import List from '@/components/List'

import dispatchStoreRequest from '@/mixins/dispatchStoreRequest'

export default {
  name: 'ScenarioList',
  components: { List },
  mixins: [dispatchStoreRequest],
  props: {
    typeSlug: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      filters: [],
    }
  },

  computed: {
    whereFilter() {
      return `type: { slug: {_eq: ${this.$route.params.typeSlug} } }`
    },
    fields() {
      const commonFields = [
        {
          key: 'identifier',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'title',
          label: 'Nom',
          sortable: true,
        },
      ]

      if (this.$route.params.typeSlug === 'basic') {
        return commonFields.concat([
          {
            key: 'subject',
            label: 'Matière',
            sortable: true,
          },
          {
            key: 'nodes_aggregate',
            label: 'Cours',
            nested: 'count',
            db: `nodes_aggregate {
              aggregate {
                count
              }
            }`,
          },
          {
            key: 'semester',
            label: 'Semestre',
            sortable: true,
            formatter: (value, key, item) => {
              return item.semester === 'FIRST' ? 'Premier' : 'Deuxième'
            },
          },
        ])
      }

      return commonFields.concat([
        {
          key: 'nodes_aggregate',
          label: 'Chapitres',
          nested: 'count',
          db: `nodes_aggregate {
            aggregate {
              count
            }
          }`,
        },
      ])
    },

    typeSlugText() {
      return this.$route.params.typeSlug === 'basic' ? 'une UE' : 'un Cours'
    },
  },

  /**
   * Since some routes share this same view, when a param change we need to refresh it
   */
  watch: {
    '$route.params.typeSlug': function () {
      this.$refs.list.getList()
    },
  },

  methods: {
    addScenario() {
      this.$router.push({
        name: 'scenario-edit',
        params: { typeSlug: this.$route.params.typeSlug },
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
