<template>
  <div class="game-simple">
    <Draggable v-model="questions" handle=".handle" v-bind="dragOptions">
      <div class="" v-for="(question, i) in questions" :key="i">
        <QuestionSimple
          class="my-5"
          :question="question"
          :number="question._uniqueIdx || i"
          :canAddQuestion="canAddQuestion"
          :hasPicture="hasPicture"
          :hasLabel="true"
          :option="{ multipleAnswers: true, noCorrection: true }"
          @delete-question="deleteQuestion"
          @update-question="updateQuestion"
        />
      </div>
    </Draggable>

    <div
      v-if="canAddQuestion"
      @click="addQuestion"
      class="plus m-auto bg-orange text-white font-bold rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl"
    >
      +
    </div>
  </div>
</template>
<script>
import Draggable from 'vuedraggable'
import QuestionSimple from '@/components/Games/Question_simple'

export default {
  name: 'QuizSimple',
  components: {
    QuestionSimple,
    Draggable,
  },
  props: {
    game: {
      type: Object,
      required: true,
      default: null,
    },
    withParts: {
      type: Boolean,
      default: false,
    },
    part: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    questions: {
      get() {
        if (!this.game) return []

        if (this.withParts) {
          const questions = JSON.parse(JSON.stringify(this.game.questions))
            .map((question, idx) => {
              return {
                ...question,
                ...(this.withParts && {
                  _uniqueIdx: idx,
                }),
              }
            })
            .filter((question) => question.part === this.part)

          return questions
        }

        return JSON.parse(JSON.stringify(this.game.questions))
      },
      set(value) {
        this.$emit('update-all-questions', value)
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    canAddQuestion() {
      const oneQuestionQuizType = ['quiz', 'quiz_picture']

      return (
        (this.questions && this.questions.length <= 0) ||
        oneQuestionQuizType.indexOf(this.game.type.slug) == -1
      )
    },
    hasPicture() {
      const pictureQuizType = ['quiz_picture']

      return pictureQuizType.indexOf(this.game.type.slug) > -1
    },
  },
  watch: {
    game: {
      handler(game) {
        // Automaticly add one question if none provided
        if (game && game.questions.length <= 0) {
          this.addQuestion()
        }
      },
      immediate: true,
    },
  },
  methods: {
    addQuestion() {
      let question = {
        answers: [
          {
            text: '',
            is_correct: false,
          },
        ],
        text: '',
        correction: '',
        ...(this.withParts && {
          part: this.part,
          _uniqueIdx: this.game.questions.length,
        }),
      }

      this.$emit('add-questions', [question])
    },
    updateQuestion(i, data) {
      this.$emit('update-question', i, data)
    },
    deleteQuestion(i) {
      this.$emit('delete-questions', [i])
    },
  },
}
</script>

<style lang="scss"></style>
