import apollo from '@/apolloClient'

import { GC_GET_PAGE_LIST, GC_UPDATE_PAGE, GC_ADD_PAGE_ONE } from '@/graphql/page'

export default {
  namespaced: true,
  state: {
    page: null,
  },
  mutations: {
    SET_PAGE(state, page) {
      state.page = page
    },
  },
  actions: {
    async getPage({ commit, state }) {
      const response = await apollo.query({ query: GC_GET_PAGE_LIST, fetchPolicy: 'no-cache' })
      const page = response.data.page[0] || {
        content: '',
        medias: [],
      }
      commit('SET_PAGE', page)
      return state.page
    },

    async savePage({ state }, data) {
      const query = {
        mutation: data.page.id ? GC_UPDATE_PAGE : GC_ADD_PAGE_ONE,
        variables: {
          content: data.page.content,
          id: data.page.id,
        },
      }
      const response = await apollo.mutate(query)

      if (!data.page.id) {
        state.page.id = response.data.insert_page_one.id
      }

      return response
    },
  },
}
