import apollo from '@/apolloClient'
import {
  GC_GET_COURSE_LIST,
  GC_GET_COURSE_TYPES,
  GC_ADD_COURSE_ONE,
  GC_GET_COURSE,
  GC_GET_COURSE_ID_BY_TYPE,
  GC_GET_COURSE_BY_TYPE,
  GC_DELETE_COURSE,
  GC_UPDATE_COURSE,
  GC_CHECK_NAME,
  GC_GET_COURSE_LOGS,
} from '@/graphql/course'
import { GC_GET_SCENARIO_TYPE_LIST } from '@/graphql/scenario'

export default {
  namespaced: true,
  state: {
    list: [],
    types: [],
    scenarioTypes: [],
  },
  mutations: {
    SET_COURSE_LIST(state, courses) {
      state.list = courses || []
    },
  },
  getters: {
    typeListFormat(state) {
      return state.types.reduce((dict, type) => {
        dict[type.id] = type

        return dict
      }, {})
    },
  },
  actions: {
    async getList({ commit }) {
      const response = await apollo.query({ query: GC_GET_COURSE_LIST })

      commit('SET_COURSE_LIST', response.data.course)
    },
    async Delete({ state }, id) {
      const response = await apollo.mutate({
        mutation: GC_DELETE_COURSE,
        variables: { id },
      })

      return response.data.delete_course_by_pk
    },
    async GetCourse({ state }, id) {
      const response = await apollo.query({
        query: GC_GET_COURSE,
        variables: {
          id,
        },
      })

      return response.data.course_by_pk
    },
    async GetCourseIDByType(context, typeSlug) {
      const response = await apollo.query({
        query: GC_GET_COURSE_ID_BY_TYPE,
        variables: {
          typeSlug,
        },
      })

      return (response.data.course && response.data.course[0] && response.data.course[0].id) || null
    },
    async GetCourseByType(context, typeSlug) {
      const response = await apollo.query({
        query: GC_GET_COURSE_BY_TYPE,
        variables: {
          typeSlug,
        },
      })

      return (response.data.course && response.data.course[0]) || null
    },
    async GetCourseTypes({ state }) {
      const response = await apollo.query({
        query: GC_GET_COURSE_TYPES,
      })

      state.types = response.data.course_type
    },
    async GetScenarioTypes({ state }) {
      const response = await apollo.query({
        query: GC_GET_SCENARIO_TYPE_LIST,
      })

      state.scenarioTypes = response.data.course_type
    },
    async GetCourseLogs({ state }) {
      const response = await apollo.query({
        query: GC_GET_COURSE_LOGS,
      })

      return response.data.app_log
    },
    async NameTaken({ state }, { identifier, id }) {
      const response = await apollo.query({
        query: GC_CHECK_NAME,
        variables: {
          identifier,
        },
      })

      if (
        (response.data.course.length > 0 && !id) ||
        (response.data.course.length > 0 && id && response.data.course[0].id !== id)
      ) {
        return true
      }
    },
    async saveCourse({ state, dispatch }, course) {
      // Check name (todo:)
      let isTaken = await dispatch('NameTaken', { identifier: course.identifier, id: course.id })

      if (isTaken) {
        return false
      }

      //         // todo: Sanity check for course of type bloc
      //         if (course.type && course.type.slug == 'bloc_course') {
      // // Delete modules > 0
      // if (course.modules.length > 1){
      // 	course.modules.splice(1, course.modules.length - 1);
      // }
      //         }

      // Add course base data
      let variables = {
        identifier: course.identifier || '',
        title: course.title || '',
        description: course.description || '',
        type: course.type,
      }

      // Format metas data
      variables.metas = {
        data: course.metas.map((meta) => {
          const course_id = meta.course_id || course.id

          if (course_id) {
            meta.course_id = course_id
          }

          return meta
        }),
      }

      // Format modules data
      variables.modules = {
        data: course.modules.map((module, index) => {
          const data = {
            identifier: module.identifier || '',
            title: module.title || '',
            order: module.order || index,
            metas: {
              data: module.metas,
            },
            media: {
              data: module.media,
            },
            scenarios: {
              data: module.scenarios,
            },
            sequences: {
              data: module.sequences.map((sequence, index) => {
                // Get list of associated media
                let media = []

                if (sequence.media) {
                  media = sequence.media.map((media, index) => {
                    return {
                      media_id: media.id,
                      order: media.order || index,
                    }
                  })
                }

                const data = {
                  identifier: sequence.identifier || '',
                  title: sequence.title || '',
                  order: sequence.order || index,
                  scenarios: {
                    data: sequence.scenarios,
                  },
                  media: {
                    data: media,
                  },
                  metas: {
                    data: sequence.metas,
                  },
                }

                if (sequence.id) {
                  data.id = sequence.id
                }

                return data
              }),
            },
          }

          if (module.id) {
            data.id = module.id
          }

          const course_id = module.course_id || course.id

          if (course_id) {
            data.course_id = course_id
          }

          return data
        }),
      }

      let response = null

      if (course.id) {
        // Set id
        variables.id = course.id

        // Get existing meta ids
        const existingMetas = variables.metas.data.filter((meta) => {
          return meta.id ? true : false
        })

        variables.meta_ids = existingMetas.map((meta) => meta.id)

        // Get existing module ids
        const existingModules = variables.modules.data.filter((module) => {
          return module.id ? true : false
        })

        variables.module_ids = existingModules.map((module) => module.id)

        // List all module meta associations (existing or new) and create condition expression to delete old associations
        const moduleMetasData = existingModules.reduce(
          (data, module) => {
            if (!module.metas.data) return data

            // Add new condition rules (to delete existing associations that are no longer in the current list)
            data.deleteCondition._or.push({
              module_id: { _eq: module.id },
              id: {
                _nin: module.metas.data.reduce((ids, meta) => {
                  if (meta.id) {
                    ids.push(meta.id)
                  }

                  return ids
                }, []),
              },
            })

            // List all meta associations in existing module (to be able to insert new ones and update existing one)
            module.metas.data.forEach((meta) => {
              meta.module_id = module.id

              data.list.push(meta)
            })

            return data
          },
          {
            deleteCondition: { _or: [] },
            list: [],
          }
        )

        variables.delete_module_metas_condition = moduleMetasData.deleteCondition
        variables.module_meta_list = moduleMetasData.list

        // List all module media associations (existing or new) and create condition expression to delete old associations
        const moduleMediaData = existingModules.reduce(
          (data, module) => {
            if (!module.media.data) return data

            // Add new condition rules (to delete existing associations that are no longer in the current list)
            data.deleteCondition._or.push({
              module_id: { _eq: module.id },
              medium_id: {
                _nin: module.media.data.reduce((ids, medium) => {
                  if (medium.medium_id) {
                    ids.push(medium.medium_id)
                  }

                  return ids
                }, []),
              },
            })

            // List all media associations in existing module (to be able to insert new ones and update existing one)
            module.media.data.forEach((medium, index) => {
              data.list.push({
                module_id: module.id,
                medium_id: medium.medium_id,
                order: medium.order || index,
              })
            })

            return data
          },
          {
            deleteCondition: { _or: [] },
            list: [],
          }
        )

        variables.delete_module_media_condition = moduleMediaData.deleteCondition
        variables.module_media_list = moduleMediaData.list

        // List all module scenario associations (existing or new) and create condition expression to delete old associations
        const moduleScenarioData = existingModules.reduce(
          (data, module) => {
            if (!module.scenarios.data) return data

            // Add new condition rules (delete existing associations not in the current list)
            data.deleteCondition._or.push({
              module_id: { _eq: module.id },
              scenario_id: {
                _nin: module.scenarios.data.reduce((ids, scenario) => {
                  if (scenario.scenario_id) {
                    ids.push(scenario.scenario_id)
                  }

                  return ids
                }, []),
              },
            })

            // List all scenario associations in existing module
            module.scenarios.data.forEach((scenario, index) => {
              data.list.push({
                module_id: module.id,
                scenario_id: scenario.scenario_id,
                order: scenario.order || index,
              })
            })

            return data
          },
          {
            deleteCondition: { _or: [] },
            list: [],
          }
        )

        variables.delete_module_scenarios_condition = moduleScenarioData.deleteCondition
        variables.module_scenario_list = moduleScenarioData.list

        // List all module sequences (existing or new) and create condition expression to delete old ones
        const moduleSequenceData = existingModules.reduce(
          (data, module) => {
            if (!module.sequences.data) return data

            // Add new condition rules (delete existing sequence not in the current list)
            data.deleteCondition._or.push({
              module_id: { _eq: module.id },
              id: {
                _nin: module.sequences.data.reduce((ids, sequence) => {
                  if (sequence.id) {
                    ids.push(sequence.id)
                  }

                  return ids
                }, []),
              },
            })

            // List all sequence in existing module
            module.sequences.data.forEach((sequence) => {
              data.list.push({
                id: sequence.id,
                identifier: sequence.identifier,
                order: sequence.order,
                title: sequence.title,
                scenarios: sequence.scenarios,
                media: sequence.media,
                metas: sequence.metas,
                module_id: module.id,
              })
            })

            return data
          },
          {
            deleteCondition: { _or: [] },
            list: [],
          }
        )

        variables.delete_sequence_condition = moduleSequenceData.deleteCondition
        variables.module_sequence_list = moduleSequenceData.list.map((sequence) => {
          // Remove extra data (scenarios, ...) for existing ones
          if (sequence.id) {
            return {
              id: sequence.id,
              identifier: sequence.identifier,
              title: sequence.title,
              order: sequence.order,
              module_id: sequence.module_id,
            }
          }

          return sequence
        })

        // List all sequence scenario and media associations (existing or new) and create condition expression to delete old associations
        const sequenceAssocData = moduleSequenceData.list.reduce(
          (data, sequence) => {
            if (!sequence.id) return data

            if (!sequence.scenarios.data && !sequence.media.data && !sequence.metas.data)
              return data

            if (sequence.scenarios.data) {
              // Add new condition rules (delete existing associations not in the current list)
              data.deleteScenarioCondition._or.push({
                sequence_id: { _eq: sequence.id },
                scenario_id: {
                  _nin: sequence.scenarios.data.reduce((ids, scenario) => {
                    if (scenario.scenario_id) {
                      ids.push(scenario.scenario_id)
                    }

                    return ids
                  }, []),
                },
              })

              // List all scenario associations in existing sequence
              sequence.scenarios.data.forEach((scenario) => {
                data.scenarioList.push({
                  sequence_id: sequence.id,
                  scenario_id: scenario.scenario_id,
                  order: scenario.order,
                })
              })
            }

            if (sequence.media.data) {
              // Add new condition rules (delete existing associations not in the current list)
              data.deleteMediaCondition._or.push({
                sequence_id: { _eq: sequence.id },
                media_id: {
                  _nin: sequence.media.data.reduce((ids, media) => {
                    if (media.media_id) {
                      ids.push(media.media_id)
                    }

                    return ids
                  }, []),
                },
              })

              // List all media associations in existing sequence
              sequence.media.data.forEach((media) => {
                data.mediaList.push({
                  sequence_id: sequence.id,
                  media_id: media.media_id,
                  order: media.order,
                })
              })
            }

            if (sequence.metas.data) {
              // Add new condition rules (to delete existing associations that are no longer in the current list)
              data.deleteMetaCondition._or.push({
                sequence_id: { _eq: sequence.id },
                id: {
                  _nin: sequence.metas.data.reduce((ids, meta) => {
                    if (meta.id) {
                      ids.push(meta.id)
                    }

                    return ids
                  }, []),
                },
              })

              // List all meta associations in existing sequence (to be able to insert new ones and update existing one)
              sequence.metas.data.forEach((meta) => {
                meta.sequence_id = sequence.id

                data.metaList.push(meta)
              })
            }

            return data
          },
          {
            deleteScenarioCondition: { _or: [] },
            scenarioList: [],
            deleteMediaCondition: { _or: [] },
            mediaList: [],
            deleteMetaCondition: { _or: [] },
            metaList: [],
          }
        )

        variables.delete_sequence_scenarios_condition = sequenceAssocData.deleteScenarioCondition
        variables.sequence_scenario_list = sequenceAssocData.scenarioList

        variables.delete_sequence_media_condition = sequenceAssocData.deleteMediaCondition
        variables.sequence_media_list = sequenceAssocData.mediaList

        variables.delete_sequence_metas_condition = sequenceAssocData.deleteMetaCondition
        variables.sequence_meta_list = sequenceAssocData.metaList

        // Re-format/flatten metas data
        variables.metas = variables.metas.data.map((meta) => {
          return meta
        })

        // Re-format modules data
        variables.modules = variables.modules.data.map((module) => {
          // Remove extra data (sequences, scenarios, ...) for existing ones
          if (module.id) {
            return {
              id: module.id,
              identifier: module.identifier,
              title: module.title,
              order: module.order,
              course_id: module.course_id,
            }
          }

          return module
        })

        // Update course data
        response = await apollo.mutate({
          mutation: GC_UPDATE_COURSE,
          variables,
        })
      } else {
        // Format type
        if (!variables.course_type_id && variables.type) {
          variables.course_type_id = variables.type
          delete variables.type
        }

        // Insert new course data
        response = await apollo.mutate({
          mutation: GC_ADD_COURSE_ONE,
          variables: {
            course: variables,
          },
        })
      }

      return response.data.insert_course_one
    },
  },
}
