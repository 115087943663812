<template>
  <div class="game px-8" v-if="game">
    <!-- Header -->
    <div class="game-header">
      <div class="buttons flex flex-row justify-between items-start">
        <router-link
          class="twn-button text-xs"
          :to="{ name: 'activity-list', params: { type: gameTypeSlug } }"
        >
          Retour
        </router-link>
        <div class="buttons-right flex items-center flex-wrap ml-4">
          <div class="flex items-center mr-4">
            <span :class="!game.is_public ? 'text-blue' : 'text-gray-disabled'">Payant</span>
            <Toggle
              class="mx-2"
              :checked="game.is_public"
              :onInput="() => (game.is_public = !game.is_public)"
            ></Toggle>
            <span :class="game.is_public ? 'text-blue' : 'text-gray-disabled'">Gratuit</span>
          </div>

          <div class="flex items-center mr-4">
            <span :class="!game.is_published ? 'text-blue' : 'text-gray-disabled'">Caché</span>
            <Toggle
              class="mx-2"
              :checked="game.is_published"
              :onInput="() => (game.is_published = !game.is_published)"
            ></Toggle>
            <span :class="game.is_published ? 'text-blue' : 'text-gray-disabled'">Publié</span>
          </div>

          <button
            @click="deleteGame"
            class="twn-button danger text-xs danger mr-4"
            type="button"
            :disabled="!itemID || hasPendingStoreRequest"
          >
            Supprimer
          </button>

          <button
            v-if="canPreviewGame"
            @click="previewGame"
            :disabled="!itemID || hasPendingStoreRequest"
            class="twn-button text-xs mr-4"
            type="button"
          >
            Prévisualiser
          </button>

          <button
            @click="saveGame"
            class="twn-button secondary text-xs"
            type="button"
            :disabled="hasPendingStoreRequest"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>

    <!-- Game common fields -->
    <div class="pb-6 mb-10 mt-12 border-gray-light">
      <!-- Left block -->
      <div
        class="inline-block align-top pr-4"
        :class="{ 'w-2/3': jauges && jauges.length > 0, 'w-full': !jauges || jauges.length <= 0 }"
      >
        <div class="flex space-x-4">
          <!-- Identifier and title -->
          <div class="form-group inline-block">
            <label for="game-name" class="uppercase font-principal-medium text-sm"
              >Identifiant *</label
            >
            <b-form-input
              v-model="game.name"
              type="text"
              id="game-name"
              class="w-full"
              :state="isDefined(game.name)"
            ></b-form-input>
          </div>

          <!-- Name -->
          <div class="form-group inline-block pl-2 flex-1">
            <label for="game-title" class="uppercase font-principal text-sm">Nom *</label>
            <b-form-input
              v-model="game.title"
              type="text"
              id="game-title"
              class="w-full"
              :state="isDefined(game.title)"
            ></b-form-input>
          </div>

          <div
            v-if="type && (type.slug === 'colle' || type.slug === 'mock_exam')"
            class="flex space-x-4"
          >
            <div class="form-group inline-block">
              <label class="uppercase text-sm">Début</label>
              <input class="form-control" type="datetime-local" v-model="game.start_at" />
            </div>
            <div class="form-group inline-block">
              <label class="uppercase text-sm">Fin</label>
              <input class="form-control" type="datetime-local" v-model="game.end_at" />
            </div>
          </div>
        </div>

        <!-- Instruction -->
        <div class="form-group">
          <label for="game-instruction" class="uppercase font-principal-medium text-sm"
            >Description</label
          >
          <b-form-textarea
            v-model="game.instruction"
            type="text"
            rows="4"
            id="game-instruction"
            class="w-full"
            placeholder="Description"
          />
        </div>
      </div>
      <!-- Right block -->
      <div
        v-if="jauges && jauges.length > 0"
        class="inline-block w-1/3 align-top pl-4 border-gray-light border-l-1"
      >
        <div class="form-group">
          <label for="game-score" class="uppercase font-principal-medium text-sm w-full"
            >Permet de remporter :</label
          >
          <b-form-input
            v-model="scoreValue"
            type="text"
            id="game-score"
            class="inline-block w-1/4 pr-2"
          />
          <v-select
            id="jauges"
            class="twn-select inline-block pl-2 w-3/4"
            label="name"
            placeholder="Choisissez une jauge"
            :options="jauges"
            :clearable="false"
            v-model="scoreType"
            :reduce="(jauge) => jauge.id"
          />
        </div>

        <div class="form-group">
          <label for="game-correction" class="uppercase font-principal-medium text-sm w-full"
            >Message de correction :</label
          >
          <b-form-input id="game-correction" v-model="game.correction" />
        </div>

        <div class="form-group">
          <label for="game-video" class="uppercase font-principal-medium text-sm w-full"
            >Video expert :</label
          >
          <v-select
            id="game-video"
            class="twn-select"
            placeholder="Choisissez une vidéo"
            :getOptionLabel="getMediaLabel"
            :options="videoList"
            :reduce="(medium) => medium.id"
            v-model="video"
          />
        </div>
      </div>
    </div>

    <hr class="my-8 bg-blue opacity-50" />

    <div class="mb-8" v-if="game.type.slug == 'quiz_simple'">
      <div v-for="(v, k) in pdfs" :key="k" class="mb-5">
        <h3 class="text-3xl text-blue font-thin">{{ v }}</h3>

        <div class="flex mt-3 items-stretch">
          <Dropzone
            :current-file="game[k + 'Media'] ? game[k + 'Media'].path : undefined"
            @file-uploaded="game[k + 'Media'] = $event"
            media-type-slug="file_pdf"
            :accepted-files="['application/pdf']"
            type="file_pdf"
          />
          <TextEditor v-model="game[k + '_content']" class="ml-4 w-full" />
        </div>
      </div>

      <div>
        <h3 class="text-3xl text-blue font-thin">Vidéo</h3>

        <div class="flex mt-3 items-stretch">
          <Dropzone
            :current-file="game.videoMedia ? game.videoMedia.path : undefined"
            @file-uploaded="game.videoMedia = $event"
            media-type-slug="file_video"
            :accepted-files="['video/*']"
            type="file_video"
          />
          <TextEditor v-model="game.video_content" class="ml-4 w-full" />
        </div>
      </div>
    </div>

    <h3 class="text-3xl text-blue font-thin mb-4">
      <div v-if="game.type.slug === 'colle'">Colle</div>
      <div v-else-if="game.type.slug === 'mock_exam'">Parties</div>
      <div v-if="game.type.slug === 'quiz_simple'">QCM</div>
    </h3>

    <div class="flex space-x-8">
      <div class="w-2/3 border-r border-blue border-opacity-50 pr-8">
        <label for="game-help" class="uppercase font-principal-medium text-sm"
          >Titre / Consigne</label
        >
        <b-form-input
          v-model="game.help"
          type="text"
          id="game-help"
          class="w-full"
          placeholder="Titre / Consigne"
        />
      </div>
      <div class="w-1/3">
        <label class="uppercase font-principal-medium text-sm">Gain par bonne réponse *</label>
        <b-form-input
          v-model="game.points_per_question"
          type="number"
          class="w-full"
          :state="isDefined(game.points_per_question)"
        />
        <label class="uppercase font-principal-medium text-sm mt-4"
          >Temps par question (secondes) *</label
        >
        <b-form-input
          v-model="game.time_per_question"
          type="number"
          class="w-full"
          :state="isDefined(game.time_per_question)"
        />
      </div>
    </div>

    <div v-if="game.type.slug === 'mock_exam'" class="mt-4">
      <div class="flex items-center">
        <div
          v-for="(part, idx) in getMetaValue('activity-parts')"
          :key="idx"
          @click="selectedPartIdx = idx"
          :class="{
            'bg-blue text-white': selectedPartIdx === idx,
            'border border-blue px-4 py-2 cursor-pointer': true,
          }"
        >
          {{ part.label }}
        </div>
        <div
          class="ml-2 text-lg text-blue font-bold cursor-pointer"
          @click="getMetaValue('activity-parts').push({ label: `Partie ${parts.length + 1}` })"
        >
          +
        </div>
      </div>

      <div class="mt-4">
        <button
          type="button"
          v-show="getMetaValue('activity-parts').length > 1 && selectedPartIdx !== 0"
          class="mt-4 twn-button danger float-right"
          @click="
            ;(selectedPartIdx -= 1), getMetaValue('activity-parts').splice(selectedPartIdx + 1, 1)
          "
        >
          Supprimer
        </button>

        <div class="flex space-x-4">
          <div>
            <label>Nom *</label>
            <b-form-input
              v-model="getMetaValue('activity-parts')[selectedPartIdx].label"
              :state="isDefined(getMetaValue('activity-parts')[selectedPartIdx].label)"
            ></b-form-input>
          </div>
        </div>

        <div class="flex space-x-4 mt-4">
          <div>
            <label>Début</label>
            <input
              class="form-control"
              v-model="getMetaValue('activity-parts')[selectedPartIdx].start"
              type="datetime-local"
              value="2018-06-12T19:30"
            />
          </div>
          <div>
            <label>Fin</label>
            <input
              class="form-control"
              v-model="getMetaValue('activity-parts')[selectedPartIdx].end"
              type="datetime-local"
              value="2018-06-12T19:30"
            />
          </div>
        </div>
      </div>

      <QuizSimple
        :is="gameComponent"
        :game="game"
        with-parts
        :part="selectedPartIdx"
        @add-questions="addQuestions"
        @update-question="updateQuestion"
        @delete-questions="deleteQuestions"
        @update-all-questions="updateAllQuestions"
        @update-correction="updateCorrection"
        @update-game-media="updateGameMedia"
        :getGameMetaValue="getMetaValue"
        @update-meta-value="setMetaValue($event.slug, $event.value)"
      />
    </div>

    <div v-else>
      <!-- Type specific game editing component -->
      <component
        :is="gameComponent"
        :game="game"
        @add-headers="addHeaders"
        @add-questions="addQuestions"
        @update-exec-time="updateExecuteTime"
        @update-prep-time="updatePrepareTime"
        @update-header="updateHeader"
        @update-question="updateQuestion"
        @delete-headers="deleteHeaders"
        @delete-questions="deleteQuestions"
        @update-all-questions="updateAllQuestions"
        @update-correction="updateCorrection"
        @update-game-media="updateGameMedia"
        :getGameMetaValue="getMetaValue"
        @update-meta-value="setMetaValue($event.slug, $event.value)"
      />
    </div>

    <!-- Footer -->
    <div class="mt-20 w-full md:w-1/2 lg:w-1/3 mb-4" v-if="modifRecord.length > 0">
      <div class="cursor-pointer select-none" @click="modifRecordOpen = !modifRecordOpen">
        <svg
          class="inline-block mr-2"
          :class="{ 'transform rotate-180': modifRecordOpen }"
          height="15px"
          id="Layer_1"
          version="1.1"
          viewBox="0 0 26 15"
          width="26px"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <polygon
            fill="#231F20"
            points="23.303,-0.002 12.467,10.834 1.63,-0.002 -0.454,2.082 12.467,15.002 14.551,12.918 25.387,2.082   "
          />
        </svg>
        <span v-if="modifRecordOpen">Cacher</span>
        <span v-else>Afficher</span> l'historique des modifications
      </div>

      <div v-if="modifRecordOpen" class="mt-4">
        <div
          class="my-4 flex flex-row text-sm justify-between w-full pr-4"
          v-for="record in modifRecord"
          :key="record.created_at"
        >
          <div>{{ formatDate(record.created_at) }}</div>
          <div v-if="record.user">{{ record.user.first_name }} {{ record.user.name }}</div>
          <div v-else>-</div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <b-modal
      ref="delete-modal-game"
      class="bootstrap"
      centered
      hide-footer
      id="delete-modal-game"
      hide-header
    >
      <div class="d-block text-center my-6 uppercase font-semibold">
        <h3>Confirmer la suppression</h3>
      </div>
      <div class="flex flex-row justify-evenly items-center">
        <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal-game')">
          Retour
        </button>
        <button type="button" class="mt-4 twn-button danger" @click="confirmDelete">
          Supprimer
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import dispatchStoreRequest from '@/mixins/dispatchStoreRequest'

import Toggle from '@/components/Toggle'
import TrueFalse from '@/components/common/game/TrueFalse'
import DragDrop from '@/components/common/game/DragDrop'
import QuizSimple from '@/components/common/game/QuizSimple'
import QuizTable from '@/components/common/game/QuizTable'
import CellByCell from '@/components/common/game/CellByCell'
import Reorder from '@/components/common/game/Reorder'
import Vocabulary from '@/components/common/game/Vocabulary'
// import GapFill from '@/components/common/game/GapFill'
import Hotspots from '@/components/common/game/Hotspots'
import Messages from '@/components/common/game/Messages'
import Conversations from '@/components/common/game/Conversations'
import Survey from '@/components/common/game/Survey'

import Medias from '@/views/Medias'
import RightPanel from '@/components/RightPanel'
import MiddlePanel from '@/components/MiddlePanel'
import { mapState } from 'vuex'
import moment from 'moment'
import Dropzone from '../components/Dropzone.vue'
import TextEditor from '@/components/base/TextEditor.vue'

// import gql from "graphql-tag"

import browserEventBus from '@/utils/browserEventBus'

export default {
  name: 'GameCreation',
  mixins: [dispatchStoreRequest],
  props: {
    gameTypeSlug: {
      type: String,
      required: true,
      default: null,
    },
    itemID: {
      type: String,
      required: false,
      default: null,
    },
  },
  components: {
    // GapFill,
    RightPanel,
    MiddlePanel,
    Medias,
    Toggle,
    Dropzone,
    QuizSimple,
    TextEditor,
  },
  data() {
    return {
      selectedPartIdx: 0,
      parts: [
        {
          label: 'Partie 1',
        },
      ],
      modifRecord: [],
      modifRecordOpen: false,
      currentComponent: null,
      game: null,
      nameTaken: null,
      type: null,
      selectedMedia: null,
      pdfs: {
        pdf: 'Fiche de cours',
        update: 'Actualisation',
        session: 'Séance à préparer',
        details: 'Précision',
        annals: 'Annales',
      },
      tabOptions: [
        {
          title: 'Instructions',
          slot: 'instructions',
        },
        {
          title: 'Scores',
          slot: 'score-jauge',
        },
        // {
        // 	title: 'Médias',
        // 	slot: 'medias'
        // },
      ],
    }
  },
  async created() {
    await this.dispatchStoreRequest('Games/getTypeList')
    await this.dispatchStoreRequest('Games/getQuestionTypeList')

    this.type = this.$store.state.Games.type_list.find((type) => {
      return type.slug == this.gameTypeSlug
    })
    if (!this.type) return //todo: go back ?

    await this.dispatchStoreRequest('Games/getJaugeList')
    await this.dispatchStoreRequest('Assets/getAssetList')

    if (!this.itemID) {
      // Create a new game of the specified game type
      this.game = {
        name: '',
        title: 'Nouveau chapitre',
        instruction: '',
        help: '',
        correction: '',
        type: this.type,
        metas: [],
        is_public: this.gameTypeSlug === 'quiz_simple',
        is_published: true,
        points_per_question: 2,
        time_per_question: 60,
        medias: [],
        scores: [],
        headers: [],
        questions: [],
        show_correction: true,
      }

      if (['colle', 'mock_exam'].includes(this.gameTypeSlug)) {
        const today = new Date().toISOString().split('T')[0] + 'T00:00'
        this.game.start_at = today
        this.game.end_at = today

        if (this.gameTypeSlug === 'mock_exam') {
          this.setMetaValue('activity-parts', [
            {
              label: 'Partie 1',
              start: today,
              end: today,
            },
          ])
        }
      }
    }
  },
  computed: {
    ...mapState({
      videoList: (state) =>
        state.Assets.assets.filter((medium) => ['video'].indexOf(medium.type.slug) > -1),
      assets(state) {
        if (!state.Assets.assets || state.Assets.assets.length <= 0) return {}

        return state.Assets.assets.reduce((dict, asset) => {
          dict[asset.id] = asset

          return dict
        }, {})
      },
      metaTypes: (state) => {
        return state.Utils.metaTypeList.reduce((dict, meta) => {
          dict[meta.slug] = meta
          return dict
        }, {})
      },
    }),
    // ...mapState("Games", {
    // 	jauges(state) {if (!state.jauges || state.jauges.length <= 0) return {}
    // 		return state.jauges.reduce((dict, jauge) => {
    // 			dict[jauge.id] = jauge

    // 			return dict
    // 		}, {})
    // 	}
    // }),
    ...mapState('Games', ['jauges']),
    gameComponent() {
      if (this.game) {
        switch (this.game.type.slug) {
          case 'true_false':
            return TrueFalse

          case 'dragdrop':
          case 'dragdrop_picture':
          case 'drag_drop':
            return DragDrop

          case 'quiz':
          case 'quiz_simple':
          case 'quiz_picture':
          case 'colle':
          case 'mock_exam':
            return QuizSimple

          case 'quiz_table':
            return QuizTable

          case 'cell_by_cell':
            return CellByCell

          case 'reorder':
            return Reorder

          case 'vocabulary':
            return Vocabulary

          // case 'gap_fill':
          //   return GapFill

          case 'hotspots':
            return Hotspots

          case 'message_gallery':
          case 'message_phone':
            return Messages

          case 'conversation':
            return Conversations

          case 'survey':
            return Survey
        }
      }

      return null
    },
    canPreviewGame() {
      return true
    },
    hasCorrection() {
      if (this.game) {
        switch (this.game.type.slug) {
          case 'quiz':
          case 'quiz_simple':
          case 'true_false':
            return true
        }
      }

      return false
    },
    video: {
      get() {
        if (!this.game || !this.game.medias || this.game.medias.length <= 0) return null

        const medium = this.game.medias.find((medium) => medium.order == -1)

        return medium ? medium.media_id : null
      },
      set(medium_id) {
        this.updateGameVideo(medium_id)
      },
    },
    scoreValue: {
      get() {
        if (!this.game || !this.game.scores || this.game.scores.length <= 0) return 0

        const scoreData = this.game.scores[0]

        return scoreData.score
      },
      set(value) {
        if (this.game.scores.length <= 0) {
          let scoreData = {
            score: value,
            score_type_id: this.scoreType,
          }

          this.game.scores.push(scoreData)
        } else {
          this.game.scores[0].score = value
        }
      },
    },
    scoreType: {
      get() {
        if (!this.game || !this.game.scores || this.game.scores.length <= 0) return null

        const scoreData = this.game.scores[0]

        return scoreData.score_type_id
      },
      set(type) {
        if (this.game.scores.length <= 0) {
          let scoreData = {
            score: this.scoreValue,
            score_type_id: type,
          }

          this.game.scores.push(scoreData)
        } else {
          this.game.scores[0].score_type_id = type
        }
      },
    },
  },
  watch: {
    itemID: {
      async handler(id) {
        // Load current game data, if needed
        if (id) {
          // Get game from store
          // todo: handle invalid uuid response
          await this.dispatchStoreRequest('Games/getByID', id, true)

          // Create a deep local copy of the store data
          this.game = JSON.parse(JSON.stringify(this.$store.state.Games.items[id]))
          this.modifRecord = this.game.contentLogs
          delete this.game.contentLogs

          // todo: common/cleaner system
          document.title = this.game.name + ' ' + this.game.title

          this.game.metas.forEach((meta) => {
            meta.value = JSON.parse(meta.value)
          })
          return
        }

        if (this.game && this.game.type.slug === 'mock_exam') {
          this.setMetaValue('activity-parts', [
            {
              label: 'Partie 1',
            },
          ])
        }
      },
      immediate: true,
    },
  },
  methods: {
    getMediaLabel(medium) {
      return [medium.title, medium.name].filter((str) => str || false).join(' - ')
    },
    formatDate(dt) {
      return moment(new Date(dt)).locale('fr').format('LLLL')
    },
    async duplicateGame() {
      delete this.game.id
      this.game.title = this.game.title + ' (copie)'
      this.$router.push({
        name: 'activity-edit',
      })
    },
    async saveGame() {
      if (!!this.game.name && !!this.game.title) {
        this.nameTaken = null
        const response = await this.dispatchStoreRequest('Games/save', this.game)
        if (response.error) {
          this.nameTaken = true
          return
        }
        this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })
        if (!this.itemID && response.id) {
          // Emit browser event for activity add
          browserEventBus.emitAdd(browserEventBus.eventCategory.activity, {
            id: response?.id,
            parentId: this.$route.query && this.$route.query.parentId,
          })

          this.$router.push({
            name: 'activity-edit',
            params: {
              itemID: response.id,
            },
          })
        } else {
          // Emit browser event for activity update
          browserEventBus.emitUpdate(browserEventBus.eventCategory.activity, {
            id: this.itemID,
            parentId: this.$route.query && this.$route.query.parentId,
          })
        }
      } else {
        this.$bvToast.toast("Une erreur est survenue, l'un des champs requis doit être manquant", {
          title: 'Erreur de champs',
          solid: true,
          autoHideDelay: 5000,
        })
      }
    },
    deleteGame() {
      if (this.itemID) {
        this.$refs['delete-modal-game'].show()
      }
    },
    async confirmDelete() {
      await this.dispatchStoreRequest('Games/deleteGameByID', this.game.id)

      // Emit browser event for activity delete
      browserEventBus.emitDelete(browserEventBus.eventCategory.activity, {
        id: this.game.id,
        parentId: this.$route.query && this.$route.query.parentId,
      })

      this.$router.push({ name: 'activity-list', params: { type: this.gameTypeSlug } })
    },
    previewGame() {
      let url = '/'

      if (this.gameTypeSlug === 'quiz_simple') {
        if (!this.game.usedInNodes[0]) {
          this.$bvToast.toast(
            "Impossible de prévisualiser le contenu, celui-ci n'est rattaché à aucun programme.",
            {
              title: `Erreur`,
            }
          )
          return
        }
        const { semester, identifier } = this.game.usedInNodes[0].scenario
        url += `semestre/${semester}/ues/${identifier}/courses/${this.game.name}`
      } else {
        url += (this.gameTypeSlug === 'colle' ? 'colle/' : 'concours/') + this.game.name
      }

      window.open(encodeURI(process.env.VUE_APP_FRONT_URL + url + '?preview'), '_blank')
    },
    addHeaders(headers) {
      for (var i = 0; i < headers.length; i++) {
        this.game.headers.push(headers[i])
      }
    },
    addQuestions(questions) {
      for (var i = 0; i < questions.length; i++) {
        this.game.questions.push(questions[i])
      }
    },
    updateHeader(index, headerData) {
      const dataKeys = Object.keys(headerData)

      for (var i = 0; i < dataKeys.length; i++) {
        this.game.headers[index][dataKeys[i]] = headerData[dataKeys[i]]
      }
    },
    updateQuestion(index, questionData) {
      const dataKeys = Object.keys(questionData)

      for (var i = 0; i < dataKeys.length; i++) {
        this.game.questions[index][dataKeys[i]] = questionData[dataKeys[i]]
      }
    },
    updateAllQuestions(questions) {
      this.game.questions = questions
    },
    updateCorrection(correction) {
      this.game.correction = correction
    },
    updateGameMedia(media_id) {
      const medium = this.game.medias.find((medium) => medium.order == 0)

      if (!medium) {
        let media = {
          media_id,
          order: 0,
        }

        this.game.medias.push(media)
      } else {
        for (var i = 0; i < this.game.medias.length; i++) {
          if (this.game.medias[i].order == 0) {
            this.game.medias[i].media_id = media_id
            break
          }
        }
      }
    },
    updateGameVideo(media_id) {
      const medium = this.game.medias.find((medium) => medium.order == -1)

      if (!medium) {
        let media = {
          media_id,
          order: -1,
        }

        this.game.medias.push(media)
      } else {
        for (var i = 0; i < this.game.medias.length; i++) {
          if (this.game.medias[i].order == -1) {
            this.game.medias[i].media_id = media_id
            break
          }
        }
      }
    },
    deleteHeaders(headerIndexes) {
      for (var i = 0; i < headerIndexes.length; i++) {
        this.game.headers.splice(headerIndexes[i], 1)
      }
    },
    deleteQuestions(questionIndexes) {
      let sorted = questionIndexes.sort((a, b) => a - b)
      for (var i = sorted.length - 1; i >= 0; i--) {
        this.game.questions.splice(sorted[i], 1)
      }
    },
    updatePrepareTime(time) {
      this.game.prepareTime = time
    },
    updateExecuteTime(time) {
      this.game.executeTime = time
    },
    DropAssetInGame(e, i) {
      if (e.dataTransfer) {
        const assetJSON = e.dataTransfer.getData('application/json')
        const asset = assetJSON ? JSON.parse(assetJSON) : null
        if (asset.type.slug === 'pdf' || asset.type.slug === 'image') {
          this.game.medias[i].media_id = asset.id
          this.$refs['pdfPanel'].hide()
        }
      }
    },
    allowDrop: function (event) {
      event.preventDefault()
    },
    ShowRightPanel(i) {
      if (i !== undefined && i !== null) {
        this.selectedMedia = i
      }
      this.$refs['pdfPanel'].show()
    },
    AssignMediaToGame(asset) {
      if (
        asset &&
        this.selectedMedia !== undefined &&
        this.selectedMedia !== null &&
        (asset.type.slug === 'pdf' || asset.type.slug === 'image')
      ) {
        this.game.medias[this.selectedMedia].media_id = asset.id
      }
    },
    DeleteGamePDF(id) {
      let index = this.game.medias.indexOf(id)
      this.game.medias.splice(index, 1)
    },
    SelectJauge(val) {
      this.game.score_type_id = val
    },
    AddDefaultScore() {
      let score = {
        score: 0,
        score_type_id: this.jauges && this.jauges.length > 0 ? this.jauges[0].id : null,
      }
      this.game.scores.push(score)
    },
    DeleteJauge(i) {
      this.game.scores.splice(i, 1)
    },
    DeleteMedia(i) {
      this.selectedMedia = null
      this.game.medias.splice(i, 1)
    },
    AddDefaultMedia() {
      let media = {
        media_id: null,
        order: this.game.medias.length,
      }
      this.game.medias.push(media)
      this.selectedMedia = this.game.medias.length - 1
    },
    ShowParameterPanel() {
      this.$refs['gameOptions'].show()
    },
    getMeta(slug) {
      if (!this.metaTypes[slug]) return { meta: { value: '~ Meta value error ~' }, index: -1 }

      for (var i = 0; i < this.game.metas.length; i++) {
        if (this.game.metas[i].meta_type_id == this.metaTypes[slug].id) {
          return { meta: this.game.metas[i], index: i }
        }
      }

      return { meta: null, index: -1 }
    },
    getMetaValue(slug, defaultValue = null) {
      const { meta } = this.getMeta(slug)

      return (meta && meta.value) || defaultValue
    },
    setMetaValue(slug, value) {
      // Try to find existing meta with this slug
      let { meta, index } = this.getMeta(slug)

      // Update, add or remove meta value
      if (meta) {
        if (value != null) {
          meta.value = value
        } else {
          this.game.metas.splice(index, 1)
        }
      } else if (value) {
        this.game.metas.push({
          value,
          meta_type_id: this.metaTypes[slug].id,
        })
      }
    },
    isDefined(v) {
      // eslint-disable-next-line
      return !!v ? null : false
    },
  },
}
</script>
<style lang="scss" scoped>
.game {
  .jauges-score,
  .pdf {
    width: 40%;
  }
}
</style>
<style lang="scss">
.preview {
  .pdf_thumbnail {
    @apply w-8 mr-4;
  }
  .img_thumbnail {
    @apply w-16 mr-4;
  }
}
</style>
