<template>
  <div class="authentication-container">
    <div class="flex justify-center items-center mb-4">
      <img class="h-20" src="/logo.svg" alt="logo" />
    </div>
    <div class="input-fields">
      <b-form @submit="onSubmit">
        <b-form-group id="input-group-email" label="Email:" label-for="input-email">
          <b-form-input
            id="input-email"
            v-model="email"
            type="text"
            required
            placeholder="Entrez votre adresse mail"
            :disabled="hasPendingRequest"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-password" label="Mot de passe:" label-for="input-password">
          <b-form-input
            id="input-password"
            v-model="password"
            type="password"
            required
            placeholer="..."
            :disabled="hasPendingRequest"
          ></b-form-input>
        </b-form-group>
        <div class="flex flex-col items-center">
          <div
            class="mt-0 mb-3 md:self-start flex flex-col md:flex-row md:w-full md:justify-between"
          >
            <b-form-checkbox
              id="input-checkbox"
              value="true"
              unchecked-value=""
              v-model="remember"
              :disabled="hasPendingRequest"
              >Se souvenir de moi</b-form-checkbox
            >
            <div class="text-red-600 text-center">{{ error }}</div>
          </div>
          <a class="mb-3" @click="handleForgotPasswordClick" href="#">Mot de passe oublié ?</a>
          <div class="mb-3">
            <b-button type="submit" variant="primary" :disabled="hasPendingRequest">
              Se connecter
            </b-button>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Authentication',
  data() {
    return {
      hasPendingRequest: false,
      email: '',
      password: '',
      remember: '',
      error: null,
    }
  },
  async mounted() {
    const hashMatchs = document.location.hash.match(/#token=(.*)&expires=(.*)/)
    if (hashMatchs && hashMatchs[1]) {
      await this.loginWthToken({ token: hashMatchs[1], expires: hashMatchs[2] })

      await this.$router.push({ name: 'users-list' })
    }
  },
  computed: {
    ...mapGetters('Auth', ['getErrorMessage']),
  },
  methods: {
    ...mapActions('Auth', ['login', 'loginWthToken']),

    async handleForgotPasswordClick() {
      this.error = null

      if (this.hasPendingRequest) return
      if (!this.email) {
        this.error = 'Veuillez renseigner votre email !'
        return
      }

      this.hasPendingRequest = true
      this.error = null

      try {
        await this.$store.dispatch('Auth/forgotPassword', { email: this.email }).catch(() => {})
      } finally {
        this.$bvToast.toast(
          'Si votre email est correct, vous allez recevoir un email de réinitialisation de mot de passe.',
          { title: 'Mot de passe oublié', solid: true, autoHideDelay: 5000 }
        )
        this.hasPendingRequest = false
      }
    },

    async onSubmit(evt) {
      evt.preventDefault()

      if (this.hasPendingRequest) return

      this.hasPendingRequest = true
      this.error = null

      if (this.email && this.password) {
        try {
          await this.login({
            email: this.email,
            password: this.password,
            remember: this.remember ? true : false,
          })

          await this.$router.push({ name: 'users-list' })
        } catch (err) {
          this.error = 'Email ou mot de passe incorrect !'
          this.hasPendingRequest = false
          return
        }
      }

      this.hasPendingRequest = false
    },
  },
}
</script>

<style lang="scss" scoped>
.authentication-container {
  @apply bg-white h-screen flex flex-col items-center justify-center;

  @screen md {
    @apply justify-center;
    .input-fields {
      @apply w-1/3;
    }
  }
}
</style>
