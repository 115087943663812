import gql from 'graphql-tag'

const GC_GET_CUSTOMER_LIST = gql`
  query GetCustomerList {
    customer(order_by: { created_at: desc }) {
      id
      identifier
      name
    }
  }
`

const GC_GET_CUSTOMER_BY_ID = gql`
  query CustomerByID($id: uuid!) {
    customer_by_pk(id: $id) {
      id
      identifier
      name
      managers {
        user_id
      }
      groups {
        id
      }
    }
  }
`

const GC_ADD_CUSTOMER_ONE = gql`
  mutation AddCustomer($customer: customer_insert_input!) {
    insert_customer_one(object: $customer) {
      id
    }
  }
`

/*
	1) Update customer base data
	2) Delete managers that are no longer associated with this customer
	3) Insert newly associated managers or update existing ones
*/
const GC_UPDATE_CUSTOMER_BY_ID = gql`
  mutation UpdateCustomer(
    $id: uuid!
    $customer: customer_set_input!
    $manager_ids: [uuid!]!
    $managers: [customer_manager_insert_input!]!
  ) {
    ## Update customer base data
    update_customer_by_pk(pk_columns: { id: $id }, _set: $customer) {
      id
    }

    ## Delete old managers
    delete_customer_manager(where: { customer_id: { _eq: $id }, user_id: { _nin: $manager_ids } }) {
      affected_rows
    }

    ## Insert new managers or update base data for existing ones
    insert_customer_manager(
      objects: $managers
      on_conflict: { constraint: customer_manager_pkey, update_columns: [] }
    ) {
      affected_rows
    }
  }
`

const GC_DELETE_CUSTOMER_BY_ID = gql`
  mutation DeleteCustomer($id: uuid!) {
    delete_customer_by_pk(id: $id) {
      id
      identifier
      name
    }
  }
`

const GC_ADD_CUSTOMER_TO_GROUP = gql`
  mutation AddCustomerToGroup($customer_id: uuid!, $group_id: uuid!) {
    update_group_by_pk(pk_columns: { id: $group_id }, _set: { customer_id: $customer_id }) {
      id
    }
  }
`

const GC_REMOVE_CUSTOMER_FROM_GROUP = gql`
  mutation RemoveCustomerFromGroup($group_id: uuid!) {
    update_group_by_pk(pk_columns: { id: $group_id }, _set: { customer_id: null }) {
      id
    }
  }
`

export {
  GC_GET_CUSTOMER_LIST,
  GC_GET_CUSTOMER_BY_ID,
  GC_ADD_CUSTOMER_ONE,
  GC_UPDATE_CUSTOMER_BY_ID,
  GC_DELETE_CUSTOMER_BY_ID,
  GC_ADD_CUSTOMER_TO_GROUP,
  GC_REMOVE_CUSTOMER_FROM_GROUP,
}
