<template>
  <div class="media-container px-8">
    <div class="grid grid-cols-2 gap-x-8">
      <div class="flex flex-col h-full justify-between">
        <div class="search flex items-center">
          <div class="flex form-control mr-4">
            <img class="mr-2" src="@/assets/images/LOUPE.svg" alt="search icon" />
            <input
              v-model="searchStr"
              type="text"
              id="search"
              class="placeholder-italic"
              placeholder="Rechercher un média"
              ref="search"
              spellcheck="false"
            />
          </div>

          <button class="text-blue text-sm rounded-sm border-1 border-blue h-8 px-5">OK</button>
        </div>

        <div class="mt-4">
          <v-select
            class="style-chooser text-sm w-1/3 mr-auto bg-gray-light"
            placeholder="Type"
            label="name"
            multiple
            :options="typesList"
            v-model="filter.item"
            :reduce="(f) => f.id"
          >
            <template v-slot:option="option">
              <span class="capitalize">{{ option.name }}</span>
            </template>
            <template v-slot:selected-option="option">
              <span class="capitalize">{{ option.name }}</span>
            </template>
          </v-select>
        </div>
        <div class="flex items-center">
          <span :class="cardView ? 'text-blue' : 'text-gray-disabled'">Vue liste</span>
          <Toggle class="mx-2" :checked="cardView" :onInput="() => (cardView = !cardView)"></Toggle>
          <span :class="!cardView ? 'text-blue' : 'text-gray-disabled'">Vue grille</span>
        </div>
      </div>

      <div class="drop-zone">
        <vue-auto-dropzone
          ref="dz"
          :options="options"
          @success="success"
          @thumbnail="setThumbnail"
          :include-styling="true"
          v-slot="{ files }"
          class="twn-dropzone cursor-pointer hover:text-principal-selected"
        >
          <div class="dz-message text-sm">Ajouter un ou plusieurs fichiers</div>
          <div class="flex w-full justify-around">
            <figure v-for="file in files" :key="file.upload.uuid">
              <img v-if="file.dataURL" :src="file.dataURL" :alt="file.name" />
              <figcaption class="text-principal-gray">
                <div class="name leading-none overflow">
                  {{ file.name }}
                </div>
                <br />
                <span v-if="file.upload.progress !== 100"
                  >{{ file.upload.progress.toFixed(0) }}%</span
                >
              </figcaption>
            </figure>
          </div>
          <div class="buttons flex justify-around w-full">
            <button
              type="button"
              class="twn-button text-sm"
              v-if="files.length > 0"
              @click="openUploadModal"
            >
              Confimer
            </button>
            <button
              type="button"
              class="twn-button danger text-sm"
              v-if="files.length > 0"
              @click="$refs.dz.removeAllFiles()"
            >
              Reset
            </button>
          </div>
        </vue-auto-dropzone>
      </div>
    </div>
    <div>
      <div class="intermediary flex flex-row justify-between items-center mb-5"></div>
      <div class="list">
        <div
          class="card-list w-full flex-wrap justify-center md:justify-start items-center"
          v-bind:class="[cardView ? 'flex' : 'hidden']"
        >
          <div
            class="card hover:shadow-twn bg-assets-background relative w-56 h-56 md:w-28 md:h-28 mr-6 mb-6"
            :class="{ 'cursor-pointer': selectOnly }"
            v-for="(asset, i) in filteredAssetList.slice(
              (page - 1) * pageSize,
              (page - 1) * pageSize + pageSize
            )"
            :key="asset.id"
            :draggable="draggableAsset"
            @dragstart="cardDrag($event, asset)"
            @dragend="dragEnd"
            @click="selectMedia(asset, i, $event)"
          >
            <div class="thumb absolute w-full h-full">
              <img
                class="w-full h-full object-contain"
                :src="getThumbnailForAsset(asset)"
                alt=""
                draggable="false"
              />
            </div>

            <div
              v-if="!selectOnly"
              @click="showDeleteModal(asset, $event)"
              class="cursor-pointer flex justify-center items-center absolute top-0 right-0 w-10 h-10 lg:w-6 lg:h-6"
            >
              <div class="block lg:hidden">
                <b-icon-x class="h1 text-red-600 mb-0"></b-icon-x>
              </div>
              <div class="hidden lg:block">
                <b-icon-x class="h3 text-red-600 mb-0"></b-icon-x>
              </div>
            </div>
            <div
              class="absolute opacity-75 bottom-0 p-1 w-full description bg-white flex flex-col justify-between rounded-b overflow-hidden"
            >
              <div class="md:text-xs">{{ asset.name }}</div>
              <div class="md:text-xs w-full text-right absolute bottom-0 bg-white px-2 h-5">
                {{ asset.type.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="b-list" v-bind:class="[cardView ? 'hidden' : 'block']">
          <b-table
            class="twn-table min-w-0 bootstrap"
            responsive="lg"
            borderless
            :busy="tableBusy"
            :items="filteredAssetList"
            :fields="fields"
            :filter="filter"
            :tbody-tr-class="{ 'cursor-pointer': selectOnly }"
            :current-page="page"
            :per-page="pageSize"
            @row-clicked="selectMedia"
          >
            <template v-slot:cell(action)="data">
              <div
                v-if="!selectOnly"
                class="hidden lg:flex flex-row justify-around text-xs show-actions"
              >
                <button
                  @click="showDeleteModal(data.item, $event)"
                  type="button"
                  class="twn-button danger"
                >
                  Supprimer
                </button>
              </div>
              <div
                v-if="!selectOnly"
                v-click-outside="clickOutsideSubmenu"
                @click="showEditMenu(data.item.id, $event)"
                class="flex lg:hidden justify-center w-full"
              >
                <div
                  class="relative flex-row justify-around text-xs leading-tight w-1/4 items-center"
                >
                  • <br />
                  • <br />
                  •
                  <div
                    class="rounded-lg edit-menu shadow-twn absolute flex-col justify-center h-24 bg-white px-3"
                    v-bind:class="[mobile_edit_menu == data.item.id ? 'flex' : 'hidden']"
                  >
                    <div
                      @click="showDeleteModal(data.item, $event)"
                      class="flex items-center justify-center px-10 text-principal-selected"
                    >
                      Supprimer
                    </div>
                    <div
                      @click="closeSubmenu"
                      class="cursor-pointer w-6 h-6 mt-1 mr-1 flex justify-center items-center absolute top-0 right-0 text-lg font-principal"
                    >
                      <b-icon-x></b-icon-x>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination-nav
          class="flex justify-center style-paginate"
          v-model="page"
          :link-gen="linkGen"
          :number-of-pages="numPage"
          :per-page="pageSize"
          use-router
        />
      </div>
    </div>

    <!-- Modals -->
    <b-modal
      ref="upload-modal"
      centered
      id="upload-modal"
      hide-footer
      title="Choissez un type pour chaque media"
      v-if="$refs.dz"
    >
      <div class="flex justify-between mb-4 items-center">
        <div class="font-principal-bold">Tout les fichiers</div>
        <v-select
          :options="typesList"
          label="name"
          @input="bulkTypeSelect"
          class="twn-select w-1/2"
        >
        </v-select>
      </div>
      <hr />

      <div v-for="(file, i) in $refs.dz.files" :key="i" class="">
        <div class="w-full flex flex-row justify-between my-2">
          <div class="name w-3/5 pr-4">{{ file.name }}</div>
          <div class="selectType w-1/2">
            <v-select
              :options="typesList"
              label="name"
              class="twn-select"
              @input="$forceUpdate()"
              v-bind:class="file.incorrectType === false ? 'is-invalid' : ''"
              v-model="file.assetType"
            >
            </v-select>
          </div>
        </div>
        <hr class="bg-line-top w-full" />
      </div>
      <div class="flex flex-row justify-evenly items-center">
        <button type="button" class="mt-4 twn-button" @click="confirmTypes">Confirmer</button>
      </div>
    </b-modal>
    <b-modal
      ref="delete-modal-media"
      class="bootstrap"
      centered
      hide-footer
      id="delete-modal-media"
      hide-header
    >
      <div class="d-block text-center my-6 font-semibold" v-if="selectedFile">
        <h3 class="uppercase">Confirmer la suppression</h3>
        <div
          v-if="selectedFile.targets && selectedFile.targets.length > 0"
          class="my-4 font-principal font-normal"
        >
          Attention, le média sera supprimé aux emplacements suivants : <br />
          <div class="inline" v-for="(target, i) in selectedFile.targets" :key="i">
            <span>{{ target.type }} : </span>
            <span>{{ target.name }}</span> <br />
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-evenly items-center">
        <button
          type="button"
          class="mt-4 twn-button"
          @click="!isSaving ? $bvModal.hide('delete-modal-media') : ''"
        >
          Retour
        </button>
        <button type="button" class="mt-4 twn-button danger" @click="deleteItem">Supprimer</button>
      </div>
    </b-modal>
    <b-modal
      ref="detail-media-modal"
      id="detail-media-modal"
      centered
      hide-footer
      title="Détails du fichier joint"
      size="xl"
    >
      <div
        class="flex flex-row items-center w-full h-full"
        :class="{ saving: isSaving }"
        v-if="selectedFile"
      >
        <div class="flex items-center flex-col justify-center w-1/2">
          <div v-if="selectedFile.type.slug == 'file_image'">
            <img :src="selectedFile.url" alt="" />
          </div>
          <div
            v-else-if="
              selectedFile.type.slug == 'file_pdf' || selectedFile.name.indexOf('.pdf') > -1
            "
          >
            <pdf :src="selectedFile.url"></pdf>
          </div>
          <div
            v-else-if="
              selectedFile.type.slug == 'integrated_video' ||
              selectedFile.type.slug == 'file_video' ||
              selectedFile.type.slug == 'call_video' ||
              selectedFile.name.indexOf('.mp4') > -1
            "
          >
            <video controls :src="selectedFile.url"></video>
          </div>
          <div v-else-if="selectedFile.type.slug == 'audio'">
            <audio controls :src="selectedFile.url"></audio>
          </div>
          <input type="file" ref="inputFile" hidden class="input-file" @change="chooseFile" />
          <button class="twn-button mt-4" @click="changeFile">Changer le fichier</button>
        </div>
        <div class="p-4 w-1/2">
          <div class="detail-input">
            <div class="form-label">Nom du fichier</div>
            <div class="text-sm w-2/3">{{ selectedFile.name }}</div>
          </div>
          <div class="detail-input">
            <label class="form-label" for="form-title">Titre</label>
            <input
              class="twn-input"
              type="text"
              id="form-title"
              v-model="selectedFile.title"
              @blur="updateMedia"
            />
          </div>
          <div class="detail-input">
            <label class="form-label" for="form-description">Description</label>
            <input
              class="twn-input"
              type="text"
              id="form-description"
              v-model="selectedFile.description"
              @blur="updateMedia"
            />
          </div>
          <div class="detail-input">
            <label class="form-label" for="form-author">Type</label>
            <v-select
              :options="typesList"
              label="name"
              class="twn-select w-2/3"
              @input="updateMedia"
              v-model="selectedFile.type"
              :clearable="false"
            >
            </v-select>
          </div>
          <div class="detail-input">
            <label class="form-label" for="form-author">Téléversement</label>
            <div class="w-2/3" v-if="selectedFile.author">
              Le <span class="p-0 m-0">{{ formatDate(selectedFile.author.created_at) }} </span>par
              <span v-if="selectedFile.author.user">
                {{ selectedFile.author.user.first_name }} {{ selectedFile.author.user.name }}</span
              >
              <span v-else>-</span>
            </div>
          </div>
          <div class="detail-input">
            <label class="form-label" for="form-linked">Utilisé par</label>
            <div
              class="w-2/3 flex flex-col"
              v-if="selectedFile.targets && selectedFile.targets.length > 0"
            >
              <div v-for="(target, i) in selectedFile.targets" :key="i">
                <router-link :to="target.path" target="_blank"
                  >{{ target.type }} : {{ target.name }}</router-link
                >
              </div>
            </div>
            <div class="w-2/3 flex flex-col" v-else>Non assigné</div>
          </div>
          <div class="detail-input">
            <label class="form-label" for="form-copy">Lien</label>
            <a target="_blank" class="link text-blue w-2/3" :href="selectedFile.url">{{
              selectedFile.url
            }}</a>
          </div>
          <div
            class="mt-5 text-center"
            v-if="selectedFile.updates && selectedFile.updates.length > 0"
          >
            <div class="cursor-pointer select-none" @click="modifRecordOpen = !modifRecordOpen">
              <svg
                class="inline-block mr-2"
                :class="{ 'transform rotate-180': modifRecordOpen }"
                height="15px"
                id="Layer_1"
                version="1.1"
                viewBox="0 0 26 15"
                width="26px"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <polygon
                  fill="#231F20"
                  points="23.303,-0.002 12.467,10.834 1.63,-0.002 -0.454,2.082 12.467,15.002 14.551,12.918 25.387,2.082   "
                />
              </svg>
              <span v-if="modifRecordOpen">Cacher</span>
              <span v-else>Afficher</span> l'historique des modifications
            </div>

            <div v-if="modifRecordOpen" class="record-list mt-4 px-5">
              <div
                class="my-4 flex flex-row text-sm justify-between w-full pr-4"
                v-for="record in selectedFile.updates"
                :key="record.created_at"
              >
                <div>{{ formatDate(record.created_at) }}</div>
                <div v-if="record.user">{{ record.user.first_name }} {{ record.user.name }}</div>
                <div v-else>-</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import VueAutoDropzone from 'vue-auto-dropzone'
import defaultImage from '@/assets/images/thumbnails/martine.png'
import pdf from 'vue-pdf'
import { GC_GET_ASSET_BY_ID, GC_UPDATE_MEDIA } from '@/graphql/assets'
import moment from 'moment'
import Toggle from '../components/Toggle.vue'

export default {
  name: 'Medias',
  props: {
    selectOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    draggableAsset: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultTypeFilterSlug: {
      type: Array,
      required: false,
      default: () => [],
    },
    onDragStart: {
      type: Function,
      required: false,
    },
    onDragEnd: {
      type: Function,
      required: false,
    },
  },
  components: {
    VueAutoDropzone,
    pdf,
    Toggle,
  },
  async created() {
    await this.getTypesList()
    await this.getAssetList()
    this.$emit('media-loaded')
  },
  async mounted() {
    const dz = this.$refs.dz
    dz.setOption('accept', this.acceptFile)
    dz.setOption('sending', this.sending)
  },
  data() {
    return {
      page: 1,
      pageSize: 60,
      isSaving: false,
      options: {
        url: this.getUploadUrl,
        method: 'PUT',
        parallelUploads: 1,
        uploadMultiple: false,
        header: '',
        chunking: true,
        maxFileSize: 50000,
        autoProcessQueue: false,
      },
      modifRecordOpen: false,
      bulkType: null,
      searchStr: '',
      cardView: true,
      tableBusy: false,
      includedFields: ['searchStr', 'filter'],
      selectedFile: null,
      mobile_edit_menu: -1,
      filter: {
        placeholder: 'Types',
        item: [],
      },
      fields: [
        {
          key: 'title',
          label: 'Titre',
          sortable: true,
        },
        {
          key: 'type.name',
          label: 'Type',
          sortable: true,
        },
        {
          key: 'activities_aggregate',
          nested: 'count',
          label: 'Utilisations',
          formatter: (value, key, item) => {
            let count = 0

            count += item.activities_aggregate.aggregate.count
            count += item.questions_aggregate.aggregate.count
            count += item.answers_aggregate.aggregate.count
            count += item.modules_aggregate.aggregate.count
            count += item.sequences_aggregate.aggregate.count
            count += item.nodes_aggregate.aggregate.count
            count += item.activitiesByPdfMediaId_aggregate.aggregate.count
            count += item.activitiesByUpdateMediaId_aggregate.aggregate.count
            count += item.activitiesBySessionMediaId_aggregate.aggregate.count
            count += item.activitiesByDetailsMediaId_aggregate.aggregate.count
            count += item.activitiesByAnnalsMediaId_aggregate.aggregate.count
            count += item.activitiesByVideoMediaId_aggregate.aggregate.count
            if (item.page_id) count += 1
            if (item.planning_id) count += 1

            return count
          },
          sortable: true,
        },
        {
          key: 'name',
          label: 'Nom du fichier',
          sortable: true,
        },
        {
          key: 'action',
          sortable: false,
          label: '',
        },
      ],
      fileEvents: [],
    }
  },
  watch: {
    'typesList'(newList) {
      if (this.defaultTypeFilterSlug && this.defaultTypeFilterSlug.length && newList.length > 0) {
        this.page = 1
        this.filter.item = []
        // Get the media type instance coresponding to this slug
        for (var i = 0; i < this.defaultTypeFilterSlug.length; i++) {
          let index = newList.findIndex((type) => type.slug == this.defaultTypeFilterSlug[i])
          if (index !== -1) {
            this.filter.item.push(newList[index].id)
          }
        }
      } else {
        this.filter.item = []
      }
    },
    'defaultTypeFilterSlug'(newType) {
      if (newType && this.typesList.length > 0) {
        this.page = 1
        this.filter.item = []
        // Get the media type instance coresponding to this slug
        for (var i = 0; i < newType.length; i++) {
          let index = this.typesList.findIndex((type) => type.slug == newType[i])
          if (index !== -1) {
            this.filter.item.push(this.typesList[index].id)
          }
        }
      } else {
        this.filter.item = []
      }
    },
    'searchStr'(value) {
      if (value) {
        this.page = 1
      }
    },
    'filter.item'(value) {
      if (value && value.length > 0) {
        this.page = 1
      }
    },
  },
  computed: {
    ...mapGetters('Assets', ['assetList', 'typesList']),
    filteredAssetList() {
      if (!this.filter.item.length && !this.searchStr) return this.assetList ? this.assetList : []

      // Init filters matching logic
      const searchRegex = this.searchStr
        ? new RegExp('.*' + this.searchStr.toLowerCase().replace(' ', '.*') + '.*')
        : null

      const matchSearch = (asset) => {
        return (
          searchRegex.test(asset.name.toLowerCase()) ||
          (asset.title && searchRegex.test(asset.title.toLowerCase()))
        )
      }

      const matchFilters = (asset) => {
        return this.filter.item.includes(asset.type.id)
      }

      // Combine the right filters if needed
      let isMatching = (asset) => matchSearch(asset) && matchFilters(asset)

      if (!this.filter.item.length) {
        isMatching = (asset) => matchSearch(asset)
      } else if (!this.searchStr) {
        isMatching = (asset) => matchFilters(asset)
      }

      return this.assetList.filter(isMatching)
    },
    numPage() {
      if (this.filteredAssetList && this.pageSize) {
        return Math.ceil(this.filteredAssetList.length / this.pageSize) || 1
      }

      return 1
    },
  },
  methods: {
    ...mapActions('Assets', [
      'addAsset',
      'getAssetList',
      'getTypesList',
      'getSignedUrl',
      'deleteFile',
      'setFileThumbnail',
      'deleteFileFromAWS',
      'sendFileToAWS',
      'sendFileToHasura',
      'getAssetFromPath',
    ]),
    getThumbnailForAsset(asset) {
      if (!asset.thumbnail_url) {
        switch (asset.type.slug) {
          case 'file_pdf':
            return require('../assets/images/thumbnails/PDF_THUMBNAIL.svg')
          case 'file_video':
          case 'integrated_video':
          case 'call_video':
            return require('../assets/images/thumbnails/VIDEO_THUMBNAIL.svg')
          case 'asset_scene':
            return require('../assets/images/thumbnails/SCENE_THUMBNAIL.svg')
          case 'file_image':
            return asset.url
        }
      }

      return asset.thumbnail_url || defaultImage
    },
    linkGen(page) {
      return page === 1 ? '?' : '?page=' + page
    },
    async showDeleteModal(asset, e) {
      if (e) {
        e.stopPropagation()
      }
      this.selectedFile = asset
      const response = await this.$apollo.query({
        query: GC_GET_ASSET_BY_ID,
        variables: {
          id: asset.id,
        },
      })
      this.selectedFile.targets = []

      if (response.data) {
        let data = response.data

        const medium = data.media[0]
        this.selectedFile.targets = this.getMediumTargets(medium)

        this.$forceUpdate()
      }
      this.$refs['delete-modal-media'].show()
    },
    async deleteItem() {
      if (this.isSaving) return

      this.isSaving = true

      await this.deleteFile(this.selectedFile)

      await this.getAssetList()

      this.$refs['delete-modal-media'].hide()
      this.selectedFile = null

      this.isSaving = false
    },
    success(file) {
      this.sendFileToHasura(file).then((res) => {
        this.$refs.dz.removeFile(file)
        let returnedData = res.data.insert_media_one
        let newAsset = {
          id: returnedData.id,
          path: returnedData.path,
          name: returnedData.name,
          thumbnail_path: file.thumbnail_path,
          type: {
            id: file.assetType.id,
            name: file.assetType.name,
            slug: file.assetType.slug,
          },
        }
        this.addAsset(newAsset)
      })
    },
    processQueue() {
      let acceptedFiles = this.$refs.dz.acceptedFiles
      for (let i = 0; i < acceptedFiles.length; i++) {
        if (acceptedFiles[i] && this.fileEvents[i]) {
          this.fileEvents[i](acceptedFiles[i])
        }
      }
    },
    sending(file, xhr) {
      let _send = xhr.send
      xhr.send = () => {
        _send.call(xhr, file)
      }
    },
    acceptFile(file, done) {
      this.getSignedUrl(file)
        .then((url) => {
          file.uploadURL = url
          done()
          // Manually process each file
          this.fileEvents.push(this.$refs.dz.processFile)
          // setTimeout(() => this.$refs.dz.processFile(file))
        })
        .catch((err) => {
          done('Failed to get an S3 signed upload URL', err)
        })
    },
    getUploadUrl(files) {
      if (files.length <= 0 || files.length > 1) return '#'

      return files[0].uploadURL
    },
    setThumbnail(file, thumbnail) {
      this.setFileThumbnail({ file, thumbnail }).then((path) => {
        file.thumbnail_path = path
      })
    },
    showEditMenu(id, e) {
      e.stopPropagation()
      this.mobile_edit_menu = id
    },
    clickOutsideSubmenu() {
      this.mobile_edit_menu = -1
    },
    closeSubmenu(e) {
      e.stopPropagation()
      this.mobile_edit_menu = -1
    },
    cardDrag(e, asset) {
      if (!this.draggableAsset) {
        return
      }
      if (this.onDragStart) {
        this.onDragStart()
      }
      e.dataTransfer.effectAllowed = 'copy'
      e.dataTransfer.dropEffect = 'copy'
      e.dataTransfer.setData('application/json', JSON.stringify(asset))
    },
    dragEnd(e) {
      if (this.onDragEnd) {
        this.onDragEnd(e)
      }
    },
    openUploadModal() {
      this.$refs['upload-modal'].show()
    },
    confirmTypes(e) {
      e.preventDefault()
      let allow = true
      let i = 0
      for (i = 0; i < this.$refs.dz.queuedFiles.length; i++) {
        const file = this.$refs.dz.queuedFiles[i]
        file.incorrectType = false
        if (!file.assetType) {
          allow = false
          file.incorrectType = true
        }
      }
      if (allow && i !== 0) {
        this.processQueue()
        this.$refs['upload-modal'].hide()
      }
    },
    getMediumTargets(medium) {
      const targets = []

      medium.modules.forEach((mediaModule) => {
        const module = mediaModule.module

        targets.push({
          name: [module.identifier, module.title].join(' - '),
          type: 'Module',
          path: {
            name: 'course-edit',
            params: { itemID: module.course_id },
          },
        })
      })

      medium.sequences.forEach((mediaSequence) => {
        const sequence = mediaSequence.sequence

        targets.push({
          name: [sequence.identifier, sequence.title].join(' - '),
          type: 'Séquence',
          path: {
            name: 'course-edit',
            params: { itemID: sequence.module.course_id },
          },
        })
      })

      medium.nodes.forEach((mediaNode) => {
        const scenario = mediaNode.scenario

        targets.push({
          name: [scenario.identifier, scenario.title].join(' - '),
          type: 'Scénario',
          path: {
            name: 'scenario-edit',
            params: { typeSlug: 'basic', itemID: scenario.id },
          },
        })
      })

      medium.activities.forEach((mediaActivity) => {
        const activity = mediaActivity.activity

        targets.push({
          name: [activity.identifier, activity.title].join(' - '),
          type: 'Activité',
          path: {
            name: 'game-edit',
            params: {
              gameTypeSlug: activity.type.slug,
              itemID: activity.id,
            },
          },
        })
      })

      medium.questions.forEach((mediaQuestion) => {
        const activity = mediaQuestion.activity

        targets.push({
          name: [activity.identifier, activity.title].join(' - '),
          type: "Question d'une activité",
          path: {
            name: 'game-edit',
            params: {
              gameTypeSlug: activity.type.slug,
              itemID: activity.id,
            },
          },
        })
      })

      medium.answers.forEach((mediaAnswer) => {
        const activity = mediaAnswer.question.activity

        targets.push({
          name: [activity.identifier, activity.title].join(' - '),
          type: "Réponse d'une activité",
          path: {
            name: 'game-edit',
            params: {
              gameTypeSlug: activity.type.slug,
              itemID: activity.id,
            },
          },
        })
      })

      const types = ['Pdf', 'Update', 'Session', 'Details', 'Annals', 'Video']
      types.forEach((t) => {
        medium['activitiesBy' + t + 'MediaId'].forEach((activity) => {
          targets.push({
            name: [activity.identifier, activity.title].join(' - '),
            type: 'Media de chapitre',
            path: {
              name: 'activity-edit',
              params: {
                gameTypeSlug: 'quiz_simple',
                itemID: activity.id,
              },
            },
          })
        })
      })

      if (medium.page_id) {
        targets.push({
          name: "Page d'accueil",
          type: 'Page',
          path: {
            name: 'homepage',
          },
        })
      }

      if (medium.planning_id) {
        targets.push({
          name: medium.planning_id,
          type: 'Planning',
          path: {
            name: 'planning-edit',
            params: {
              itemID: medium.planning_id,
            },
          },
        })
      }

      return targets
    },
    async selectMedia(item) {
      this.$emit('select-media', item)
      if (!this.selectOnly) {
        this.modifRecordOpen = false
        this.selectedFile = item
        //GET DB INFO
        const response = await this.$apollo.query({
          query: GC_GET_ASSET_BY_ID,
          variables: {
            id: item.id,
          },
        })

        this.selectedFile.targets = []
        this.selectedFile.updates = []
        if (response.data) {
          let data = response.data

          const medium = data.media[0]

          if (medium.created[0]) {
            this.selectedFile.author = medium.created[0]
          }

          if (medium.updated[0]) {
            this.selectedFile.updates = medium.updated
          }

          this.selectedFile.targets = this.getMediumTargets(medium)

          this.$forceUpdate()
        }
        this.$refs['detail-media-modal'].show()
      }
    },
    bulkTypeSelect(e) {
      this.bulkType = e
      if (this.$refs.dz.files) {
        this.$refs.dz.files.forEach((file) => {
          if (!e) {
            file.assetType = null
          } else {
            file.assetType = JSON.parse(JSON.stringify(e))
          }
        })
      }
      this.$forceUpdate()
    },
    formatDate(dt) {
      return moment(new Date(dt)).locale('fr').format('LLLL')
    },
    async updateMedia(e) {
      if (this.isSaving) return

      this.isSaving = true

      if (e && this.selectedFile) {
        await this.$apollo.mutate({
          mutation: GC_UPDATE_MEDIA,
          variables: {
            id: this.selectedFile.id,
            title: this.selectedFile.title,
            description: this.selectedFile.description,
            type: this.selectedFile.type.id,
            thumbnail_path: this.selectedFile.thumbnail_path,
            path: this.selectedFile.path,
            name: this.selectedFile.name,
          },
        })
        await this.$store.dispatch(
          'Logs/ContentLog',
          {
            id: this.selectedFile.id,
            action: 'content_update',
          },
          { root: true }
        )
      }

      this.isSaving = false
    },
    changeFile() {
      this.$refs['inputFile'].click()
    },
    sanitizeFileName(filename) {
      return filename.replace(/\s/g, '_').replace(/[^a-zA-Z0-9-_.]/g, '')
    },
    async chooseFile(e) {
      if (this.isSaving) return

      this.isSaving = true

      if (e.target.files[0] && this.selectedFile) {
        let fileInfo = this.selectedFile
        await this.deleteFileFromAWS(this.selectedFile)

        let newFile = e.target.files[0]
        fileInfo.name = newFile.name

        const res = await this.sendFileToAWS(newFile)

        if (res && res.status == 200) {
          let thumbnail_path = null
          let path = `assets/${this.sanitizeFileName(fileInfo.name)}`
          if (newFile.type.match(/image.*/)) {
            thumbnail_path = await this.setFileThumbnail(newFile)
          }
          await this.$apollo.mutate({
            mutation: GC_UPDATE_MEDIA,
            variables: {
              id: fileInfo.id,
              title: fileInfo.title,
              description: fileInfo.description,
              name: fileInfo.name,
              type: fileInfo.type.id,
              thumbnail_path,
              path,
            },
          })
          this.selectedFile = fileInfo
          this.selectedFile.url = await this.getAssetFromPath(path)

          await this.$store.dispatch(
            'Logs/ContentLog',
            {
              id: this.selectedFile.id,
              action: 'content_update',
            },
            { root: true }
          )

          if (thumbnail_path) {
            this.selectedFile.thumbnail_url = await this.getAssetFromPath(thumbnail_path)
          }

          this.$forceUpdate()
        }
      }

      this.isSaving = false
    },
  },
}
</script>
<style lang="scss">
.style-paginate {
  .page-link,
  .page-link:hover {
    @apply text-black;
  }
  .page-item.active .page-link {
    @apply bg-line-left border-principal-list-item;
  }

  .page-item.disabled .page-link {
    @apply opacity-50;
  }
}

#upload-modal {
  .name {
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }

  .twn-select {
    .vs__dropdown-toggle {
      @apply border-line-top;
    }
    &.is-invalid {
      .vs__dropdown-toggle {
        border-color: #dc3545;
      }
    }
  }
}

.record-list {
  max-height: 200px;
  overflow-y: auto;
}

#detail-media-modal {
  .saving {
    opacity: 0.4;
  }

  .detail-input {
    @apply flex justify-end w-full my-4;
    .form-label {
      @apply w-1/3 text-right pr-4;
    }
    .twn-input {
      @apply w-2/3;
    }
  }
}

.media-container {
  .icon {
    &.selected {
      fill: #dc9799;
    }
    @apply inline-block mb-2;
    padding: 6px;
    width: 32px;
    height: 32px;
  }
  .twn-dropzone {
    @apply border-dashed border-principal-gray rounded border-2;
    &:hover {
      @apply border-principal-selected;
    }
    min-height: 10rem;
    figure {
      width: 10%;
      figcaption {
        @apply break-words text-center;
        .name {
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          @apply text-xs;
        }
      }
    }
  }
  .card {
    @screen md {
      &.md\:w-28,
      .w-28 {
        width: 7rem !important;
      }

      &.md\:h-28,
      .h-28 {
        height: 7rem !important;
      }
    }

    .description {
      height: 50%;
    }
  }
  .edit-menu {
    right: 0px;
    top: -10px;
  }
}
</style>
