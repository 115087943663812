import gql from 'graphql-tag'

const GC_LIST_USERS = gql`
  query getUserList {
    user(order_by: { id: asc }) {
      id
      name: last_name
      role
      updated_at
      first_name
      email
      created_at
      userOrganisations {
        organisations {
          name
        }
      }
    }
  }
`

const FC_DELETE_ITEM = (table) => {
  return gql`
  mutation DeleteItem($id:uuid!) {
    delete_${table}_by_pk(id: $id) {
      id
    }
  }`
}

export { GC_LIST_USERS, FC_DELETE_ITEM }
