import gql from 'graphql-tag'

const GC_GET_PLANNING_LIST = gql`
  query GetPlanningList {
    planning {
      id
      name
    }
  }
`

const GC_GET_PLANNING_BY_ID = gql`
  query PlanningByID($id: uuid!) {
    planning_by_pk(id: $id) {
      id
      name
      content

      users {
        id
      }

      medias {
        id
        path
        name: identifier
        title
        type {
          slug
        }
      }
    }
  }
`

const GC_ADD_PLANNING_ONE = gql`
  mutation AddPlanning($name: String!, $content: String!) {
    insert_planning_one(object: { name: $name, content: $content }) {
      id
    }
  }
`

const GC_UPDATE_PLANNING_BY_ID = gql`
  mutation UpdatePlanning($id: uuid!, $name: String!, $content: String!, $users: [uuid!]!) {
    update_planning_by_pk(pk_columns: { id: $id }, _set: { name: $name, content: $content }) {
      id
    }
    remove: update_user_many(
      updates: { where: { planning_id: { _eq: $id } }, _set: { planning_id: null } }
    ) {
      affected_rows
    }
    update_user_many(updates: { where: { id: { _in: $users } }, _set: { planning_id: $id } }) {
      affected_rows
    }
  }
`

const GC_DELETE_PLANNING_BY_ID = gql`
  mutation DeletePlanning($id: uuid!, $users: [uuid!]!) {
    delete_planning_by_pk(id: $id) {
      id
      name
    }
    update_user_many(updates: { where: { id: { _in: $users } }, _set: { planning_id: null } }) {
      affected_rows
    }
  }
`

export {
  GC_GET_PLANNING_LIST,
  GC_GET_PLANNING_BY_ID,
  GC_ADD_PLANNING_ONE,
  GC_UPDATE_PLANNING_BY_ID,
  GC_DELETE_PLANNING_BY_ID,
}
