import { GC_GET_MEDIA_TYPE_LIST, GC_GET_MEDIA_LIST } from '@/graphql/media'
import apollo from '@/apolloClient'

export default {
  namespaced: true,
  state: {
    type_list: [],
    list: [],
  },
  mutations: {
    SET_MEDIA_TYPE_LIST(state, types) {
      state.type_list = types
    },
    SET_MEDIA_LIST(state, locations) {
      state.list = locations
    },
  },
  actions: {
    async getTypeList({ commit }) {
      const response = await apollo.query({ query: GC_GET_MEDIA_TYPE_LIST })

      commit('SET_MEDIA_TYPE_LIST', response.data.media_type)
    },
    async getList({ commit }) {
      const response = await apollo.query({ query: GC_GET_MEDIA_LIST })

      commit('SET_MEDIA_LIST', response.data.media)
    },
  },
}
